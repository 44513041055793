<template>
  <canvas ref="canvas"></canvas>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import QRCode from 'qrcode/lib/browser';

const props = defineProps({
  payeeName: {
    type: String,
    default: '',
  },
  payeeInn: {
    type: String,
    default: '',
  },
  payeeKpp: {
    type: String,
    default: '',
  },
  payeePersonalAcc: {
    type: String,
    default: '',
  },
  payeeBankName: {
    type: String,
    default: '',
  },
  payeeBankBic: {
    type: String,
    default: '',
  },
  payeeBankCorrespAcc: {
    type: String,
    default: '',
  },
  purpose: {
    type: String,
    default: '',
  },
  sum: {
    type: String,
    default: '0',
  },
  width: {
    type: [String, Number],
  },
});

const canvas = ref();

onMounted(() => {
  renderPayment();
});

const mapToQRPaymentString = (descriptor) => {
  const {
    payeeName,
    payeeInn,
    payeeKpp,
    payeePersonalAcc,
    payeeBankName,
    payeeBankBic,
    payeeBankCorrespAcc,
    sum,
    purpose,
  } = descriptor;
  return [
    `ST00012`,
    `Name=${payeeName}`,
    `PersonalAcc=${payeePersonalAcc}`,
    `BankName=${payeeBankName}`,
    `BIC=${payeeBankBic}`,
    `CorrespAcc=${payeeBankCorrespAcc}`,
    `Purpose=${purpose}`,
    `Sum=${sum}`,
    `PayeeINN=${payeeInn}`,
    `KPP=${payeeKpp}`,
  ].join('|');
};
const renderPayment = () => {
  const str = mapToQRPaymentString(props);
  let options = {};
  if (props.width) {
    options.width = props.width;
  }
  /*
          IMPORTANT

          jsdom doesn't support canvas.
          So this "if (cond) {}" prevents our tests from crashing.
         */
  if (import.meta.env.MODE !== 'testing') {
    // https://github.com/soldair/node-qrcode#tocanvascanvaselement-text-options-cberror
    QRCode.toCanvas(canvas.value, str, options);
  }
};
</script>
