<template>
  <div class="investment-refill">
    <div v-if="typesTransfer.length > 1" class="switcher-wrapper mb-4">
      <Switcher v-model="selectedtTypesTransfer" :items="typesTransfer" />
    </div>
    <div class="col-md-12 content-wrapper">
      <RefillBalanceAccount
        v-if="selectedtTypesTransfer === 2"
        :data="methodAccount"
      />
      <RefillBalanceSPB v-if="selectedtTypesTransfer === 1" :data="methodSpb" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import Switcher from '@/components/_generic/cselect/Switcher.vue';
import detectDevice from '@/composables/detectDeviceComposable.js';
import RefillBalanceAccount from '@/components/Refill/RefillBalanceAccount.vue';
import RefillBalanceSPB from '@/components/Refill/RefillBalanceSPB.vue';

const props = defineProps({
  paymentMethods: {
    type: Array,
  },
});

const { isMobile } = detectDevice();
const methodSpb = computed(() => {
  return props.paymentMethods.find((i) => i.method === 'sbp');
});
const methodAccount = computed(() => {
  return props.paymentMethods.find((i) => i.method === 'bankAccount');
});
const typesTransfer = computed(() => {
  let result = [];
  if (methodSpb.value) {
    let title = methodSpb.value.title;
    if (isMobile.value) {
      title = methodSpb.value.shortTitle;
    }
    result.push({
      title,
      icon: 'icon_spb',
      value: 1,
    });
  }
  if (methodAccount.value) {
    let title = methodAccount.value.title;
    if (isMobile.value) {
      title = methodAccount.value.shortTitle;
    }
    result.push({
      title,
      value: 2,
    });
  }
  return result;
});
const selectedtTypesTransfer = ref();

onMounted(() => {
  if (props.paymentMethods.length === 1) {
    selectedtTypesTransfer.value = typesTransfer.value[0].value;
  }
  if (props.paymentMethods.length > 1) {
    selectedtTypesTransfer.value = 1;
  }
});
</script>
<style lang="scss" scoped></style>
