<template>
  <div class="loan-your-investments">
    <div class="title potok-text-h2">ваши инвестиции</div>
    <div>
      <div>
        <GeneralTable
          :items="filterInvestment(props.project.investments)"
          :columns="columnsInvestment"
        />
      </div>
      <div
        v-if="
          props.project.investments?.length > MAX_INVESTMENT_ITEMS &&
          !isShowAllInvesntment
        "
        class="extra-investments potok-text-body-2"
        @click="isShowAllInvesntment = !isShowAllInvesntment"
      >
        показать еще
        {{ props.project.investments?.length - MAX_INVESTMENT_ITEMS }}
        инвестиций
        <i class="fa-angle-up fa fa-angle-down"></i>
      </div>
      <div
        v-if="
          props.project.investments?.length > MAX_INVESTMENT_ITEMS &&
          isShowAllInvesntment
        "
        class="extra-investments potok-text-body-2"
        @click="isShowAllInvesntment = !isShowAllInvesntment"
      >
        скрыть последние
        {{ props.project.investments?.length - MAX_INVESTMENT_ITEMS }}
        инвестиции
        <i class="fa-angle-up fa fa-angle-up"></i>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import constants from '@/constants';
import { formatCurrency, formatDate } from '@/utils/commonUtils/utils';

import GeneralTable from '@/components/_generic/table/GeneralTable.vue';

const props = defineProps({
  project: {
    type: Object,
    default: () => {},
  },
});

const MAX_INVESTMENT_ITEMS = 3;

const columnsInvestment = [
  {
    title: 'инвестиция',
    is_mobile_full: true,
    th_desktop_style: {
      width: '25%',
    },
    content_desktop(item) {
      return `
      <a href="/investment/${item.id}"
        target="_blank" style="color: #04CCD9">${item.number}</a>
      `;
    },
  },
  {
    title: 'дата инвестиции',
    is_mobile_full: true,
    th_desktop_style: {
      width: '25%',
    },
    content_desktop(item) {
      return `
      ${formatDate(item.createdAt)}
      `;
    },
  },
  {
    title: 'сумма инвестирования',
    is_mobile_full: true,
    th_desktop_style: {
      width: '25%',
    },
    content_desktop(item) {
      return `
      <span>${formatCurrency(item.amount, 0)} </span>
      ₽
      `;
    },
  },
  {
    title: 'статус',
    is_mobile_full: true,
    th_desktop_style: {
      width: '25%',
    },
    content_desktop(item) {
      return `
      ${investmentStatus(item.status, item.isRestructured)}
      `;
    },
  },
];

const isShowAllInvesntment = ref(false);

const filterInvestment = (items) => {
  if (items?.length <= MAX_INVESTMENT_ITEMS) {
    return items;
  }
  if (!isShowAllInvesntment.value) {
    return items?.slice(0, MAX_INVESTMENT_ITEMS);
  }
  return items;
};

const investmentStatus = (status, isRestructured) => {
  const investmentStatusTitle = constants.investmentStatusTitle;
  let statusTitle;
  if (isRestructured) {
    statusTitle = 'Реструктурирован';
  } else {
    statusTitle = investmentStatusTitle[status];
  }
  let statusClass = status;
  if (status === 'cession' && !isRestructured) {
    statusClass = 'default';
  }

  return `
  <div class="status">
    <div class="status-value potok-text-uppercase status-${statusClass}">
      ${statusTitle}</div>
  </div>
  `;
};
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.loan-your-investments {
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 4px #e5e9f2;
  border-radius: 6px;
  margin: 30px 0;
  padding: 20px;

  @media (max-width: $size_767) {
    padding: 16px;
  }

  .status-value {
    @media (max-width: $size_767) {
      background-color: transparent !important;
      padding: 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.title {
  width: 100%;
  color: $deep_dive;
  margin-bottom: 6px;

  @media (max-width: $size_767) {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 7px;
  }
}

.extra-investments {
  color: #04ccd9;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 20px;
}

.status {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 0 6px 0 0;

  .status-value {
    display: inline-block;
    border-radius: 6px;
    padding: 4px 8px;
    margin: 0 2px;
    letter-spacing: 0.1em;

    @media (max-width: $size_767) {
      margin: 0;
    }
  }

  .status-reserved {
    color: #4b64c4;
    background-color: #edf0f9;
  }

  .status-active {
    color: #04ccd9;
    background-color: #e7f8f7;
  }

  .status-refunded {
    color: #4b64c4;
    background-color: #edf0f9;
  }

  .status-cession {
    color: #ffc11e;
    background-color: #fff9e6;
  }

  .status-default {
    color: #ee3d48;
    background-color: rgb(238 61 72 / 10%);
  }
}

.tableitems__item {
  box-shadow: none;
  margin-bottom: 0;
}
</style>
