<template>
  <div>
    <div class="step_description">
      <div v-html="props.data?.text"></div>
    </div>
    <div class="fields">
      <div class="col-md-8 col-sm-12">
        <div v-for="(item, i) in props.fields" :key="i" class="field-item">
          <FormField
            :name="item.name"
            :value="item.value"
            name-width="5"
            value-width="7"
          />
        </div>
      </div>
      <div class="col-md-4 col-sm-12 p-0">
        <div class="qr-wrapper">
          <div class="qr-text-desc">QR code для оплаты из мобильного банка</div>
          <vue-qr-code-payment
            class="code_payment"
            :payee-name="props.paymentValues.payeeName"
            :payee-inn="props.paymentValues.payeeInn"
            :payee-kpp="props.paymentValues.payeeKpp"
            :payee-personal-acc="props.paymentValues.payeePersonalAcc"
            :payee-bank-name="props.paymentValues.payeeBankName"
            :payee-bank-bic="props.paymentValues.payeeBankBic"
            :payee-bank-corresp-acc="props.paymentValues.payeeBankCorrespAcc"
            :purpose="props.paymentValues.purpose"
            :width="186"
          >
          </vue-qr-code-payment>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import FormField from '@/components/_generic/forms/FormField.vue';
import VueQrCodePayment from '@/components/_generic/VueQrCodePayment';

const props = defineProps({
  fields: {
    type: Array,
  },
  paymentValues: {
    type: Object,
  },
  data: {
    type: Object,
    required: false,
  },
});
</script>
<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.step_description {
  max-width: 448px;
  margin-top: 20px;
  padding: 0 15px;
}

.fields {
  margin-top: 20px;
}

.qr-text-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $grey;
  margin: auto;
  margin-bottom: 20px;
}

.qr-wrapper {
  background: $background;
  border-radius: 14px;
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 215px;
  margin: auto;
}

.qr-wrapper-mobile {
  width: 260px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  .qr-wrapper-mobile-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-transform: lowercase;
    margin-top: 13px;
  }
}
</style>
