<template>
  <div class="loans-header">
    <h2 class="loans-header-title potok-text-h1">новые займы</h2>

    <div class="loans-header-bottom">
      <PotokTabsSecondary
        :items="loansTypeItems"
        :small="isMobile ? false : true"
        class="potok-header-tabs-type"
        @change-tab="changeTab"
      >
        <template #default="{ item }">
          <div>
            {{ item.text }}
          </div>
        </template>
      </PotokTabsSecondary>

      <PotokTabsSecondary
        v-if="!isMobile"
        :items="loansRenderTypeItems"
        :small="isMobile ? false : true"
        class="potok-header-tabs-render ml-3"
        @change-tab="changeRender"
      >
        <template #default="{ item }">
          <PotokImg :src="item.image" height="16" width="16" />
        </template>
      </PotokTabsSecondary>

      <div class="loans-header-available">
        <div class="loans-header-available-name potok-text-body-2 mr-1">
          доступно на счете
        </div>
        <div class="loans-header-available-value potok-text-body-2">
          <span>{{ (isMobile ? '' : '— ') + props.totalFree + ' ₽' }}</span>
        </div>
      </div>
    </div>

    <PotokDivider margin-y="20" class="no_mobile" />
  </div>
</template>

<script setup>
import detectDevice from '@/composables/detectDeviceComposable';
import { ref } from 'vue';
import { PotokDivider } from 'potok-uikit';
import PotokImg from '@/components/_generic/PotokImg.vue';
import PotokTabsSecondary from '../_generic/PotokTabsSecondary.vue';
import imageConstants from '@/constants/imageConstants';

const { isMobile } = detectDevice();

const emits = defineEmits(['changeTabs', 'changeRender']);
const props = defineProps({
  totalFree: {
    type: [Number, String],
    required: true,
  },
});

const loansTypeItems = ref([
  {
    id: 0,
    text: 'первичные',
    isActive: true,
  },
  {
    id: 1,
    text: 'вторичные',
    isActive: false,
  },
]);

const loansRenderTypeItems = ref([
  {
    id: 0,
    text: 'таблица',
    image: imageConstants.loans.loansHeader.rowsVertical,
    isActive: false,
  },
  {
    id: 1,
    text: 'карточки',
    image: imageConstants.loans.loansHeader.fourSquares,
    isActive: true,
  },
]);

const changeTab = (item) => {
  emits('changeTabs', item.id === 0 ? 'LoansFirstPage' : 'LoansSecondPage');

  const previusActiveItem = loansTypeItems.value.find((el) => el.isActive);
  const targetItem = loansTypeItems.value.find((el) => el.id === item.id);

  previusActiveItem.isActive = false;
  targetItem.isActive = true;
};

const changeRender = (item) => {
  emits('changeRender', item.id === 0 ? 'table' : 'card');

  const previusActiveItem = loansRenderTypeItems.value.find(
    (el) => el.isActive,
  );
  const targetItem = loansRenderTypeItems.value.find((el) => el.id === item.id);

  previusActiveItem.isActive = false;
  targetItem.isActive = true;
};
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;
@use '../../scss/typography.scss' as *;

.loans-header {
  display: flex;
  flex-direction: column;

  @media (max-width: $size_767) {
    align-items: flex-start;
  }
}

.loans-header-bottom {
  display: flex;

  @media (max-width: $size_767) {
    flex-direction: column;
    width: 100%;
  }
}

.loans-header-title {
  margin: 0 0 20px;

  @media (max-width: $size_767) {
    margin: 0 0 16px;

    @include potok-text-h2;
  }
}

.loans-header-available {
  order: 3;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  background: rgb(23 59 87 / 6%);
  border-radius: 8px;
  padding: 4px 12px;
  margin-left: auto;

  //border-right: 3px solid #173b57;
  @media (max-width: $size_767) {
    order: 1;
    align-items: center;
    margin-left: initial;
    padding: 10px 20px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-right: initial;
    border-left: 3px solid #173b57;
  }
}

.potok-header-tabs-type {
  order: 1;

  @media (max-width: $size_767) {
    order: 2;
    margin-top: 16px;
  }
}

.potok-header-tabs-render {
  order: 2;
}

.loans-header-available-name {
  @media (max-width: $size_767) {
    @include potok-text-uppercase;

    color: $grey;
  }
}

.loans-header-available-value {
  font-weight: 500;

  @media (max-width: $size_767) {
    @include potok-text-body-1-bold;
  }
}

.loans-header-list {
  text-transform: none;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.loans-header-list-item {
  margin-right: 20px;

  @media (max-width: $size_991) {
    margin-right: 8px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.loans-header-item-link {
  text-decoration: none;
  color: $grey;

  &:hover {
    color: $deep_dive;
  }

  @media (max-width: $size_991) {
    @include potok-text-h2;
  }
}

.loans-header .loans-header-item-active {
  position: relative;
  color: $deep_dive;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -8px;
    left: 0;
    height: 2px;
    background-color: $deep_dive;
  }
}
</style>
