<template>
  <PotokModal
    v-if="isShowConsentModal"
    size="fit"
    :is-unable-to-close="true"
  >
    <template #content>
      <div
        v-html="props.description"
        class="consent-modal-content"
      />
    </template>

    <template #actions>
      <div class="consent-modal-actions mt-5">
        <PotokButton
          fullWidthDesktop
          :text="props.buttonText"
          size="large"
          @click="clearHandle"
        />
        <PotokButton
          v-if="props.rejectButtonText"
          theme="link"
          size="link"
          :text="props.rejectButtonText"
          class="mt-5"
          @click="close"
        />
      </div>
    </template>
  </PotokModal>

  <ModalConfirmSMS
      v-if="isShowSmsModal"
      :loading="isLoading"
      :confirm-function="confirmAutoInvestSettingsReconnect"
      :request="requestAutoInvestSettingsReconnect"
      :confirm-message="null"
      :error-message="confirmErrorMessage"
      title="подтвердите свое согласие"
      @close="isShowSmsModal = false"
    />
</template>

<script setup>
import { ref } from 'vue';
import { PotokModal, PotokButton } from 'potok-uikit';
import ModalConfirmSMS from '@/components/_generic/modals/ModalConfirmSMS.vue';

import server from '@/server';
import { useStateStore } from '@/store/stateStore';

const props = defineProps({
  description: {
    type: String,
    default: '',
  },
  buttonText: {
    type: String,
    default: '',
  },
  rejectButtonText: {
    type: String,
    default: '',
  },
});

const store = useStateStore()

const isLoading = ref(false);
const isShowConsentModal = ref(true);
const isShowSmsModal = ref(false);
const confirmErrorMessage = ref('');

const clearHandle = () => {
  isShowConsentModal.value = false;
  isShowSmsModal.value = true;
  requestAutoInvestSettingsReconnect();
}

const close = () => {
  isShowConsentModal.value = false;
}

const requestAutoInvestSettingsReconnect = () => {
  isLoading.value = true;

  return server.postCompanyAutoInvestSettingsReconnect
    .send({}, { params: { id: store.selectedCompanyId } })
    .pipe(
      onRequestAutoInvestSettingsReconnectSuccess(),
      () => (isLoading.value = false),
    )
    .exec();
};

const onRequestAutoInvestSettingsReconnectSuccess = () => {
  isLoading.value = false;
  confirmErrorMessage.value = null;
};

const confirmAutoInvestSettingsReconnect = ({ code }) => {
  const query = {
    code,
  };

  isLoading.value = true;

  return server.putCompanyAutoInvestSettingsReconnect
    .send(query, { params: { id: store.selectedCompanyId } })
    .pipe(
      onConfirmAutoInvestSettingsReconnectSuccess.bind(this),
      onConfirmAutoInvestSettingsReconnectError.bind(this),
    )
    .exec();
};

const onConfirmAutoInvestSettingsReconnectSuccess = ({ data }) => {
  isLoading.value = false;

  if (data.isSuccessful) {
    isShowSmsModal.value = false;
    confirmErrorMessage.value = null;

    store.setNotification({
      title: 'Вы успешно подтвердили свое согласие',
    });
  } else {
    confirmErrorMessage.value = 'неверный код';
  }
};

const onConfirmAutoInvestSettingsReconnectError = ({ data }) => {
  isLoading.value = false;

  confirmErrorMessage.value = data.message;
};
</script>

<style lang="scss" scoped>
.consent-modal-content {
  max-width: 300px;
}

.consent-modal-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
