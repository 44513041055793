<template>
  <div class="accept-rules-text potok-text-body-3 mt-4">
    <p>
      Нажимая кнопку "добавить это ип", вы присоединяетесь к
      <a href="/documents" target="_blank">Правилам платформы</a>
      и подтверждаете что:
    </p>

    <p class="my-0">- являетесь налоговым резидентом РФ,</p>

    <div>
      <span class="my-0"> - не являетесь </span>
      <VDropdown placement="bottom-start">
        <span class="dropdown-trigger">публичным должностным лицом</span>
        <template #popper>
          <div class="popup">
            <div v-close-popper class="popup-close" />
            <div class="popup-text">
              ДЛПМО - должностные лица публичных международных организаций?<br />
              ИПДЛ - иностранные публичные должностные лица;<br />
              РПДЛ - лица, замещающие (занимающие) государственные должности РФ
            </div>
          </div>
        </template>
      </VDropdown>
      <span class="my-0"> и/или родственником такого лица;</span>
    </div>

    <p class="my-0">- являетесь бенефициарным владельцем;</p>

    <div>
      -
      <VDropdown placement="bottom-start">
        <span class="dropdown-trigger">выгодоприобретатели</span>
        <template #popper>
          <div class="popup">
            <div v-close-popper class="popup-close" />
            <div class="popup-text">
              лица, к выгоде которых действует инвестор, в том числе на
              основании агентского договора, договоров поручения, комиссии и
              доверительного управления, при проведении операций с денежными
              средствами и иным имуществом
            </div>
          </div>
        </template>
      </VDropdown>
      отсутствуют,
    </div>

    <p class="my-0">- деловая репутация Инвестора - положительная.</p>
  </div>
</template>

<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.dropdown-trigger {
  cursor: pointer;
  color: $tiffany;
}

.accept-rules-text {
  text-transform: none;
}
</style>
