<template>
  <section class="investor_investments">
    <div class="row">
      <div class="col-xs-12">
        <h2 class="block_title">
          <VDropdown placement="bottom-start">
            <div class="title-icon">ваши инвестиции</div>
            <template #popper>
              <div class="popup">
                <div v-close-popper class="popup-close"></div>
                <div class="popup-text">
                  Консолидированные данные по всем вашим Инвестициям на
                  платформе – рассчитаны в реальном времени.
                </div>
              </div>
            </template>
          </VDropdown>
        </h2>
      </div>
    </div>
    <div>
      <template v-if="loadingInvestmentsStats">
        <div class="balances-item col-md-6">
          <div class="property" style="margin-top: 15px">
            <Loader />
          </div>
        </div>
      </template>
      <template v-if="!loadingInvestmentsStats">
        <DashboardInvestmentsDesktop v-if="!isMobile" :stats="data.stats" />
        <DashboardInvestmentsMobile v-if="isMobile" :stats="data.stats" />
      </template>
    </div>
  </section>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted } from 'vue';
import { useStateStore } from '@/store/stateStore';
import { Loader } from 'potok-uikit';

import server from '@/server';
import DashboardInvestmentsDesktop from './DashboardInvestments/DashboardInvestmentsDesktop.vue';
import DashboardInvestmentsMobile from './DashboardInvestments/DashboardInvestmentsMobile.vue';

import detectDevice from '@/composables/detectDeviceComposable';

const store = useStateStore();
const { isMobile } = detectDevice();

const loadingInvestmentsStats = ref(false);
const data = reactive({
  stats: {
    default: {},
    funded: {},
    refunded: {},
    restructured: {},
    softOverdue: {},
    sold: {},
    total: {},
  },
});

const selectedCompanyId = computed(() => {
  return store.selectedCompanyId;
});

const getData = () => {
  fetchCompanyInvestmentsStatsAsync();
};
const fetchCompanyInvestmentsStatsAsync = () => {
  const companyId = selectedCompanyId.value;
  if (!companyId) {
    return;
  }
  loadingInvestmentsStats.value = true;
  return server.getCompanyInvestmentsStats
    .send(null, { params: { id: companyId } })
    .pipe(onFetchCompanyInvestmentsStatsSuccess, () => {
      loadingInvestmentsStats.value = false;
    })
    .exec();
};
const onFetchCompanyInvestmentsStatsSuccess = (obj) => {
  loadingInvestmentsStats.value = false;
  if (!obj.data) {
    return;
  }
  data.stats = obj.data;
};

watch(selectedCompanyId, () => {
  if (!store.isAuthenticated) {
    return;
  }
  getData();
});

onMounted(() => {
  getData();
});
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.investor_investments {
  .block_title {
    margin-top: 70px;
    margin-bottom: 30px;

    @media (max-width: $size_767) {
      margin-top: 30px;
    }
  }

  :deep(.dashboard_block) {
    .dashboard_block_content {
      .item_row_vertical {
        @media (max-width: $size_767) {
          margin: 6px 0;
        }
      }
    }
  }
}
</style>
