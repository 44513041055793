<template>
  <div class="row">
    <div>
      <template v-if="currentStepMode === 1">
        <RefillBalanceSPBStep1
          v-model:amount-value="amountValue"
          :error-message="errorMessage"
          :loading="loading"
          :on-input="onInput"
          :data="props.data"
          @next-step="nextStep"
        />
      </template>
      <template v-if="currentStepMode === 2">
        <RefillBalanceSPBStep2Desktop
          v-if="!isMobile"
          :amount-value="amountValue"
          :qr-code="qrCode"
          :data="props.data"
          @prev-step="goBack"
        />

        <RefillBalanceSPBStep2Mobile
          v-if="isMobile"
          :amount-value="amountValue"
          :qr-code="qrCode"
          :link-payment="linkPayment"
          @prev-step="goBack"
        />
      </template>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';

import detectDevice from '@/composables/detectDeviceComposable.js';
import server from '@/server/index';
import { useStateStore } from '@/store/stateStore';

import RefillBalanceSPBStep1 from './RefillBalanceSPBStep1.vue';
import RefillBalanceSPBStep2Desktop from './RefillBalanceSPBStep2Desktop.vue';
import RefillBalanceSPBStep2Mobile from './RefillBalanceSPBStep2Mobile.vue';

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});

const store = useStateStore();

const amountValue = ref(0);
const loading = ref(false);
const currentStepMode = ref(1);
const errorMessage = ref(null);

const { isMobile } = detectDevice();

const qrCode = ref(null);
const linkPayment = ref(null);

const isValidApply = () => {
  if (!amountValue.value || amountValue.value <= 0) {
    errorMessage.value = 'введите сумму пополнения';
    return false;
  }
  if (amountValue.value > 1000000) {
    errorMessage.value = 'максимальная сумма 1 000 000 ₽';
    return false;
  }
  return true;
};
const nextStep = () => {
  if (!isValidApply()) {
    return;
  }

  const accountNumber = store.selectedCompany?.investorAccountNumber;

  loading.value = true;

  const query = {
    accountNumber: accountNumber,
    amount: String(amountValue.value),
  };

  return server.postSpbQRCode
    .send(query)
    .pipe(
      (result) => {
        loading.value = false;
        const data = result.data || {};
        qrCode.value = data.image;
        linkPayment.value = data.payload;

        currentStepMode.value = 2;
      },
      () => {
        loading.value = false;
      },
    )
    .exec();
};

const goBack = () => {
  currentStepMode.value = 1;
};

const onInput = (value) => {
  errorMessage.value = null;
  amountValue.value = value;
};

watch(
  () => {
    return store.selectedCompany;
  },
  () => {
    currentStepMode.value = 1;
  },
);
</script>
<style lang="scss" scoped></style>
