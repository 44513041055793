<template>
  <div class="project-label-pif" @click.prevent>
    <VDropdown>
      <button class="info-icon-hint" style="display: flex; align-items: center">
        <div class="project__tag-circle pif">
          <div class="icon_galka"></div>
        </div>
        <span class="project-label-pif__text">Поток</span>
      </button>
      <template #popper>
        <div class="popup">
          <div v-close-popper class="popup-close"></div>
          <div class="popup-text" style="text-transform: none">
            займ проинвестирован средствами МФК «Поток.Финанс» (ООО) и другими
            средствами акционеров
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.project-label-pif {
  display: flex;
  align-items: center;
  border: 1px solid #dedee1;
  border-radius: 6px;
  padding: 2px 8px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  color: #10273d;
  text-transform: none;
  height: 20px;
}

.project__tag-circle {
  background-color: #04ccd9;
  color: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
}
</style>
