<template>
  <div class="new-investments-new-request-step-1-title">
    <div class="new-investments-new-request-step-1-title-left">
      <div
        class="new-investments-new-request-step-1-title-subtitle potok-text-body-1"
      >
        доступно для продажи
      </div>

      <div
        class="new-investments-new-request-step-1-title-sum potok-text-h2 mt-2"
      >
        {{ investmentsText }}
      </div>
    </div>

    <div class="new-investments-new-request-step-1-title-right">
      <div
        class="new-investments-new-request-step-1-title-how potok-text-body-1"
        @click="showTour"
      >
        <template v-if="!isTablet"> как это работает? </template>
        <template v-else>
          <i
            class="new-investments-new-request-step-1-title-how-icon icon-attention"
            @click="showTour"
          />
        </template>
      </div>

      <button
        class="new-investments-new-request-step-1-title-continue"
        @click="nextStep"
      >
        продолжить
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { formatCurrency, pluralize } from '@/utils/commonUtils/libUtils';
import detectDevice from '@/composables/detectDeviceComposable';

const { isTablet } = detectDevice();
const props = defineProps(['sum', 'count', 'howItWorksText']);
const emits = defineEmits(['change-step', 'show-tour']);

const sumModified = computed(() => {
  return `${formatCurrency(props.sum, 0)} ₽`;
});
const investmentsText = computed(() => {
  return `${props.count} ${pluralize(props.count, ['инвестиция', 'инвестиции', 'инвестиций'])} на сумму ${sumModified.value}`;
});

const showTour = () => {
  emits('show-tour');
};
const nextStep = () => {
  emits('change-step', 2);
};
</script>

<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;
@use '../../../../scss/typography.scss' as *;

.new-investments-new-request-step-1-title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 14px;
  padding: 30px;
  background-color: $white;

  @media (max-width: $size_991) {
    flex-direction: column;
    padding: 20px 14px;
    align-items: flex-start;
  }
}

.new-investments-new-request-step-1-title-left {
  display: flex;
  flex-direction: column;
}

.new-investments-new-request-step-1-title-right {
  display: flex;
  align-items: center;

  @media (max-width: $size_991) {
    margin-top: 30px;
    width: 100%;
  }
}

.new-investments-new-request-step-1-title-sum {
  @media (max-width: $size_991) {
    @include potok-text-body-1-bold;
  }
}

.new-investments-new-request-step-1-title-subtitle {
  color: $grey;

  @media (max-width: $size_991) {
    @include potok-text-body-2;
  }
}

.new-investments-new-request-step-1-title-how {
  color: $tiffany;
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.new-investments-new-request-step-1-title-continue {
  padding: 10px 18px;
  border-radius: 8px;
  border: 1px solid $breakwater;
  background: $breakwater;
  color: $white;
  margin-left: 30px;

  @media (max-width: $size_991) {
    margin-left: 0;
    width: 100%;
  }

  &:hover {
    color: $breakwater;
    background: transparent;
  }
}

.new-investments-new-request-step-1-title-how-icon {
  position: absolute;
  right: 14px;
  top: 14px;
}
</style>
