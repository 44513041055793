<template>
  <div class="assets-outcome">
    <div class="assets-outcome-head mb-5">
      <h2 class="potok-text-body-1-bold my-0">расход</h2>

      <Switcher
        v-model="selectedPeriod"
        class="assets-outcome-switcher"
        :items="periods"
        small="true"
        @update:model-value="updatePeriod"
      />
    </div>

    <AnalyticsLoadingContainer
      :has-data="!!items.length"
      :loading="props.loading"
      loading-text="пожалуйста, подождите, загружаем данные"
      help-mobile-title="стоимость активов"
    >
      <div
        v-if="selectedPeriod === 'day' && !props.loading && dayText"
        class="day-block potok-text-uppercase"
      >
        {{ `${dayText} год` }}
      </div>
      <div style="width: 100%; height: 240px">
        <Bar
          v-if="isShowGraph && isLabels"
          :data="dataChart"
          :options="options"
          :width="'100%'"
          :height="'240px'"
          :plugins="[chartAreaBorder]"
        />
      </div>

      <template #popper>
        <div class="popup help-dropdown-text potok-text-body-2">
          <div class="mb-5">
            <div v-html="props.texts?.hint?.fullDescription"></div>
          </div>
        </div>
      </template>
    </AnalyticsLoadingContainer>
  </div>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue';
import { Bar } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';
import { format, parse } from 'date-fns';
import ru from 'date-fns/locale/ru';
import Switcher from '@/components/_generic/cselect/Switcher.vue';
import externalTooltipOutcomeHandler from '@/utils/chartjs/getOrCreateTooltipOutcome';
import chartAreaBorder from '@/utils/chartjs/chartAreaBorder';
import AnalyticsLoadingContainer from './AnalyticsLoadingContainer.vue';

Chart.register(...registerables);

const emit = defineEmits(['updatePeriod']);
const props = defineProps({
  loading: Boolean,
  data: {
    type: Object,
    default: () => null,
  },
  texts: {
    type: Object,
    default: () => {},
  },
});

const periods = ref([
  {
    title: 'месяц',
    value: 'day',
  },
  {
    title: 'год',
    value: 'month',
  },
  {
    title: 'все',
    value: 'quarter',
  },
]);
const selectedPeriod = ref('month');

const dataChart = ref({
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      borderColor: 'rgba(238, 61, 72, 1)',
      backgroundColor: 'rgba(238, 61, 72, 1)',
      borderRadius: 4,
      order: 1,
      minBarLength: 5,
    },
  ],
});
const options = ref({
  metaConfig: {
    tooltipFooter: true,
    tooltipFooterText: '',
    tooltipHeaderFormat: 'month',
    defaultLabels: null,
  },
  parsing: {
    yAxisKey: 'indexValue',
    xAxisKey: 'indexValue',
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        usePointStyle: true,
        pointStyle: 'circle',
        color: 'rgba(129, 142, 153, 1)',
        font: {
          size: 14,
        },
      },
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      padding: 0,
    },
    chartAreaBorder: {
      borderColor: 'rgba(232, 236, 240, 1)',
      borderWidth: 1,
    },
  },
  elements: {
    point: {
      pointStyle: 'circle',
      radius: 0,
      borderWidth: 2,
    },
    line: {
      borderWidth: 2,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: true,
        drawTicks: false,
        color: 'rgba(232, 236, 240, 1)',
        width: 1,
      },
      border: {
        color: 'rgba(243, 245, 247, 1)',
        width: 1,
      },
      ticks: {
        display: false,
      },
    },
    x: {
      grid: {
        display: true,
        drawTicks: false,
        color: 'rgba(243, 245, 247, 1)',
        tickBorderDash: [5, 3],
        width: 1,
      },
      border: {
        dash: [5, 3],
        color: 'rgba(243, 245, 247, 1)',
        width: 1,
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        color: 'rgba(129, 142, 153, 0.8)',
        font: {
          size: 8,
          family: "'SuisseIntl'",
          weight: '400',
        },
      },
    },
  },
});

const isShowGraph = ref(false);

const items = computed(() => {
  if (!props.data) {
    return [];
  }
  const keys = Object.keys(props.data);
  const values = Object.values(props.data);

  const result = keys.map((el, i) => {
    return {
      label: keys[i],
      ...values[i],
    };
  });

  return result.filter((el) => {
    return el.totalOutcome >= 1;
  });
});

const labels = computed(() => {
  return items.value.map((el) => {
    if (selectedPeriod.value === 'month') {
      return format(parse(el.label, 'dd.MM.yyyy', new Date()), 'MM.yy');
    }

    if (selectedPeriod.value === 'day') {
      return format(parse(el.label, 'dd.MM.yyyy', new Date()), 'd MMMM yyyy', {
        locale: ru,
      });
    }

    return format(parse(el.label, 'dd.MM.yyyy', new Date()), 'qКyy');
  });
});

const isLabels = computed(() => {
  return dataChart.value.labels.length;
});

const dayText = computed(() => {
  if (!items.value.length) {
    return '';
  }
  return format(
    parse(items.value[0]?.label, 'dd.mm.yyyy', new Date()),
    'LLLL yyyy',
    {
      locale: ru,
    },
  );
});

const updatePeriod = () => {
  emit('updatePeriod', selectedPeriod.value);
};

const refreshData = () => {
  isShowGraph.value = false;
  const newDataTotalOutcome = [];

  items.value.forEach((el) => {
    newDataTotalOutcome.push({
      ...el,
      indexValue: el.totalOutcome,
    });
  });

  dataChart.value.datasets[0].data = newDataTotalOutcome;
  dataChart.value.datasets[0].label = props.texts?.keys?.totalOutcome?.name;
  dataChart.value.labels = labels.value;
  options.value.metaConfig.tooltipHeaderFormat = selectedPeriod.value;
  options.value.metaConfig.tooltipFooterText =
    props.texts?.keys?.totalOutcome?.name;
  options.value.metaConfig.defaultLabels = items.value.map((el) => el.label);

  options.value.scales.x.grid.display = selectedPeriod.value !== 'day';
  options.value.scales.x.ticks.display = selectedPeriod.value !== 'day';

  options.value.plugins.tooltip.external = (context) => {
    externalTooltipOutcomeHandler(context);
  };

  nextTick(() => {
    isShowGraph.value = true;
  });
};

watch(items, () => {
  refreshData();
});
watch(
  () => {
    return props.texts;
  },
  () => {
    refreshData();
  },
);

refreshData();
</script>

<style scoped lang="scss">
@use '../../../scss/variables.scss' as *;

.assets-outcome-head {
  display: flex;
  justify-content: space-between;

  @media (max-width: $size_991) {
    flex-direction: column;
  }
}

.assets-outcome-switcher {
  @media (max-width: $size_991) {
    margin-top: 16px;
  }
}

.day-block {
  position: absolute;
  top: -12px;
  left: 15px;
}

.help-dropdown-text {
  max-width: 560px;
  color: $deep_dive;
}
</style>
