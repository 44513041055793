<template>
  <div class="header_menu_desktop">
    <div class="header__nav">
      <ul class="header__menu">
        <li class="menu__item">
          <router-link
            :to="{ name: 'main' }"
            class="menu__link"
            active-class="menu__link__active"
          >
            рабочий стол
          </router-link>
        </li>
        <li v-if="isUserRegistrated" class="menu__item">
          <router-link
            :to="{ name: 'loans' }"
            class="menu__link"
            active-class="menu__link__active"
            >новые займы</router-link
          >
        </li>
        <li v-if="isUserRegistrated" class="menu__item">
          <router-link
            :to="{ name: 'investments' }"
            class="menu__link"
            active-class="menu__link__active"
            >мои инвестиции</router-link
          >
        </li>
        <li class="menu__item">
          <router-link
            v-if="isUserLoaded && isUserRegistrated"
            :to="{ name: 'analytics' }"
            class="menu__link"
            active-class="menu__link__active"
            >аналитика</router-link
          >
        </li>
        <template v-if="isAdmin">
          <li class="menu__item">
            <a class="menu__link" :href="urlAdmin" target="_blank"
              >управление</a
            >
          </li>
        </template>
      </ul>

      <div class="header__right">
        <button
          v-if="isUserLoaded && isUserRegistrated"
          class="icon_present_button"
          title="пригласить друзей"
          @click="onClickReferral"
        ></button>
        <div style="margin-right: 15px">
          <NotificationMenu
            :is-show-notification-list="isShowNotificationList"
            @change-show-notification="changeShowNotification"
          />
        </div>

        <div
          v-if="isHasCompaniesRoot"
          v-on-clickaway="onClickaway"
          class="header__menulist"
          style="margin-right: 15px"
        >
          <AccountsMenu
            :is-show-account-list="isShowAccountList"
            @change-show-account-list="changeShowAccountList"
            @choose-company-by-id="onClickChooseCompany"
            @add-account="onClickAddAccount"
          />
        </div>

        <div
          v-on-clickaway="onClickaway"
          class="header__menulist"
          style="margin-right: 50px"
        >
          <CompaniesMenu
            :is-show-company-list="isShowCompanyList"
            @change-show-company-list="changeShowCompanyList"
            @choose-company-by-id="onClickChooseCompany"
            @add-investor="onClickAddInvestor"
            @click-settings="onClickSettings"
            @click-logout="onClickLogout"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { directive as vOnClickaway } from 'vue3-click-away';

import headerComposable from '@/composables/headerComposable';
import NotificationMenu from './notification/NotificationMenu.vue';
import AccountsMenu from './accounts/AccountsMenu.vue';
import CompaniesMenu from './companies/CompaniesMenu.vue';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();
const router = useRouter();
const emit = defineEmits(['logout', 'chooseCompanyById', 'addAccount']);

const isShowCompanyList = ref(false);
const isShowAccountList = ref(false);
const isShowNotificationList = ref(false);

const { isHasCompaniesRoot, addInvestor } = headerComposable();

const isUserRegistrated = computed(() => {
  return store.isUserRegistrated;
});
const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const isAdmin = computed(() => {
  return store.isAdmin;
});
const urlAdmin = computed(() => {
  const isProduction = import.meta.env.MODE === 'production';
  if (isProduction) {
    return 'https://admin-i.potok.digital';
  }
  return 'https://admin-i.potok.team';
});

const onClickChooseCompany = (item) => {
  onClickaway();
  emit('chooseCompanyById', item.id);
};
const onClickLogout = () => {
  onClickaway();
  emit('logout');
};
const onClickSettings = () => {
  onClickaway();
  router.push({
    name: 'settings',
  });
};
const onClickAddInvestor = () => {
  onClickaway();
  return addInvestor();
};

const onClickAddAccount = () => {
  onClickaway();
  emit('addAccount', {
    callback: () => {},
  });
};
const onClickaway = () => {
  isShowCompanyList.value = false;
  isShowAccountList.value = false;
  isShowNotificationList.value = false;
};

const changeShowNotification = (value) => {
  onClickaway();
  isShowNotificationList.value = value;
};
const changeShowAccountList = (value) => {
  onClickaway();
  isShowAccountList.value = value;
};
const changeShowCompanyList = (value) => {
  onClickaway();
  isShowCompanyList.value = value;
};
const onClickReferral = () => {
  store.state.modals.isShowModalRefferal = true;
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.header__right {
  display: flex;
  align-items: center;
  float: right;
  text-transform: none;
}

:deep(.header__menulist) {
  float: right;
  position: relative;
  margin-right: 19px;
  cursor: pointer;
  margin-top: 0;

  .header__menulist_text {
    display: inline-block;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $deep_dive;
    margin-top: 3px;

    @media (min-width: $size_767) and (width <= 830px) {
      max-width: 50px;
    }

    @media (width >= 830px) and (width <= 900px) {
      max-width: 100px;
    }

    @media (width >= 900px) and (width <= 1024px) {
      max-width: 150px;
    }

    @media (width >= 1024px) and (width <= 1200px) {
      max-width: 120px;
    }
  }

  .header__menulist_second_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-overflow: initial !important;
    overflow: initial !important;
    margin-top: 2px;
  }

  .header__menulist_button {
    position: relative;
    text-decoration: none;
    padding: 5px 15px 8px;
    background: $background;
    border-radius: 28px !important;
    white-space: nowrap;
    vertical-align: middle;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    &:hover {
      background: rgb(242 244 248 / 60%);
    }

    .desktop-icon {
      margin-left: 5px;
      margin-top: 2px;
    }
  }

  .header__menulist__items {
    position: absolute;
    top: 38px;
    right: 0;
    z-index: 100;
    display: none;
    width: 355px;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    background: #fff;
    box-shadow: 0 1px 4px #e5e9f2;
    border-radius: 6px;

    .header__menulist__item {
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $deep_dive;

      a {
        width: 100%;

        &:hover {
          text-decoration: none !important;
        }
      }

      &:hover {
        background-color: rgb(21 180 180 / 20%);
        color: #000;
      }
    }
  }
}

:deep(.header__menulist__show) {
  & + .header__menulist__items {
    display: block;
  }
}

:deep(.link_btn) {
  &:hover {
    text-decoration: none;
  }
}

.header-user {
  display: inline-block;
  margin: 0 20px 0 0;
  padding: 9px 0 12px;
  color: #fff;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #fff;
    text-decoration: none;
  }

  @media (width <= 1074px) {
    display: none;
  }
}

.header__nav {
  display: flex;
  justify-content: space-between;

  .header__menu {
    float: left;
    margin-left: 30px;
    padding-left: 0;
    display: flex;

    @media (width <= 1052px) {
      margin-left: 20px;
    }

    .menu__item {
      white-space: nowrap;
      display: inline-block;
      margin-right: 30px;
      vertical-align: baseline;

      .menu__link.menu__link__active {
        position: relative;
        color: $deep_dive;
      }

      @media (width <= 991px) {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }

      .menu__link {
        color: $grey;
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        &:hover {
          color: $deep_dive;
        }
      }
    }
  }
}

.link_btn {
  display: flex;
  color: black;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
}

.icon_present_button {
  margin-right: 20px;
}
</style>
