<template>
  <div class="new-investments-sell-item-panel-actions">
    <!-- кнопки для оператора вторичного рынка -->
    <template v-if="props.isSecondMarket">
      <button
        :disabled="props.isLoadingOperation"
        class="panel-actions-accept panel-actions-btn potok-text-body-1 mr-2"
        @click="handleOperation({ status: 'approved', commentValue: null })"
      >
        <template v-if="props.isLoadingOperation">
          <Loader />
        </template>
        <template v-if="!props.isLoadingOperation">
          подтвердить заявку
        </template>
      </button>

      <button
        :disabled="props.isLoadingOperation"
        class="panel-actions-reject panel-actions-btn potok-text-body-1"
        @click="handleReject"
      >
        <template v-if="props.isLoadingOperation">
          <Loader />
        </template>
        <template v-if="!props.isLoadingOperation"> отклонить </template>
      </button>
    </template>
    <!-- кнопки для обычного инвестора -->
    <template v-if="!props.isSecondMarket">
      <button
        :disabled="props.isLoadingOperation"
        class="panel-actions-cancel panel-actions-btn potok-text-body-1 mr-2"
        @click="handleOperation({ status: 'cancel', commentValue: null })"
      >
        <template v-if="props.isLoadingOperation">
          <Loader />
        </template>
        <template v-if="!props.isLoadingOperation"> отменить заявку </template>
      </button>
    </template>
    <!-- модалка при отклонении заявки -->
    <ModalFull
      v-if="isShowRejectModal"
      title="отклонение заявки на продажу"
      class="reject-request-modal"
      @close="closeModalReject"
    >
      <template #content>
        <div
          class="investments-reject-request-modal-text potok-text-body-2 mb-8"
        >
          заявка {{ props.item.number }} будет отклонена, напишите <br />
          причину, чтобы инвестору было понятно
        </div>

        <textarea
          v-model="comment"
          maxlength="500"
          placeholder="причина отклонения"
          class="investments-reject-request-modal-textarea"
          rows="4"
        />
      </template>
      <template #actions>
        <div class="modal-reject-actions button-center">
          <button
            type="button"
            :disabled="props.isLoadingOperation"
            class="button button-secondary button_ok modal-reject-actions-approve mb-5"
            @click.stop="confirmReject"
          >
            <template v-if="!props.isLoadingOperation"> отклонить </template>
            <template v-if="props.isLoadingOperation">
              <Loader />
            </template>
          </button>
          <button
            type="button"
            class="button button-link modal-reject-actions-cancel"
            @click.stop="closeModalReject"
          >
            отмена
          </button>
        </div>
      </template>
    </ModalFull>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import { Loader } from 'potok-uikit';

const props = defineProps(['isSecondMarket', 'isLoadingOperation', 'item']);
const emits = defineEmits(['handleOperation']);

const isShowRejectModal = ref(false);
const comment = ref('');

const handleOperation = ({ status, commentValue }) => {
  emits('handleOperation', { status, comment: commentValue });
};

const handleReject = () => {
  isShowRejectModal.value = true;
};

const closeModalReject = () => {
  comment.value = '';
  isShowRejectModal.value = false;
};

const confirmReject = () => {
  handleOperation({
    status: 'rejected',
    commentValue: comment.value,
  });
};
</script>

<style scoped lang="scss">
@use '../../../../scss/variables.scss' as *;

.new-investments-sell-item-panel-actions {
  display: flex;
  margin-left: auto;

  @media (max-width: $size_991) {
    flex-direction: column;
    margin-left: initial;
  }
}

.panel-actions-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 44px;
  padding: 10px 18px;
  color: $breakwater;
  background: transparent;
  border-radius: 6px;
  border: 1px solid $input;

  &:hover {
    background: rgb(242 244 248);
    border: 1px solid rgb(242 244 248);
  }
}

.panel-actions-reject {
  width: 117px;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

.panel-actions-cancel {
  width: 167px;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

.panel-actions-accept {
  width: 193px;
  background: $breakwater;
  border: 1px solid $breakwater;
  color: $white;

  @media (max-width: $size_991) {
    width: 100%;
    margin-bottom: 10px;
  }

  &:hover {
    background: $white;
    color: $breakwater;
    border: 1px solid $breakwater;
  }
}

.reject-request-modal {
  :deep(.modal_block_container) {
    max-width: 440px;
  }

  :deep(.modal_block_title) {
    justify-content: center;
  }

  :deep(.modal_block_actions) {
    margin-top: 20px;
  }
}

.investments-reject-request-modal-text {
  text-align: center;
}

.investments-reject-request-modal-textarea {
  width: 100%;
  resize: none;
  padding: 14px 20px;
  border: none;
  border-radius: 8px;
  background: $background;

  &::placeholder {
    color: $grey;
  }
}

.modal-reject-actions {
  display: flex;
  flex-direction: column;
}

.modal-reject-actions-cancel {
  color: $grey;

  &:hover {
    opacity: 1;
    color: $deep_dive;
  }
}

.modal-reject-actions-approve {
  height: 60px;
}
</style>
