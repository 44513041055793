<template>
  <ModalFull
    title="Инвестируйте в займы надежному бизнесу с поручительством"
    @close="emits('close')"
  >
    <template #content>
      <div class="alfa-modal-content">
        <div class="alfa-modal-description potok-text-body-2">
          {{ descText }}
        </div>
        <div class="alfa-modal-why mt-7">
          <div class="alfa-modal-why-title potok-text-h2 mb-5">
            почему стоит инвестировать в Потоке
          </div>
          <div
            v-for="item in alfaWhyList"
            :key="item.title"
            class="alfa-modal-why-item mb-7"
          >
            <div class="alfa-modal-why-item-title potok-text-body-1">
              {{ item.title }}
            </div>
            <div class="alfa-modal-why-item-text potok-text-body-2 mt-2">
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="alfa-modal-how-works mt-7">
          <div class="alfa-modal-how-works-title potok-text-h2 mb-5">
            как это работает
          </div>
          <div
            v-for="(item, i) in alfaHowWorks"
            :key="i"
            class="alfa-modal-how-works-item mb-7 potok-text-body-2"
          >
            <div class="alfa-modal-how-works-item-numbers mr-3">
              {{ `${i + 1}.` }}
            </div>
            <div class="alfa-modal-how-works-item-text">
              {{ item }}
            </div>
          </div>
        </div>

        <div class="alfa-modal-welcome mt-7">
          <div class="alfa-modal-welcome-text potok-text-body-1">
            добро пожаловать<br />
            в Поток!
          </div>
        </div>

        <button class="alfa-modal-continue-btn mt-5" @click="emits('close')">
          продолжить
        </button>
      </div>
    </template>
  </ModalFull>
</template>
<script setup>
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

const emits = defineEmits(['close']);

const descText =
  'наша платформа Поток, благодаря уникальной системе скоринга π-score, основанной на системе' +
  'искусственного интеллекта, отобрала для вас проверенные компании, которые активно развиваются в том числе ' +
  'и за счётзаимствований — с обязательным поручительством собственников бизнеса';

const alfaWhyList = [
  {
    title: 'высокая доходность',
    text: '24,2% заработали инвесторы Потока в среднем за последние 12 месяцев',
  },
  {
    title: 'наш аукционер — Московская биржа ',
    text:
      'в 2022 году нашим акционером стала Московская биржа, мы старейшая инвестиционная платформа' +
      'в России — работаем с 2016 года и состоим в реестре ЦБ РФ',
  },
  {
    title: 'качественные заёмщики',
    text: 'акционеры Потока инвестируют вместе с вами не менее 10% в каждый заём, чтобы вы не сомневались в качестве заемщиков',
  },
];

const alfaHowWorks = [
  'присоединитесь к правилам платформы и загрузите паспорт',
  'подключите подходящую вам стратегию инвестирования по соотношению Риск/Доходность',
  'пополните счет на любую сумму — для хорошей диверсификации мы рекомендуем пополнить счет на сумму от 20 тыс рублей',
  'наш алгоритм проинвестирует ваши средства в займы в соответствие с выбранной стратегией и будет ежедневно реинвестировать возвраты по ним',
  'вы регулярно получаете выплаты на свой счёт от заемщиков',
  'когда захотите вывести деньги — выключите реинвестирование и получите их вместе с прибылью',
];
</script>

<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;
@use '../../../../scss/typography.scss' as *;

:deep(.modal_block_container) {
  max-width: 736px;
}

.alfa-modal-content {
  width: 100%;
}

.alfa-modal-description {
  color: $grey;
  padding-bottom: 30px;
  border-bottom: 1px solid $dividers;
}

.alfa-modal-why {
  border-bottom: 1px solid $dividers;
}

.alfa-modal-why-title {
  text-transform: initial;
}

.alfa-modal-why-item-text {
  color: $grey;
}

.alfa-modal-how-works-item {
  display: flex;
}

.alfa-modal-how-works-item-numbers {
  position: relative;
  top: 3px;
}

.alfa-modal-welcome {
  height: 122px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  background-color: $breakwater;
  background-image: url('@img/modals/alfa-onboard-modal.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.alfa-modal-welcome-text {
  color: $white;
  text-align: center;
}

.alfa-modal-continue-btn {
  width: 100%;
  padding: 18px;
  border-radius: 8px;
  border: 1px solid $breakwater;
  background: transparent;
}
</style>
