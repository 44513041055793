<template>
  <div>
    <div class="onboard-bonus-banner">
      <div class="onboard-bonus-banner-title">Бонус 10 000 руб</div>
      <div class="onboard-bonus-banner-text potok-text-h2 mt-5">
        Пополните свой инвестиционный счет прямо сейчас и получите
        <span class="onboard-bonus-banner-text-special"> бонуc </span>
        на первое пополнение до 10 000 руб.
      </div>
      <button
        class="onboard-bonus-banner-btn potok-text-body-1"
        @click="onClickBonusModal(true)"
      >
        подробные условия
      </button>
    </div>

    <DashboardModalBonus
      v-if="isShowBonusModal"
      @close="onClickBonusModal(false)"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import DashboardModalBonus from '@/components/Dashboard/modals/DashboardModalBonus.vue';

const isShowBonusModal = ref(false);

const onClickBonusModal = () => {
  window.open(
    'https://files.potok.digital/Документы/раздел правила платформы/Бонусная программа для инвесторов.pdf',
    '_blank',
  );
};
</script>

<style scoped lang="scss">
@use '../../../../scss/variables.scss' as *;
@use '../../../../scss/typography.scss' as *;

.onboard-bonus-banner {
  padding: 30px 20px 20px;
  background-color: $breakwater;
  border-radius: 14px;
}

.onboard-bonus-banner-title {
  font-size: 38px;
  line-height: 38px;
  font-weight: 500;
  text-transform: initial;
  color: $white;
}

.onboard-bonus-banner-text {
  color: $white;

  @media (max-width: $size_767) {
    @include potok-text-body-1-bold;
  }
}

.onboard-bonus-banner-text-special {
  color: $tiffany;
}

.onboard-bonus-banner-btn {
  margin-top: 60px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid $white;
  color: $white;
  background-color: transparent;
  padding: 10px 18px;

  &:hover {
    border: 1px solid $white;
    background-color: $white;
    color: $breakwater;
  }
}
</style>
