<template>
  <PotokTable :columns="columns" :rows="props.rows">
    <template #body="props">
      <PotokTableRow
        hover
        class="loans-first-table-row"
        @click="toLoanPage(props.row.id)"
      >
        <PotokTableCell>
          <div class="table-info-container">
            <LoansBorrowerTypeIcon :type="getBorrowerType(props.row.borrower.typeId)" />

            <div class="loans-first-table-info ml-4">
              <div class="loans-first-table-info-top mb-1">
                <LoansItemRating
                  v-if="props.row.borrower.modelBucket"
                  :rating="props.row.borrower.modelBucket"
                />
                <PotokChip
                  v-if="props.row.mmRefundedLoansCount > 0"
                  text="опытный"
                  color-text="#04CCD9"
                  color-background="rgba(4, 204, 217, 0.1)"
                  class="ml-2"
                  @click.stop
                >
                  <template #dropdown>
                    <div class="popup">
                      <div v-close-popper class="popup-close"></div>
                      <div class="popup-text">
                        Опытный заёмщик — имеет как минимум один погашенный заём
                      </div>
                    </div>
                  </template>
                </PotokChip>
              </div>

              <span class="text-no-transform mb-1">{{ props.row.borrower.shortName }}</span>
              <span class="potok-text-uppercase potok-color-grey">
                {{ props.row.number }} от {{ formatDate(props.row.fundsStartedAt, 'dd.MM.yyyy') }}
              </span>
            </div>
          </div>
        </PotokTableCell>

        <PotokTableCell>
          {{ getRateText(props.row.loanRate, props.row.irr) }}
        </PotokTableCell>

        <PotokTableCell>
          {{ 
            formatCurrency(
              decimal(props.row.raisedAmount).plus(props.row.pifReservedAmount),
              0,
            )
          }} ₽
        </PotokTableCell>

        <PotokTableCell>
          {{ formatCurrency(props.row.loanAmount, 0) }} ₽
        </PotokTableCell>

        <PotokTableCell>
          <div class="loans-first-table-goal">
            <span class="loans-first-table-goal-text">{{ getLoanType(props.row.loanType) }}</span>
            <PotokButton
              text="инвестировать"
              class="loans-first-table-btn"
              @click.stop="toInvestmentPage(props.row.id)"
            />
          </div>
        </PotokTableCell>
      </PotokTableRow>
    </template>
  </PotokTable>

  <PaginationDesktop
    v-model:current-page="currentPage"
    v-model:selected-page-range="selectedPageRange"
    :total-items-count="props.rows.length"
    :items-pluralize-array="['займа','займов','займов']"
  />
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import PotokChip from '@/components/_generic/PotokChip.vue';
import PotokTable from '@/components/_generic/table/PotokTable/PotokTable.vue';
import PotokTableCell from '@/components/_generic/table/PotokTable/PotokTableCell.vue';
import PotokTableRow from '@/components/_generic/table/PotokTable/PotokTableRow.vue';
import PaginationDesktop from '@/components/_generic/pagination/paginationDesktop.vue';

import { createRateText } from '@/utils/strategies/strategiesUtils';
import {
  formatCurrency,
  formatDate,
  decimal,
} from '@/utils/commonUtils/utils';

import constants from '@/constants';
import LoansBorrowerTypeIcon from '@/components/Loans/LoansBorrowerTypeIcon.vue';
import LoansItemRating from '@/components/Loans/LoansItemRating.vue';

const router = useRouter();

const props = defineProps(['rows']);

const selectedPageRange = ref(20);
const currentPage = ref(1);

const columns = [
  {
    label: 'заемщик',
  },
  {
    label: 'ставка',
    dropdownMessage: 'номинальная / эффективная',
  },
  {
    label: 'сумма займа',
  },
  {
    label: 'остаток сбора',
  },
  {
    label: 'цель займа',
  },
];

const getLoanType = (type) => {
  const textsByLoanType = {
    tender: 'госзаказ',
    tender_no_purpose: 'госзаказ',
    factoring: 'факторинг',
    hidden_factoring: 'факторинг',
    main: 'развитие',
    akbars: 'развитие',
    b2b: 'развитие',
  };

  return textsByLoanType[type];
};

const getRateText = (rate, irr) => {
  let text = `${createRateText(rate)}`

  if (irr) {
    text += ` / ${createRateText(props.row.irr)}`
  }

  text += '%';

  return text;
};

const getBorrowerType = (typeId) => {
  if (typeId === constants.COMPANY_TYPES_ENTREPRENEUR) return 'ENTREPRENEUR';
  return 'COMPANY';
}

const toInvestmentPage = (id) => {
  router.push({ name: 'loanInvestment', params: { id } });
};

const toLoanPage = (id) => {
  router.push({ name: 'loan', params: { id } });
};
</script>

<style lang="scss" scoped>
.table-info-container {
  display: flex;
  align-items: center;
}

.loans-first-table-info {
  display: flex;
  flex-direction: column;
}

.loans-first-table-info-top {
  display: flex;
  align-items: center;
}

.loans-first-table-goal {
  display: flex;
  align-items: center;
}

.loans-first-table-goal-text {
  display: initial;
  height: fit-content;
}

.loans-first-table-goal .loans-first-table-btn {
  opacity: 0;
}

.loans-first-table-row {
  &:hover {
    .loans-first-table-goal-text {
      display: none;
    }

    .loans-first-table-btn {
      opacity: 1;
    }
  }
}
</style>
