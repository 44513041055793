<template>
  <div class="form_input">
    <label class="form_input__label" :for="`input-${uuid}`">
      <div
        v-if="value"
        class="form_input__title"
        :class="{
          'potok-color-red': isFormErrorClass,
        }"
      >
        {{ props.title }}
        <slot name="label"></slot>
      </div>
      <VueSuggestions
        :id="`input-${uuid}`"
        v-model="value"
        class="form_input__input"
        :placeholder="props.title"
        :options="suggestionOptions(props.suggestionField)"
        :disabled="props.disabled"
        @input="inputValue"
      ></VueSuggestions>
    </label>
    <div v-if="props.disabled" class="form_input__shadow"></div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { uuidv4 } from '@/utils/commonUtils/utils';
import VueSuggestions from '@/libs/suggestions/vue-suggestions.vue';

const suggestionOptions = (part) => {
  return {
    token: import.meta.env.VITE_APP_THIRD_PARTY_SERVICE_DADATA_TOKEN,
    type: part.type,
    scrollOnFocus: false,
    triggerSelectOnBlur: true,
    triggerSelectOnEnter: true,
    addon: 'none',
    params: {
      parts: [part.code],
    },
    onSelect: onDadataSelect.bind(this, part),
  };
};
const onDadataSelect = (part, suggestion) => {
  if (!suggestion) {
    return;
  }
  value.value = suggestion.data[part.dadataFieldName] || suggestion.value;
};

const props = defineProps({
  modelValue: String,
  vmaska: [Object, String],
  disabled: Boolean,
  title: String,
  validate: Object,
  suggestionField: Object,
});
const emit = defineEmits(['update:modelValue', 'inputValue']);

const uuid = uuidv4();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const isFormErrorClass = computed(() => {
  return props.validate?.$invalid && props.validate?.$dirty;
});

const inputValue = () => {
  props.validate ? props.validate.$touch() : null;
  emit('inputValue');
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

label {
  width: 100%;
}

.form_input {
  position: relative;
}

.form_input__shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.form_input__title {
  position: absolute;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $grey;
  top: -5px;
  left: 14px;
  background-color: white;
  padding: 0 5px;
  display: flex;
  align-items: center;
}

.form_input__input {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $deep_dive;
  display: block;
  width: 100%;
  height: 44px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #dce0e3;
  border-radius: 8px;

  &:disabled {
    color: $grey;
  }

  &::placeholder {
    color: $grey;
  }
}

:deep(.suggestions-suggestions) {
  margin-top: 20px;
  background: #fff;
  box-shadow: 0 2px 22px rgb(13 39 65 / 12%);
  border-radius: 12px;
  border: 0;
  padding: 10px;
}
</style>
