<template>
  <div class="content-block" style="padding: 0 16px">
    <div class="row">
      <table class="table_investments">
        <thead>
          <tr>
            <th>инвестиция</th>
            <th>число инвестиций</th>
            <th>сумма инвестиций</th>
            <th>остаток осн. долга</th>
            <th>процентный доход</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="table_investments_title">
              <router-link
                v-if="props.stats.funded.count > 0"
                :to="{
                  name: 'investments',
                  query: { status: 'funded' },
                }"
              >
                текущие
              </router-link>
              <span v-if="props.stats.funded.count === 0">текущие</span>
            </td>
            <td>{{ formatCurrency(props.stats.funded.count, 0) }}</td>
            <td>{{ formatCurrency(props.stats.funded.amount, 0) }}&nbsp;₽</td>
            <td>
              {{
                formatCurrency(props.stats.funded.balanceOfMainDebt, 0)
              }}&nbsp;₽
            </td>
            <td>{{ formatCurrency(props.stats.funded.income, 0) }}&nbsp;₽</td>
          </tr>
          <tr>
            <td class="table_investments_title">
              <router-link
                v-if="props.stats.restructured.count > 0"
                :to="{
                  name: 'investments',
                  query: { status: 'restructured' },
                }"
              >
                реструктурированные
              </router-link>
              <span v-if="props.stats.restructured.count === 0"
                >реструктурированные</span
              >
            </td>
            <td>{{ formatCurrency(props.stats.restructured.count, 0) }}</td>
            <td>
              {{ formatCurrency(props.stats.restructured.amount, 0) }}&nbsp;₽
            </td>
            <td>
              {{
                formatCurrency(props.stats.restructured.balanceOfMainDebt, 0)
              }}&nbsp;₽
            </td>
            <td>
              {{ formatCurrency(props.stats.restructured.income, 0) }}&nbsp;₽
            </td>
          </tr>
          <tr>
            <td class="table_investments_title">
              <router-link
                v-if="props.stats.refunded.count > 0"
                :to="{
                  name: 'investments',
                  query: { status: 'refunded' },
                }"
              >
                возвращенные
              </router-link>
              <span v-if="props.stats.refunded.count === 0">возвращенные</span>
            </td>
            <td>{{ formatCurrency(props.stats.refunded.count, 0) }}</td>
            <td>{{ formatCurrency(props.stats.refunded.amount, 0) }}&nbsp;₽</td>
            <td>
              {{
                formatCurrency(props.stats.refunded.balanceOfMainDebt, 0)
              }}&nbsp;₽
            </td>
            <td>{{ formatCurrency(props.stats.refunded.income, 0) }}&nbsp;₽</td>
          </tr>
          <tr>
            <td class="table_investments_title">
              <router-link
                v-if="props.stats.softOverdue.count > 0"
                :to="{
                  name: 'investments',
                  query: { status: 'softOverdue' },
                }"
              >
                в просрочке
              </router-link>
              <span v-if="props.stats.softOverdue.count === 0"
                >в просрочке</span
              >
            </td>
            <td>{{ formatCurrency(props.stats.softOverdue.count, 0) }}</td>
            <td>
              {{ formatCurrency(props.stats.softOverdue.amount, 0) }}&nbsp;₽
            </td>
            <td>
              {{
                formatCurrency(props.stats.softOverdue.balanceOfMainDebt, 0)
              }}&nbsp;₽
            </td>
            <td>
              {{ formatCurrency(props.stats.softOverdue.income, 0) }}&nbsp;₽
            </td>
          </tr>
          <tr>
            <td class="table_investments_title">
              <router-link
                v-if="props.stats.default.count > 0"
                :to="{
                  name: 'investments',
                  query: { status: 'default' },
                }"
              >
                в дефолте
              </router-link>
              <span v-if="props.stats.default.count === 0">в дефолте</span>
            </td>
            <td>{{ formatCurrency(props.stats.default.count, 0) }}</td>
            <td>{{ formatCurrency(props.stats.default.amount, 0) }}&nbsp;₽</td>
            <td>
              {{
                formatCurrency(props.stats.default.balanceOfMainDebt, 0)
              }}&nbsp;₽
            </td>
            <td>{{ formatCurrency(props.stats.default.income, 0) }}&nbsp;₽</td>
          </tr>
          <tr>
            <td class="table_investments_title">
              <router-link
                v-if="props.stats.sold.count > 0"
                :to="{
                  name: 'investments',
                  query: { status: 'sold' },
                }"
              >
                выкупленные
              </router-link>
              <span v-else>выкупленные</span>
            </td>
            <td>{{ formatCurrency(props.stats.sold.count, 0) }}</td>
            <td>{{ formatCurrency(props.stats.sold.amount, 0) }}&nbsp;₽</td>
            <td>
              {{ formatCurrency(props.stats.sold.balanceOfMainDebt, 0) }}&nbsp;₽
            </td>
            <td>{{ formatCurrency(props.stats.sold.income, 0) }}&nbsp;₽</td>
          </tr>
          <tr class="table_investments_summarise">
            <td>итого</td>
            <td>{{ formatCurrency(props.stats.total.count, 0) }}</td>
            <td>{{ formatCurrency(props.stats.total.amount, 0) }}&nbsp;₽</td>
            <td>
              {{
                formatCurrency(props.stats.total.balanceOfMainDebt, 0)
              }}&nbsp;₽
            </td>
            <td>{{ formatCurrency(props.stats.total.income, 0) }}&nbsp;₽</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import { formatCurrency } from '@/utils/commonUtils/utils';

const props = defineProps(['stats']);
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.table_investments {
  width: 100%;

  td {
    border-top: 1px solid #ebedf4;
    padding: 18px 20px;
    text-align: left;

    &:first-child {
      text-align: left;
    }
  }

  th {
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: $grey;
    padding: 18px 20px 15px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: left;
    background: rgb(232 236 240 / 30%);

    &:first-child {
      text-align: left;
    }
  }

  .table_investments_title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .table_investments_summarise {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    td {
      padding: 22px 20px 31px;
      text-align: left;
    }
  }
}
</style>
