<template>
  <PotokPage title="Займы Поток.Холдинг">
    <template v-if="!isUserLoaded">
      <div class="icon_loading"></div>
    </template>

    <template #titleRight>
      <div class="title_right_wrapper">
        <div class="title_right_name">доступно на счете</div>
        <div class="title_right_value">
          <span
            >{{
              formatCurrency(getFreeBalance + getCashboxRealAmount, 0)
            }} </span
          >&nbsp;₽
        </div>
      </div>
    </template>

    <template v-if="isUserLoaded">
      <notification-message />
      <NeedJoinEula v-if="needJoinEula" />
      <RejectedPassport
        v-if="isShowRejectedPassportModal"
        @close="isShowRejectedPassportModal = false"
      />
      <UserMessage
        class="mb-4"
        @open-rejected-passport="isShowRejectedPassportModal = true"
      />

      <PotokLoansContent />
    </template>
  </PotokPage>
</template>

<script setup>
import { onMounted, computed, ref, watch } from 'vue';
import { useStateStore } from '@/store/stateStore';
import constants from '@/constants';

import { PotokPage } from 'potok-uikit';
import NotificationMessage from '@/components/NotificationMessage/NotificationMessage.vue';
import NeedJoinEula from '@/components/_generic/messages/NeedJoinEula.vue';
import RejectedPassport from '@/components/_modals/RejectedPassport.vue';
import UserMessage from '@/components/_generic/messages/UserMessage.vue';
import tracker from '@/tracker';
import { formatCurrency } from '@/utils/commonUtils/utils';
import PotokLoansContent from '@/components/Loans/PotokLoansContent.vue';

const store = useStateStore();

const isShowRejectedPassportModal = ref(false);

const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});

const needJoinEula = computed(() => {
  if (
    store.selectedCompany &&
    store.selectedCompany.typeId !== constants.companyTypes.person
  ) {
    return (
      store.selectedCompany.isEulaApproved &&
      !store.selectedCompany.isAgreeRules
    );
  }
  return false;
});

const getFreeBalance = computed(() => {
  return store.getFreeBalance;
});
const getCashboxRealAmount = computed(() => {
  return store.getCashboxRealAmount;
});

watch(
  () => {
    return store.selectedCompany?.id;
  },
  () => {
    store.fetchActiveBalance({
      companyId: store.selectedCompany?.id,
    });
  },
);

onMounted(() => {
  tracker.queue(tracker.commands.SEND, 'Investor_LK_New_Projects');
  store.fetchActiveBalance({
    companyId: store.selectedCompany?.id,
  });
});
</script>
<style lang="scss" scoped>
@use '../scss/variables.scss' as *;

.new_project_title {
  display: flex;
  justify-content: space-between;

  @media (max-width: $size_767) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.title_right_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: rgb(23 59 87 / 6%);
  border-radius: 8px;
  padding: 8px 20px 14px;
  border-right: 3px solid #173b57;

  @media (max-width: $size_767) {
    align-items: center;
    margin-top: 17px;
    padding: 3px 20px 10px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-right: initial;
    border-left: 3px solid #173b57;
  }
}

.title_right_name {
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $grey;
  margin-top: 6px;
}

.title_right_value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $deep_dive;
  margin-top: 5px;
}
</style>
