<template>
  <div class="row spb">
    <div>
      <template v-if="currentStepMode === 1">
        <DashboardOnboardRefillBalanceSPBStep1
          v-model:amount-value="amountValue"
          :error-message="errorMessage"
          :loading="loading"
          :on-input="onInput"
          :data="props.data"
          @next-step="nextStep"
        />
      </template>
      <template v-if="currentStepMode === 2">
        <DashboardOnboardRefillBalanceSPBStep2Desktop
          v-if="!isMobile"
          :amount-value="amountValue"
          :qr-code="qrCode"
          @prev-step="goBack"
        />

        <DashboardOnboardRefillBalanceSPBStep2Mobile
          v-if="isMobile"
          :amount-value="amountValue"
          :qr-code="qrCode"
          :link-payment="linkPayment"
          @prev-step="goBack"
        />
      </template>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';

import detectDevice from '@/composables/detectDeviceComposable.js';
import server from '@/server/index';
import { useStateStore } from '@/store/stateStore';

import DashboardOnboardRefillBalanceSPBStep1 from './DashboardOnboardRefillBalanceSPBStep1.vue';
import DashboardOnboardRefillBalanceSPBStep2Desktop from './DashboardOnboardRefillBalanceSPBStep2Desktop.vue';
import DashboardOnboardRefillBalanceSPBStep2Mobile from './DashboardOnboardRefillBalanceSPBStep2Mobile.vue';

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});

const store = useStateStore();

const amountValue = ref(0);
const loading = ref(false);
const currentStepMode = ref(1);
const errorMessage = ref(null);

const { isMobile } = detectDevice();

const qrCode = ref(null);
const linkPayment = ref(null);
const qrCodeId = ref(null);

const isValidApply = () => {
  if (!amountValue.value || amountValue.value <= 0) {
    errorMessage.value = 'введите сумму пополнения';
    return false;
  }
  if (amountValue.value > 1000000) {
    errorMessage.value = 'максимальная сумма 1 000 000 ₽';
    return false;
  }
  return true;
};

const getPaymentStatus = () => {
  const params = {
    id: qrCodeId.value,
  };

  return server.getSpbQRCodeStatus
    .send(
      {},
      {
        params,
      },
    )
    .pipe(
      ({ data }) => {
        if (data.status === 'ACCEPTED') {
          store.setNotification({
            timer: 5000,
            message:
              'Успешное пополнение по СБП, деньги скоро зачислятся на ваш счет. Можно завершить регистрацию',
            title: '',
          });
        }
        if (data.status === 'REJECTED') {
          store.setNotification({
            timer: 5000,
            message:
              'Что-то пошло не так при пополнении по СБП, пожалуйста попробуйте еще раз или пополните счет по реквизитам',
            title: '',
            isError: true,
          });

          nextStep();
        }
        if (
          data.status === 'NOT_STARTED' ||
          data.status === 'RECEIVED' ||
          data.status === 'IN_PROGRESS'
        ) {
          setTimeout(() => {
            getPaymentStatus();
          }, 5000);
        }
      },
      ({ response }) => {
        if (response.status === 400) {
          setTimeout(() => {
            getPaymentStatus();
          }, 5000);
        }
      },
    )
    .exec();
};

const nextStep = () => {
  if (!isValidApply()) {
    return;
  }

  const accountNumber = store.selectedCompany?.investorAccountNumber;

  loading.value = true;

  const query = {
    accountNumber,
    amount: String(amountValue.value),
  };

  return server.postSpbQRCode
    .send(query)
    .pipe(
      (result) => {
        loading.value = false;
        const data = result.data || {};
        qrCode.value = data.image;
        linkPayment.value = data.payload;
        qrCodeId.value = data.id;

        getPaymentStatus();

        currentStepMode.value = 2;
      },
      () => {
        loading.value = false;
      },
    )
    .exec();
};

const goBack = () => {
  currentStepMode.value = 1;
};

const onInput = () => {
  errorMessage.value = null;
};
</script>
<style lang="scss" scoped>
.spb {
  margin-top: 26px;
}
</style>
