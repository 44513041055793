<template>
  <div class="row header_panel">
    <router-link :to="getRouterLinkLogo()" class="header-logo">
      <PotokImg height="20" width="72" :src="imageConstants.logo" />
      <template v-if="store.isAlfaCustomer">
        <div>+</div>
        <div>
          <i class="icon_alfa"></i>
        </div>
      </template>
    </router-link>

    <Loader v-if="!isUserLoaded" />

    <HeaderMenuDesktop
      v-if="isUserLoaded && !isMobile"
      @choose-company-by-id="chooseCompanyById"
      @logout="logoutAsync"
      @add-account="addAccount"
    />
    <HeaderMenuMobile
      v-if="isUserLoaded && isMobile"
      @choose-company-by-id="chooseCompanyById"
      @logout="logoutAsync"
      @add-account="addAccount"
    />
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import constants from '@/constants';
import server from '@/server';
import { getProp } from '@/utils/commonUtils/utils';
import tracker from '@/tracker';
import detectDevice from '@/composables/detectDeviceComposable';
import { Loader } from 'potok-uikit';
import PotokImg from '@/components/_generic/PotokImg.vue';
import imageConstants from '@/constants/imageConstants';
import HeaderMenuDesktop from './HeaderMenuDesktop.vue';
import HeaderMenuMobile from './HeaderMenuMobile.vue';
import { useStateStore } from '@/store/stateStore';

const CHECK_TOKEN_TIME = 900000;
const LIFE_TIME_SESSION = 1800000;

const { isMobile } = detectDevice();
const store = useStateStore();
const router = useRouter();
const route = useRoute();

const intervalId = ref(null);

const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});
const companies = computed(() => {
  return store.companies;
});
const firstIncomeAt = computed(() => {
  return store.state.user.firstIncomeAt;
});
const isFirstIncomeAt = computed(() => {
  return store.isFirstIncomeAt;
});
const isOnboardingCompleted = computed(() => {
  return user.value.isOnboardingComplete === true;
});
const user = computed(() => {
  return store.state.user;
});

const logoutAsync = () => {
  const query = {};
  return server.postUsersLogout.send(query).pipe(logout, logout).exec();
};
const logout = () => {
  store.logout();
  router.push({
    name: 'main',
  });
};
const getCompanies = () => {
  store.fetchCompanies({
    callback: (modifiedCompanies) => {
      const companyId = selectCompany(modifiedCompanies);

      store.state.preferences = { companyId };
    },
  });
};

const selectCompany = (_companies) => {
  /**
   * Логика следующая:
   * при начально открытие страницы
   * - если нет компаний то возращаем null
   * - если company_id передается в параметре query и она есть в списке компаний то то выбираем ее
   * - если есть выбранная компания сохраненая в localStorage и она есть в списке компаний то выбираем ее
   * - иначе выбирается первая компания из списка
   */

  if (_companies.length === 0) {
    return null;
  }

  const selectedQueryCompanyId = route.query?.company_id;
  if (selectedQueryCompanyId) {
    const isExist = _companies.find((i) => i.id === selectedQueryCompanyId);
    if (isExist) {
      return selectedQueryCompanyId;
    }
  }

  const savedCompanyId = store.state.preferences.companyId;
  if (savedCompanyId) {
    const isExist = _companies.find((i) => i.id === savedCompanyId);
    if (isExist) {
      return savedCompanyId;
    }
  }
  return _companies[0].id;
};
const getUsersMeAsync = () => {
  return server.getUsersMe
    .send()
    .pipe(onGetUsersMeAsyncSuccess, () => {
      store.setUserLoading(false);
    })
    .exec();
};
const onGetUsersMeAsyncSuccess = ({ data: user }) => {
  store.merge({ user });
  store.setUserLoading(false);

  tracker.queue(tracker.commands.PARAMS, {
    userId: getProp(store.state.user, 'id', ''),
  });
};

const setNotificationInterval = () => {
  if (!intervalId.value) {
    intervalId.value = setInterval(() => {
      getNotificationsAsync();
    }, 5000);
  }
};

const getNotificationsAsync = () => {
  const query = {
    isGlobal: false,
  };
  return server.getNotification
    .send(query)
    .pipe(onGetNotificationAsyncSuccess)
    .exec();
};
const onGetNotificationAsyncSuccess = (obj) => {
  const data = obj.data || [];
  if (!Array.isArray(data)) {
    return;
  }
  store.pushMenuNotifications(data);
  const counter = data.filter((i) => !i.isViewed).length;
  store.merge({
    menuNotification: {
      counter,
    },
  });
};

const getRouterLinkLogo = () => {
  return { name: 'main' };
};
const chooseCompanyById = (companyId) => {
  store.merge({
    preferences: { companyId },
  });
  if (route.name === 'autoInvest') {
    if (companyId) {
      router.push({
        name: 'autoInvest',
      });
    } else {
      router.push({
        name: 'loans',
      });
    }
  }
};
const forwardOAuth2 = (location) => {
  const { search } = location;
  const shouldRedirectToOAuth2 =
    search.includes('client_id=') &&
    search.includes('redirect_uri=') &&
    search.includes('response_type=code');
  if (shouldRedirectToOAuth2) {
    const { token } = store.state.session;
    const tokenParam = `access_token=${token}`;
    const params = search.slice(1);
    const newQueryString = params.split('&').concat(tokenParam).join('&');
    const oauth2AuthorizationUri = `/oauth2/authorize?${newQueryString}`;
    location.assign(oauth2AuthorizationUri);
  }
};

const runCheckTimeAuthToken = () => {
  if (!store.state.session || !store.state.session.token) {
    return router.push({
      name: 'main',
    });
  }

  if (store.state.session && store.state.session.startSession) {
    const timeDelta = new Date().getTime() - store.state.session.startSession;
    if (timeDelta > LIFE_TIME_SESSION) {
      server.getUsersMe.send().exec();
    }
  }

  setTimeout(() => {
    runCheckTimeAuthToken();
  }, CHECK_TOKEN_TIME);
};
const addAccount = (obj) => {
  if (!firstIncomeAt.value) {
    return store.pushAlert({
      message: constants.messages.finishReg,
      title: 'Внимание!',
    });
  }

  let companyId;
  let parentCompanyName;
  if (!selectedCompany.value.parentId) {
    companyId = selectedCompany.value.id;
    parentCompanyName = selectedCompany.value.shortName;
  } else {
    const parentCompany = companies.value.find(
      (i) => i.id === selectedCompany.value.parentId,
    );
    companyId = parentCompany.id;
    parentCompanyName = parentCompany.shortName;
  }

  const message_text = `
      <span style="font-weight: 500;font-size: 20px;line-height: 26px;text-align: center;letter-spacing: -0.01em;">
      подтвердите добавление нового счета по инвестору:<br>
      <span style="text-transform: none;font-weight: 400;font-size: 16px;line-height: 24px;">
        ${parentCompanyName}
      </span></span>
      `;
  store.pushConfirm({
    message: message_text,
    action_text: 'подтвердить',
    callback: (removeConfirm) => {
      const params = {
        id: companyId,
      };
      obj.callback = removeConfirm;
      return server.postCompaniesId
        .send({}, { params })
        .pipe(onPostCompaniesAsyncSuccess.bind(this, obj))
        .exec();
    },
  });
};
const onPostCompaniesAsyncSuccess = (data, result) => {
  getCompanies();
  if (data && data.callback) {
    data.callback();
  }
  if (result.data && result.data.isSuccessful) {
    if (result.data.companyId) {
      chooseCompanyById(result.data.companyId);
    }
    store.pushAlert({
      message: `Новый счет успешно создан`,
      title: 'Внимание!',
    });
  }
};

watch(
  isOnboardingCompleted,
  () => {
    if (isOnboardingCompleted.value && !isFirstIncomeAt.value) {
      setNotificationInterval();
    }
  },
  { immediate: true },
);

watch(isFirstIncomeAt, () => {
  if (isFirstIncomeAt.value && intervalId.value) {
    clearInterval(intervalId.value);
  }
});

const init = () => {
  // Redirect when 401
  server.onError = (obj) => {
    if (!obj || !obj.response) {
      return;
    }
    const { response } = obj;
    if (response.status === 401) {
      logout();
    }
  };

  getCompanies();
  getUsersMeAsync();
  getNotificationsAsync();
};

init();

onMounted(() => {
  forwardOAuth2(window.location);
  setTimeout(() => {
    runCheckTimeAuthToken();
  }, CHECK_TOKEN_TIME);
});
onBeforeUnmount(() => {
  server.onError = null;
  clearInterval(intervalId.value);
});
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.header_panel {
  height: 80px;

  @media (max-width: $size_767) {
    height: 44px;
  }
}

.header-logo {
  display: flex;
  align-items: center;
  color: $deep_dive;
  margin-top: 15px;
  text-decoration: none;
  position: relative;
  margin-right: 30px;

  @media (max-width: $size_767) {
    margin-top: 11px;
  }

  i {
    position: absolute;
    top: 1px;
  }
}

.header_panel__alfa_icon {
  margin-left: 5px;
  display: flex;
  align-items: center;
  padding-top: 2px;
}
</style>
