<template>
  <div>
    <div class="loan-summary">
      <div class="wrapper-info">
        <div class="info">
          <div class="info-name potok-text-body-2">заемщик</div>
          <span
            v-if="props.project?.borrower"
            class="info-value potok-text-body-2"
            >{{ props.project?.borrower.shortName }}</span
          >
        </div>

        <PotokDivider class="divider" margin-y="17" />

        <div class="progress">
          <LoanProgress :project="props.project" :status="props.status" />
        </div>
      </div>
      <div v-if="isEligibleForInvestment" class="loan-button">
        <PotokButton
          v-if="
            !isSelectedCompanyHaveAlreadyInvested && isEligibleForInvestment
          "
          text="инвестировать"
          @click="investButtonHandler"
        />
      </div>
    </div>

    <div v-if="isEligibleForInvestment" class="loan-button-mobile">
      <PotokButton
        v-if="!isSelectedCompanyHaveAlreadyInvested"
        text="инвестировать"
        @click="investButtonHandler"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { PotokDivider, PotokButton } from 'potok-uikit';
import constants from '@/constants';
import { useStateStore } from '@/store/stateStore';

import LoanProgress from '../Loan/LoanProgress.vue';

const store = useStateStore();

const props = defineProps({
  project: {
    type: Object,
    default: () => {},
  },
  status: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['depositMoney']);

const investButtonHandler = () => {
  emit('depositMoney');
};

const selectedCompanyId = computed(() => {
  return store.selectedCompanyId;
});

const isEligibleForInvestment = computed(() => {
  let collected = props.project.collected;

  if (props.project.pifReservedAmount && props.project.pifReservedAmount > 0) {
    collected = collected - props.project.pifReservedAmount;
  }
  if (
    store.isUserLoaded &&
    constants.projectStatusSlug[props.project.statusId] === 'risingFunds' &&
    collected !== props.project.amount
  ) {
    return true;
  }
  return false;
});

const isSelectedCompanyHaveAlreadyInvested = computed(() => {
  if (!props.project) {
    return true;
  }
  return props.project?.investments?.find(
    (item) =>
      item.companyId === selectedCompanyId.value && item.isConfirmed === true,
  );
});
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.loan-summary {
  display: flex;
  width: 100%;
  background: $white;
  box-shadow: 0 1px 4px #e5e9f2;
  border-radius: 6px;
  margin: 30px 0;
  padding: 20px;

  @media (max-width: $size_767) {
    padding: 16px;
  }
}

.wrapper-info {
  width: 50%;

  @media (max-width: $size_767) {
    width: 100%;
  }
}

.info {
  display: flex;

  @media (max-width: $size_767) {
    flex-direction: column;
  }
}

.info-name {
  width: 50%;
  color: $grey;
  padding-right: 0;
  display: flex;
  align-items: center;

  @media (max-width: $size_767) {
    width: 100%;
    line-height: 20px;
    color: $grey-fields;
  }

  p {
    margin: 0;
  }

  .v-popper {
    margin-left: 5px;
  }
}

.info-value {
  width: 50%;
  color: $deep_dive;
  word-break: break-word;

  @media (max-width: $size_767) {
    width: 100%;
    line-height: 20px;
  }
}

.divider {
  display: none;

  @media (max-width: $size_767) {
    display: block;
  }
}

.progress {
  margin-top: 5px;
}

.loan-button {
  width: 50%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: $size_767) {
    display: none;
  }
}

.loan-button-mobile {
  margin-bottom: 20px;
  width: 100%;

  @media (min-width: $size_767) {
    display: none;
  }

  button {
    width: 100%;
  }
}
</style>
