<template>
  <div class="profit block_content">
    <div class="item-header">доходность</div>
    <div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-grey"><i class="icon-bag"></i></div>
        <div>
          <div class="item-name">
            {{ data?.data?.portfolioWoDefaultAvg?.description }}
          </div>
          <div class="item-value">
            <MoneyValue
              :value="data?.data?.portfolioWoDefaultAvg?.value"
              :rounding="false"
            />
          </div>
        </div>
      </div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-grey"><i class="icon-cart"></i></div>
        <div>
          <div class="item-name">
            {{ data?.data?.assetsWoDefaultAvg?.description }}
          </div>
          <div class="item-value">
            <MoneyValue
              :value="data?.data?.assetsWoDefaultAvg?.value"
              :rounding="false"
            />
          </div>
        </div>
      </div>
      <div class="item-row item-row-border">
        <div class="tag-icon tag-icon-grey"><i class="icon-bag"></i></div>
        <div>
          <div class="item-name">
            {{ data?.data?.profitability?.description }}
          </div>
          <div class="item-value">
            {{ data?.data?.profitability?.value }} % годовых
          </div>
        </div>
      </div>
      <div class="item-row">
        <div class="tag-icon tag-icon-grey">
          <PotokImg
            :src="imageConstants.analytics.health"
            height="18"
            width="18"
          />
        </div>
        <div>
          <div class="item-name">
            {{ data?.data?.indexWithNdfl?.description }}
          </div>
          <div class="item-value flex-value potok-text-body-1-bold">
            {{ data?.data?.indexWithNdfl?.value }} %
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { toRefs } from 'vue';
import imageConstants from '@/constants/imageConstants';
import PotokImg from '@/components/_generic/PotokImg.vue';
import MoneyValue from '@/components/_generic/MoneyValue.vue';

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});

const { data } = toRefs(props);
</script>
<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.profit {
  @media (max-width: $size_767) {
    min-height: 460px;
  }
}

.block_content {
  width: 100%;
  padding: 20px 20px 0;

  @media (max-width: $size_767) {
    padding: 20px 0 0;
  }
}
</style>
