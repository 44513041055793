<template>
  <ul class="block-container settings-menu">
    <li
      v-for="item in props.items"
      :key="item.routeName"
      class="settings-menu-item"
      @click="changeTitle(item)"
    >
      <router-link
        :to="{
          name: item.routeName,
        }"
        :class="{
          'settings-menu-item-active': $route.name === item.routeName,
        }"
        class="settings-menu-item-link potok-text-body-1"
      >
        <PotokImg
          :src="imageConstants.settings[item.icon]"
          height="20"
          width="20"
          class="mr-7"
        />
        {{ item.title }}
      </router-link>
    </li>
  </ul>
</template>

<script setup>
import imageConstants from '@/constants/imageConstants';

const emits = defineEmits('change');
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
});

const changeTitle = (item) => emits('change', item);
</script>

<style scoped lang="scss">
@use '../../scss/variables.scss' as *;

.settings-menu {
  padding: 13px 10px;
}

ul {
  margin: 0;
}

.settings-menu-item {
  height: 48px;
  width: 100%;
  margin: 3px 0;
  list-style: none;

  &:hover {
    background: $background;
    border-radius: 8px;
  }
}

.settings-menu-item-link {
  height: 100%;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $deep_dive;
}

.settings-menu-item-active {
  background: $background;
  border-radius: 8px;
}
</style>
