<template>
  <div class="container container_wrapper">
    <div class="login_title">
      <div>восстановление пароля</div>
    </div>
    <div class="login_subtitle">
      мы отправили код на номер <br />
      <span>{{ decorateFormatPhone(props.rawPhone) }}</span>
    </div>
    <div>
      <div class="login_actions">
        <div class="login_input_wrapper">
          <input
            id="smsCode"
            v-model="localRawCode"
            class="form-control"
            type="text"
            :readonly="props.loadingPostRecovery"
            placeholder="код из смс"
            autocomplete="off"
            maxlength="6"
            @input="onClickPressKeyEnterCode"
            @keyup.enter="onClickEnterCode()"
          />
          <div v-if="errorMessageSMSCode" class="potok-color-red">
            {{ errorMessageSMSCode }}
          </div>
        </div>
        <button
          :disabled="props.loadingPostRecovery"
          type="button"
          class="button button-breakwater button-password"
          @click="onClickEnterCode()"
        >
          продолжить
        </button>

        <template v-if="leftTime > 0">
          <div class="countdown potok-text-body-1 potok-color-deepdive">
            <div class="countdown_span">
              отправить ещё через
              <span>{{ leftTime }} c</span>
            </div>
          </div>
        </template>
        <template v-if="leftTime == 0">
          <button
            type="button"
            class="button button-text button-repeat"
            :disabled="loading"
            @click="onClickRequestRetryCode()"
          >
            выслать СМС повторно
          </button>
        </template>

        <button
          type="button"
          class="button button-text button-back"
          @click="onClickBack()"
        >
          назад
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, nextTick, onMounted } from 'vue';
import { focusDomElementById } from '@/utils/commonUtils/utils';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';

import {
  convertRawPhoneToLogin,
  decorateFormatPhone,
} from '@/utils/phone/formatPhone';
import countdownComposable from '@/composables/countdownComposable';

const store = useStateStore();

const props = defineProps([
  'rawPhone',
  'rawPassword',
  'errorMessageRecovery',
  'loadingPostRecovery',
]);
const emit = defineEmits(['changeStep', 'changeMode', 'changePassword']);

const { leftTime, countdownReset, countdown } = countdownComposable();

const localRawCode = ref(null);
const loading = ref(null);
const errorMessageSMSCode = ref(null);

const isValidSmsCode = () => {
  if (!localRawCode.value) {
    errorMessageSMSCode.value = 'обязательное поле. минимум 6 символов';
    return false;
  }
  if (localRawCode.value.length < 6) {
    errorMessageSMSCode.value = 'минимум 6 символов';
    return false;
  }
  return true;
};

const onClickPressKeyEnterCode = () => {
  errorMessageSMSCode.value = null;
};

const onClickEnterCode = () => {
  if (!isValidSmsCode()) {
    return;
  }

  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
    smsCode: localRawCode.value,
    newPassword: props.rawPassword,
  };
  loading.value = true;
  return server.postUsersRecoverPassConfirm
    .send(query)
    .pipe(onConfirmRecoveringAsyncSuccess.bind(this, query), () => {
      loading.value = false;
    })
    .exec();
};

const onConfirmRecoveringAsyncSuccess = (query, { data }) => {
  loading.value = false;
  if (data.isSuccessful) {
    countdown.stop();
    emit('changeMode', {
      component: 'inputPassword',
    });
    store.setNotification({
      timer: 5000,
      message: 'чтобы продолжить войдите в Поток с новым паролем',
      title: 'пароль изменен',
    });
  } else {
    errorMessageSMSCode.value = 'неверный код СМС';
  }
};

const onClickBack = () => {
  emit('changeStep', 'inputPassword');
};

const onClickRequestRetryCode = () => {
  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
  };
  loading.value = true;
  return server.postUsersRecoverPassRequest
    .send(query)
    .pipe(onRequestRecoveringAsyncSuccess.bind(this, query), () => {
      loading.value = false;
    })
    .exec();
};

const onRequestRecoveringAsyncSuccess = () => {
  loading.value = false;
  countdownReset();
  nextTick(() => focusDomElementById('smsCode'));
};

onMounted(() => {
  countdownReset();
});
</script>
<style lang="scss" scoped>
.button-password {
  margin-top: 23px;
}

.button-repeat {
  margin-top: 30px;
}

.button-back {
  margin-top: 19px;
}
</style>
