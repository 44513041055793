<template>
  <div v-if="isShow" class="ui-notification" :class="{ error: props.isError }">
    <div class="ui-notification-header">
      <div v-if="props.title" class="text-no-transform potok-text-h2 mb-2">
        {{ props.title }}
      </div>
      <PotokButton
        theme="linkIcon"
        size="link"
        class="ui-notification-close"
        @click="close"
      >
        <PotokImg
          :src="ImageConstants.closeGreyRounded"
          height="14"
          width="14"
        />
      </PotokButton>
    </div>

    <span class="text-no-transform potok-text-body-2" v-html="props.message" />

    <PotokButton
      v-if="props.btnText"
      theme="link"
      size="link"
      :text="props.btnText"
      class="ui-notification-btn mt-3"
      @click="props.btnCallback"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { PotokButton } from 'potok-uikit';
import { useStateStore } from '@/store/stateStore';
import ImageConstants from '@/constants/imageConstants';
import PotokImg from '@/components/_generic/PotokImg.vue';

const store = useStateStore();

const props = defineProps({
  timer: {
    type: [Number, String],
    default: 5000,
  },
  title: {
    type: String,
    required: false,
  },
  message: {
    type: String,
    required: true,
  },
  isError: {
    type: Boolean,
    default: false,
  },
  btnCallback: {
    type: [null, Function],
    default: () => {},
  },
  btnText: {
    type: String,
    default: '',
  },
});

const isShow = ref(false);

const close = () => {
  isShow.value = false;
  store.setNotification({
    timer: 0,
    message: '',
    title: '',
    isError: false,
    btnCallback: null,
    btnText: '',
  });
};

const closeHandler = () => {
  setTimeout(() => {
    close();
  }, props.timer);
};

watch(
  () => {
    return props.message;
  },
  () => {
    isShow.value = true;
    if (props.timer !== 'infinity') {
      closeHandler();
    }
  },
);
</script>

<style scoped lang="scss">
@use '../../../scss/variables.scss' as *;

.ui-notification {
  position: fixed;
  top: 50px;
  right: 50px;
  display: flex;
  flex-direction: column;
  max-width: 360px;
  min-width: 280px;
  border-radius: 8px;
  border-left: 3px solid $tiffany;
  padding: 20px 24px;
  background: #fff;
  z-index: 100;
  box-shadow: 0 16px 64px 0 rgb(183 203 228 / 50%);

  @media (max-width: $size_767) {
    top: initial;
    right: initial;
    bottom: 20px;
    left: 20px;
    max-width: 90%;
  }
}

.error {
  border-left: 3px solid $red;
}

.ui-notification-header {
  display: flex;
  justify-content: space-between;
}

.ui-notification .ui-notification-close {
  height: 24px;
  width: 24px;
}

.ui-notification .ui-notification-btn {
  width: fit-content;
  justify-content: flex-start;
  color: $tiffany;

  &:hover {
    color: $tiffany;
    opacity: 0.7;
  }
}
</style>
