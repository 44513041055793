import { PotokFeedback } from 'frontend-feedback-voc';
import 'frontend-feedback-voc/dist/index.css';


let potokFeedback;

potokFeedback = new PotokFeedback({
  elId: '#potokFeedback',
  platform: 'lki',
  server: import.meta.env.VITE_APP_FEEDBACK_URL,
});

export default potokFeedback;
