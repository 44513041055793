<template>
  <section class="projects">
    <div>
      <LoansTabsDesktop
        v-if="!isMobile"
        :selected-menu="currentTabMenu"
        :count="countProjects"
        @select-menu="changeTab"
      />
      <LoansTabsMobile
        v-if="isMobile"
        :selected-menu="currentTabMenu"
        :count="countProjects"
        :selected-sorting-options="selectedSortingOptions"
        :filters="filters"
        :hide-filters="true"
        @select-menu="changeTab"
        @change-filters="onChangeFilters"
        @change-selected-sorting-options="onChangeSortingOptions"
      />
    </div>

    <dashboard-invest-loans-banner
      v-if="isInvestingPotokLoansBanner"
      class="mt-7 mb-7"
    />

    <div style="width: 100%; margin: 0">
      <div v-if="loadingProjectsRisingFunds">
        <div class="icon_loading"></div>
      </div>
      <div
        v-if="!loadingProjectsRisingFunds && projects.length > 0"
        class="projects_wrapper"
      >
        <div style="width: 100%; display: flex; flex-wrap: wrap">
          <div v-if="sortAndFilteredItems.length" class="project-item-wrapper">
            <LoansHoldingBanner class="loans-holding-banner-wrapper" />
          </div>

          <div
            v-for="item in sortAndFilteredItems"
            :key="item.id"
            class="project-item-wrapper"
          >
            <LoansItem :item="item" />
          </div>
        </div>
      </div>
      <div
        v-if="!loadingProjectsRisingFunds && sortAndFilteredItems.length === 0"
        class="projects_empty"
      >
        <p>Продукт доступен для клиентов с активами более 50 тыс. руб.</p>
      </div>
    </div>
  </section>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue';

import LoansItem from './LoansItem.vue';

import LoansTabsDesktop from './LoansTabs/LoansTabsDesktop.vue';
import LoansTabsMobile from './LoansTabs/LoansTabsMobile.vue';
import detectDevice from '@/composables/detectDeviceComposable';
import DashboardInvestLoansBanner from '@/components/Dashboard/modals/DashboardInvestLoansBanner';

import LoansItemsSortComposable from '../../composables/loansItemsSortComposable';
import LoansFiltersComposable from '../../composables/loansFiltersComposable';
import { useStateStore } from '@/store/stateStore';
import server from '@/server';
import { saveProjects } from '@/utils/localStorage/viewedProjects';
import { decimal } from '@/utils/commonUtils/utils';
import { useRoute } from 'vue-router';
import LoansHoldingBanner from '@/components/Loans/banners/LoansHoldingBanner';

const { isMobile } = detectDevice();

const { selectedSortingOptions, onChangeSortingOptions, sortItems } =
  LoansItemsSortComposable();

const { filters, makeFilter, onChangeFilters } = LoansFiltersComposable();

const store = useStateStore();
const route = useRoute();

// Items Select
const MENU_ACTIVE = 'Active';
const MENU_INVESTMENT = 'Investment';
const MENU_COMPLETE = 'Complete';
const MENU_ALL = 'All';

const currentTabMenu = ref(MENU_ACTIVE);

const isInvestingPotokLoansBanner = computed(() => {
  return store.state.features?.general?.investingPotokLoansBanner?.enabled;
});

const allProjects = (projects) => {
  return projects;
};
const activeProjects = (projects) => {
  return projects.filter((item) => {
    return (
      item.myInvestmentAmount === 0 &&
      decimal(item.raisedAmount).lessThan(item.loanAmount)
    );
  });
};
const investmentProjects = (projects) => {
  return projects.filter((item) => {
    return (
      item.myInvestmentAmount > 0 &&
      decimal(item.raisedAmount).lessThan(item.loanAmount)
    );
  });
};
const completeProjects = (projects) => {
  return projects.filter((item) => {
    return decimal(item.raisedAmount).greaterThanOrEqualTo(item.loanAmount);
  });
};

const selectedProjects = (projects) => {
  if (currentTabMenu.value === MENU_ACTIVE) {
    return activeProjects(projects);
  }

  if (currentTabMenu.value === MENU_INVESTMENT) {
    return investmentProjects(projects);
  }

  if (currentTabMenu.value === MENU_COMPLETE) {
    return completeProjects(projects);
  }

  if (currentTabMenu.value === MENU_ALL) {
    return allProjects(projects);
  }
  return [];
};

const changeTab = (menuName) => {
  currentTabMenu.value = menuName;
};

// Items select end

const projects = ref([]);
const loadingProjectsRisingFunds = ref(true);

const selectedCompanyId = computed(() => {
  return store.selectedCompanyId;
});

const getProjectsRisingFundsAsync = () => {
  if (!selectedCompanyId.value || !selectedCompanyId.value) {
    return;
  }
  loadingProjectsRisingFunds.value = true;
  const query = {
    companyId: selectedCompanyId.value,
    isPotokLoansOnly: true,
  };
  return server.getProjectsRisingFunds
    .send(query, {})
    .pipe(onGetProjectsRisingFundsAsyncSuccess, () => {
      loadingProjectsRisingFunds.value = false;
    })
    .exec();
};
const onGetProjectsRisingFundsAsyncSuccess = (obj) => {
  if (!obj || !obj.data) {
    return;
  }
  projects.value = [];
  projects.value.push(...obj.data);
  loadingProjectsRisingFunds.value = false;
  saveProjects(projects);
};

watch(selectedCompanyId, async () => {
  getProjectsRisingFundsAsync();
  store.fetchActiveBalance({
    companyId: selectedCompanyId.value,
  });
});

onMounted(() => {
  getProjectsRisingFundsAsync();
  if (route.query.selectAll) {
    changeTab(MENU_ALL);
  }
});

const countProjects = computed(() => {
  return {
    activeProjects: makeFilter(activeProjects(projects.value), filters.value)
      .length,
    investmentProjects: makeFilter(
      investmentProjects(projects.value),
      filters.value,
    ).length,
    completeProjects: makeFilter(
      completeProjects(projects.value),
      filters.value,
    ).length,
    allProjects: makeFilter(allProjects(projects.value), filters.value).length,
  };
});

const sortAndFilteredItems = computed(() => {
  return sortItems(makeFilter(selectedProjects(projects.value), filters.value));
});
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.projects {
  margin-top: -20px;

  @media (max-width: $size_767) {
    margin-top: 0;
  }

  .projects_wrapper {
    margin: 0 -20px 0 0;

    @media (max-width: $size_991) {
      margin: 20px 0 0;
    }

    @media (max-width: $size_767) {
      margin: 20px -15px 0;
    }
  }

  .project-item-wrapper {
    padding: 0 20px 0 0;
  }

  @media (max-width: $size_991) {
    .project-item-wrapper {
      width: 100%;
      padding: 0;
    }
  }

  .project-details__topright {
    display: flex;
    align-items: center;
  }

  .filter-items {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;
    width: auto;
  }

  @media (max-width: $size_991) {
    .filter-items {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .filters_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    clear: both;
  }

  .projects_empty {
    font-size: 16px;
    display: flex;
    margin-top: 40px;
    text-align: center;
    justify-content: center;
  }
}

.loans-holding-banner-wrapper {
  @media (max-width: $size_767) {
    margin: 0 16px;
  }
}
</style>
