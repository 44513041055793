<template>
  <table class="potok-table">
    <thead>
      <PotokTableRow>
        <th
          v-for="column in props.columns"
          :key="column.label"
          :style="column.style"
        >
          <div class="potok-table-header-item potok-text-uppercase">
            {{ column.label }}

            <VDropdown
              v-if="column.dropdownMessage"
              :placement="'top'"
              :triggers="['hover']"
            >
              <PotokImg
                :src="imageConstants.attention"
                height="14"
                width="14"
                class="ml-1"
              />
              <template #popper>
                <div class="potok-text-body-2">
                  {{ column.dropdownMessage }}
                </div>
              </template>
            </VDropdown>
          </div>
        </th>
      </PotokTableRow>
    </thead>

    <tbody class="potok-table-body">
      <slot
        v-for="(item, index) in props.rows"
        :key="index"
        name="body"
        :row="item"
      />
    </tbody>
  </table>
</template>

<script setup>
import PotokImg from '@/components/_generic/PotokImg.vue';
import imageConstants from '@/constants/imageConstants';
import PotokTableRow from './PotokTableRow.vue';

const props = defineProps(['rows', 'columns']);
</script>

<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.potok-table {
  width: 100%;
  overflow: auto;
}

.potok-table-body {
  background-color: $white;
}

.potok-table-header-item {
  display: flex;
  justify-content: inherit;
  align-items: center;
}

th {
  padding: 13px 10px;
  background: $grey-third;
  color: $grey;

  &:first-child {
    border-radius: 14px 0 0;
    padding-left: 20px;
  }

  &:last-child {
    border-radius: 0 14px 0 0;
  }
}
</style>
