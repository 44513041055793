<template>
  <div class="simple-table-container mt-6">
    <template v-if="isLoading">
      <Loader class="mt-6" />
    </template>

    <template v-if="!isLoading">
      <table class="simple-table potok-text-body-2 mt-6">
        <thead>
          <tr>
            <th>инвестиция</th>
            <th>дата инвестиции</th>
            <th>Окончание срока займа</th>
            <th>вложено</th>
            <th>Остаток основного долга</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in props.items"
            :key="index"
            class="simple-table-row"
          >
            <!-- инвестиция -->
            <td>
              <div class="simple-table-investment">
                <div class="simple-table-investment-name">
                  <router-link
                    :to="`/loans/${item.projectId}`"
                    class="step-1-table-link"
                  >
                    {{ item.borrowerShortName }}
                  </router-link>
                </div>
                <div class="simple-table-investment-info mt-1">
                  инв.
                  <router-link
                    :to="`/investment/${item.id}`"
                    class="step-1-table-link"
                  >
                    {{ item.number }}
                  </router-link>
                  в заем
                  <router-link
                    :to="`/loans/${item.projectId}`"
                    class="step-1-table-link"
                  >
                    {{ item.projectNumber }}
                  </router-link>
                </div>
              </div>
            </td>
            <!-- дата инвестирования -->
            <td style="max-width: 70px">
              <div>
                {{ formatDate(item.createdAt, 'd.MM.yyyy') }}
              </div>
            </td>
            <!-- дата окончание срока займа -->
            <td style="max-width: 70px">
              <div>
                {{ formatDate(item.loanFundedAt, 'd.MM.yyyy') }}
              </div>
            </td>
            <!-- вложено -->
            <td>
              <span v-if="item.amount">
                {{ formatCurrency(item.amount, 0) }} ₽
              </span>
              <template v-else>
                <span>Нет данных</span>
              </template>
            </td>
            <!-- остаток основного долга -->
            <td>
              <span>{{ formatCurrency(item.mainDebtAmount, 0) }} ₽</span>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>

  <PaginationDesktop
    v-if="!isLoading && items.length"
    v-model:current-page="currentPage"
    v-model:selected-page-range="selectedPageRange"
    :total-items-count="itemsCount"
    :items-pluralize-array="['инвестиции','инвестиций','инвестиций']"
  />
</template>

<script setup>
import { ref, watch } from 'vue';
import { Loader } from 'potok-uikit';
import PaginationDesktop from '@/components/_generic/pagination/paginationDesktop.vue';
import { formatDate, formatCurrency } from '@/utils/commonUtils/libUtils';

const props = defineProps([
  'items',
  'isLoading',
  'itemsCount',
  'isSecondMarket',
]);
const emits = defineEmits(['fetchList']);

const selectedPageRange = ref(20);
const currentPage = ref(1);

const fetchList = () => {
  emits('fetchList', {
    selectedPageRange: selectedPageRange.value,
    currentPage: currentPage.value,
  });
};

watch(currentPage, fetchList);
watch(selectedPageRange, fetchList);
</script>

<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.simple-table {
  width: 100%;
  background-color: $white;
  border-radius: 14px;

  th {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $grey;
    padding: 8px 7px 7px 20px;
    background: rgb(232 236 240 / 30%);
    text-align: left;
    min-width: 100px;
    height: 40px;
  }

  td {
    color: $deep_dive;
    padding: 17px 17px 13px 20px;
  }

  tr {
    border-bottom: 1px solid $dividers;

    &:first-child {
      border-bottom: none;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.simple-table-investment-name {
  text-transform: none;
  color: $deep_dive;
}

.simple-table-investment-info {
  color: $grey;

  span {
    color: $deep_dive;
  }
}

.step-1-table-link {
  color: $deep_dive;
  text-decoration: none;

  &:hover {
    color: $tiffany;
  }
}
</style>
