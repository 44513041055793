<template>
  <div class="notification__icon">
    <button
      class="notification__icon_button"
      :disabled="!notification.list.length"
      title="сообщения"
      @click.stop="onClickToggle"
    ></button>
    <div v-if="notification.counter > 0" class="notification__counter">
      {{ notification.counter }}
    </div>
    <div
      v-if="props.isShowNotificationList"
      v-on-clickaway="onClickaway"
      class="notification_menu"
    >
      <div class="notifications_items">
        <div
          class="notifications_items_wrapper header_scroll_menu"
          @scroll="handleCheckNotifications()"
        >
          <router-link
            v-for="notificationItem in notification.list"
            :key="notificationItem.id"
            class="notifications_item"
            :to="{ name: 'notifications' }"
          >
            <div class="notification_text__date">
              {{ formatDate(notificationItem.createdAt, 'dd.MM.yyyy') }}
            </div>
            <div
              :class="{ viewed: !notificationItem.isViewed }"
              :data-id-notification="notificationItem.id"
              class="notification_text"
            >
              <div class="notification_text__subject">
                {{ getNotificationShortName(notificationItem.subject) }}
              </div>

              <div
                class="notification_text__description"
                @click.stop=""
                v-html="getNotificationShortName(notificationItem.message)"
              ></div>
            </div>
          </router-link>
        </div>
        <div class="popup__link" style="text-align: center; padding-top: 10px">
          <router-link :to="{ name: 'notifications' }" style="font-size: 14px"
            >Показать все уведомления</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue';
import { useStateStore } from '@/store/stateStore';
import { formatDate } from '@/utils/commonUtils/utils';
import server from '@/server';
import tracker from '@/tracker';
import { directive as vOnClickaway } from 'vue3-click-away';

const store = useStateStore();
const notificationViewed = ref([]);

const props = defineProps(['isShowNotificationList']);
const emit = defineEmits(['changeShowNotification']);

const notification = computed(() => {
  return store.state.menuNotification;
});

const putNotificationsViewedAsync = () => {
  Promise.all(
    notificationViewed.value.map((id) => {
      const pipeline = server.putNotification.send(
        {},
        {
          params: { notificationId: id },
        },
      );
      return pipeline.pipe().exec();
    }),
  );
};

const getNotificationShortName = (str) => {
  if (str.split(/\s+/).length > 20)
    return str.split(/\s+/, 20).join(' ') + '...';
  return str;
};
const handleCheckNotifications = () => {
  let arrNotif = document.querySelectorAll('.notification_text.viewed');
  for (let elem of arrNotif) {
    let elemTop = elem.getBoundingClientRect().top;
    let elemId = elem.getAttribute('data-id-notification');
    if (elemTop < 280) {
      if (!notificationViewed.value.includes(elemId)) {
        notificationViewed.value.push(elemId);
        setTimeout(() => {
          elem.classList.remove('viewed');
          putNotificationsViewedAsync();
        }, 500);
      }
    }
  }
};
const handleCheckNotificationsShow = () => {
  tracker.queue(tracker.commands.SEND, 'Investor_LK_Alert');
  return handleCheckNotifications();
};
const onClickToggle = () => {
  const value = !props.isShowNotificationList;
  emit('changeShowNotification', value);
  if (value) {
    nextTick(() => {
      handleCheckNotificationsShow();
    });
  }
};
const onClickaway = () => {
  emit('changeShowNotification', false);
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.notification__icon {
  position: relative;
}

.notification_menu {
  position: absolute;
  top: 48px;
  right: 0;
  z-index: 100;
  background: #fff;
  box-shadow: 0 1px 4px #e5e9f2;
  border-radius: 6px;
  padding: 10px 0;
}

.notifications_items_wrapper {
  min-width: 300px;
}

.notifications_item {
  padding: 0 20px;
  display: block;
  margin-bottom: 30px;

  &:hover {
    text-decoration: none;
  }

  h4 {
    margin-bottom: 3px;
  }

  .date {
    font-size: 12px;
    margin-bottom: 8px;
  }

  &.viewed {
    background: #f5f6fa;
    box-shadow: 0 4px 10px rgb(48 77 98 / 12%);
  }
}

.notification_text {
  padding: 0;
  margin: 0;
  color: #2b2b2b;

  &.viewed {
    background: #f5f6fa;
    box-shadow: 0 4px 10px rgb(48 77 98 / 12%);
  }

  &:hover {
    text-decoration: none;
  }

  p {
    margin: 0;
  }
}

.notification_text__date {
  display: block;
  color: #727272;
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;

  &:hover {
    text-decoration: none;
  }
}

.notification_text__subject {
  font-weight: 500;
  display: block;
  font-size: 14px;
}

.notification_text__description {
  max-width: 280px;
  overflow-x: hidden;
}

.notification__icon {
  width: 40px;
  height: 40px;
  display: flex;
  place-items: center center;
}

.notification__icon_button {
  display: block;
  width: 24px;
  height: 24px;
  background: url('@img/icons/header/notification.svg') no-repeat;
  background-size: cover;
  position: relative;
  border: none;
  padding: 0;
  margin-right: 0;

  &:hover {
    background: url('@img/icons/header/notification_hover.svg') no-repeat;
    background-size: cover;
  }
}

.notification__counter {
  position: absolute;
  top: 5px;
  right: 12px;
  color: #fff;
  font-size: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  background: $red;
  animation: breathe 2s infinite;
}
</style>
