<template>
  <div>
    <div class="row row_first">
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.search"
          class="form-control filter_item"
          placeholder="поиск по заемщику, id займа"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.purchaseDeals"
          v-maska="masks.purchaseDeals"
          class="form-control filter_item"
          placeholder="ставка, %"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <vue-datepicker
          v-model="form.loanFundedAt"
          class="form-control filter_item"
          :input-format="'dd.MM.yyyy'"
          :locale="ru"
          placeholder="дата инвестирования"
        ></vue-datepicker>
        <button type="button" class="clear" @click="form.loanFundedAt = null">
          <span>&times;</span>
        </button>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <vue-datepicker
          v-model="form.cessionStartAt"
          class="form-control filter_item"
          :input-format="'dd.MM.yyyy'"
          :locale="ru"
          placeholder="дата цессии"
        ></vue-datepicker>
        <button type="button" class="clear" @click="form.cessionStartAt = null">
          <span>&times;</span>
        </button>
      </div>
    </div>
    <div class="row row_second">
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.daysOverdue"
          v-maska="masks.digits"
          class="form-control filter_item"
          placeholder="просрочка"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentAmount"
          v-maska="masks.digits"
          class="form-control filter_item"
          placeholder="сумма инвестиции"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentDebt"
          v-maska="masks.digits"
          class="form-control filter_item"
          placeholder="остаток основного долга"
          @keyup="updateFilters"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VueDatepicker from 'vue3-datepicker';
import ru from 'date-fns/locale/ru';

import masks from '@/utils/masks';

export default {
  components: {
    VueDatepicker,
  },
  emits: ['updateFilters'],
  data() {
    return {
      ru,
      form: {
        search: null,
        purchaseDeals: null,
        loanFundedAt: null,
        loanDue: null,
        daysOverdue: null,
        myInvestmentAmount: null,
        myInvestmentDebt: null,
      },
    };
  },
  computed: {
    masks() {
      return {
        purchaseDeals: masks.digits(2),
        digits: masks.digits(15),
      };
    },
  },
  watch: {
    'form.loanFundedAt'() {
      this.updateFilters();
    },
    'form.cessionStartAt'() {
      this.updateFilters();
    },
  },
  methods: {
    updateFilters() {
      this.$emit('updateFilters', this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
@use '../../../../../scss/variables.scss' as *;

.row {
  @media (max-width: $size_767) {
    margin: 0;
  }
}

.row_second {
  margin-top: 20px;

  @media (max-width: $size_767) {
    margin-top: 0;
  }
}

.col-xs-12 {
  @media (max-width: $size_767) {
    padding: 0;
  }
}
</style>
