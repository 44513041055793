<template>
  <div class="switcher">
    <div class="items">
      <div
        v-for="(item, index) in props.items"
        :key="index"
        :class="{
          active: props.modelValue === item.value,
          'potok-text-body-2': props.small,
          small: props.small,
        }"
        class="item potok-text-body-1"
        @click="onClickItem(item.value)"
      >
        <i v-if="item.icon" :class="item.icon"></i>
        {{ item.title }}
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps(['items', 'modelValue', 'small']);
const emit = defineEmits(['update:modelValue']);

const onClickItem = (localValue) => {
  emit('update:modelValue', localValue);
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.items {
  display: flex;

  @media (max-width: $size_767) {
    justify-content: space-between;
  }

  .item {
    padding: 10px;
    height: 44px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: $grey;
    cursor: pointer;
    border: 1px solid #dedee1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      margin-right: 5px;
    }

    @media (max-width: $size_767) {
      width: 100%;
    }

    &:first-child {
      border-radius: 8px 0 0 8px;
      border-right: none;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
      border-left: none;
    }

    &:first-child.active {
      margin-right: -1px;
      position: relative;
    }

    &:last-child.active {
      margin-left: -1px;
    }

    &:hover {
      color: $breakwater;
    }
  }

  .small {
    padding: 1px 10px 2px;
    height: 26px;
  }

  .active {
    color: $deep_dive;
    border: 1px solid $deep_dive;
    border-right: 1px solid $deep_dive !important;
    border-left: 1px solid $deep_dive !important;
  }
}
</style>
