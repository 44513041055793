<template>
  <SettingsMenuSelect
    :selected-page="selectedPage"
    :items="items"
    class="mb-5"
    @change="changePage"
  />
  <div>
    <router-view />
  </div>
  <SettingsTelegramChannels
    v-if="route.name === 'SettingsNotification'"
    class="mt-5"
  />
  <SettingsInvestorTip
    v-if="route.name === 'SettingsInvestors'"
    class="mt-5"
  />
</template>
<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SettingsMenuSelect from '@/components/Settings/SettingsMenuSelect.vue';
import { PAGE_TITLES } from '@/constants/Settings/settingsConstants';
import SettingsTelegramChannels from '@/components/Settings/SettingsNotification/SettingsTelegramChanels.vue';
import SettingsInvestorTip from "@/components/Settings/SettingsInvestors/SettingsInvestorTip.vue";

const route = useRoute();
const router = useRouter();

const items = ref([
  {
    title: PAGE_TITLES.PERSONAL,
    routeName: 'SettingsPerson',
    icon: 'personal',
  },
  {
    title: PAGE_TITLES.INVESTORS,
    routeName: 'SettingsInvestors',
    icon: 'investors',
  },
  {
    title: PAGE_TITLES.NOTIFICATIONS,
    routeName: 'SettingsNotification',
    icon: 'notification',
  },
  {
    title: PAGE_TITLES.SECURITY,
    routeName: 'SettingsSecurity',
    icon: 'security',
  },
]);

const selectedPage = ref(items.value[0]);

const changePage = (value) => {
  selectedPage.value = value;
  router.push({
    name: value.routeName,
  });
};

const init = () => {
  selectedPage.value = items.value.find((el) => el.routeName === route.name);
};
init();
</script>

<style lang="scss" scoped>
.icon_user {
  width: 16px;
  height: 19px;
}

.settings_menu_active {
  background: #ebedf4;
  border-radius: 8px;
}

.settings_menu {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  list-style: none;

  a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #313132;
  }

  li {
    a {
      display: flex;
      align-items: center;
      padding: 19px 19px 15px 20px;

      i {
        margin-right: 9px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      background-color: #ebedf4;
    }
  }
}

:deep(.settings_row) {
  display: flex;
  align-items: center;
}
</style>
