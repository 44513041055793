<template><div></div></template>
<script>
export default {
  props: {
    currentStepStatus: {
      type: [String, Number],
      required: true,
    },
    steps: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  emits: ['updateStep'],
  computed: {
    currentStep() {
      return this.modifiedSteps.find((i) => i?.step === this.currentStepStatus);
    },
    currentStepId() {
      const currentStep = this.modifiedSteps.find(
        (i) => i?.step === this.currentStepStatus,
      );
      return currentStep.id;
    },
    modifiedSteps() {
      // Сохраняем обратную совместимость из-за InvestorAdd (если передастся старая структура шагов, то ничего изменяться не будет)
      if (this.steps[0]?.titleId) {
        return this.steps;
      }

      return this.steps.map((el, i) => {
        const id = i + 1;
        const nextStepId = id === this.steps.length ? null : id + 1;

        return {
          ...el,
          id,
          nextStepId,
        };
      });
    },
    visiblePassedSteps() {
      const passedSteps = [];
      if (!this.currentStep) {
        return [];
      }
      // Сохраняем обратную совместимость из-за InvestorAdd (если передастся старая структура шагов, то логика будет старая)
      if (this.steps[0].titleId) {
        this.currentStep.visiblePassedStepsIds.forEach((id) => {
          passedSteps.push(this.steps.find((i) => i.id === id));
        });

        return passedSteps;
      }

      this.modifiedSteps.forEach((step) => {
        if (step.id < this.currentStepId && step.visible) {
          passedSteps.push(step);
        }
      });
      return passedSteps;
    },
    visibleNextSteps() {
      const nextSteps = [];
      if (!this.currentStep) {
        return [];
      }
      // Сохраняем обратную совместимость из-за InvestorAdd (если передастся старая структура шагов, то логика будет старая)
      if (this.steps[0].titleId) {
        if (this.currentStep.visibleNextStepsIds) {
          this.currentStep.visibleNextStepsIds.forEach((id) => {
            nextSteps.push(this.steps.find((i) => i.id === id));
          });
        }

        return nextSteps;
      }

      this.modifiedSteps.forEach((step) => {
        if (step.id > this.currentStepId && step.visible) {
          nextSteps.push(step);
        }
      });
      return nextSteps;
    },
  },
  methods: {
    isCurrentStepHasPrevStep(stepId) {
      if (this.currentStep.prevStepIds) {
        return !!this.currentStep.prevStepIds.find((id) => id === stepId);
      }
    },
    onClickPrevStep(stepId) {
      if (this.isCurrentStepHasPrevStep(stepId)) {
        this.$emit('updateStep', {
          prevStepId: stepId,
        });
      }
    },
    calculateTitleIdForNextSteps(index) {
      return (
        this.visiblePassedSteps.length +
        (this.currentStepId === 2 ? 0 : 1) +
        (index + 1)
      );
    },
  },
};
</script>
<style></style>
