<template>
  <PotokModal @close="emits('close')">
    <template #content>
      <div class="potok-text-h1">
        {{ props.title }}
      </div>

      <div class="mt-5" v-html="props.content"></div>
    </template>
  </PotokModal>
</template>

<script setup>
import { PotokModal } from 'potok-uikit';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  content: {
    type: String,
    default: '',
  },
});

const emits = defineEmits(['close']);
</script>
