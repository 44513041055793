<template>
  <div class="field-container">
    <PotokDivider class="settings-field-divider" />
    <div
      class="field"
      :class="{ 'on-hover': !modeEditTelegram }"
      @click="onClickEditTelegram"
    >
      <div class="item-info">
        <div class="item-name potok-text-uppercase">
          telegram
          <VDropdown :placement="'right'" :triggers="['hover']">
            <PotokButton theme="linkIcon" size="link" class="ml-2">
              <template #icon>
                <PotokImg
                  width="12"
                  height="12"
                  :src="imageConstants.attention"
                />
              </template>
            </PotokButton>
            <template #popper>
              <div class="popup">
                <div class="popup-text">
                  мы используем мессенджер для оперативной связи по важным
                  вопросам
                </div>
              </div>
            </template>
          </VDropdown>
        </div>
        <div class="item-value mt-1">
          <template v-if="!modeEditTelegram">
            <span v-if="props.user.tgNickname">{{
              props.user.tgNickname
            }}</span>
            <span v-if="!props.user.tgNickname">нет telegram</span>
          </template>
          <template v-if="modeEditTelegram">
            <div class="item-edit mt-1">
              <input
                v-model="editedTelegram"
                class="field-input potok-text-body-1 mr-2"
                :class="{
                  'potok-color-red':
                    v$.editedTelegram.$dirty && v$.editedTelegram.$invalid,
                }"
                type="text"
                @keyup="v$.editedTelegram.$touch()"
              />
              <PotokButton
                text="сохранить"
                no-full-width-tablet
                :loading="loading"
                :disabled="v$.editedTelegram.$invalid"
                class="save-btn mr-2"
                @click.stop="onClickSaveTelegram"
              />
              <PotokButton
                :disabled="loading"
                no-full-width-tablet
                text="отмена"
                theme="tertiary"
                @click.stop="onClickCancelTelegram"
              />
            </div>
          </template>
        </div>
      </div>
      <template v-if="!modeEditTelegram">
        <PotokImg
          v-if="props.user.tgNickname && !isMobile"
          height="20"
          width="20"
          class="on-hover-only"
          :src="imageConstants.pencil"
        />
        <PotokButton
          v-if="!props.user.tgNickname && !isMobile"
          size="link"
          theme="linkIcon"
          text="добавить"
          class="on-hover-only tiffany"
          @click="onClickEditTelegram"
        >
          <template #icon-after>
            <PotokImg
              height="7"
              width="10"
              class="ml-1"
              :src="imageConstants.arrowRightTiffany"
            />
          </template>
        </PotokButton>
        <PotokImg
          v-if="isMobile"
          height="7"
          width="16"
          :src="imageConstants.arrowRightDeepdiveLong"
        />
      </template>
    </div>
    <div v-if="errorText" class="field-error potok-color-red">
      {{ errorText }}
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import detectDevice from '@/composables/detectDeviceComposable.js';
import useVuelidate from '@vuelidate/core';
import validators from '@/validators';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';
import PotokImg from '@/components/_generic/PotokImg.vue';
import imageConstants from '@/constants/imageConstants';

const { isMobile } = detectDevice();
const store = useStateStore();

const props = defineProps(['user']);

const loading = ref(false);
const modeEditTelegram = ref(false);
const editedTelegram = ref(null);

const validationsRules = {
  editedTelegram: {
    telegram: validators.telegram,
    minLength: validators.minLength(4),
    maxLength: validators.maxLength(32),
  },
};
const v$ = useVuelidate(validationsRules, {
  editedTelegram,
});

const errorText = computed(() => {
  if (v$.value.editedTelegram.telegram.$invalid) {
    return 'в нике не может быть русских букв или запрещенных символов';
  }
  if (v$.value.editedTelegram.minLength.$invalid) {
    return 'ник Telegram должен содержать минимум 4 символа';
  }
  if (v$.value.editedTelegram.maxLength.$invalid) {
    return 'ник Telegram должен содержать максимум 32 символа';
  }
  return '';
});
const onClickEditTelegram = () => {
  if (!editedTelegram.value) {
    editedTelegram.value = props.user.tgNickname;
  }
  modeEditTelegram.value = true;
};
const onClickCancelTelegram = () => {
  editedTelegram.value = null;
  modeEditTelegram.value = false;
};
const onClickSaveTelegram = () => {
  const query = {
    tgNickname: editedTelegram.value,
  };
  loading.value = true;
  return server.putUsersMeProfile
    .send(query)
    .pipe(
      onClickSaveTelegramAsyncSuccess.bind(this, query),
      onClickSaveTelegramAsyncError.bind(this),
    )
    .exec();
};
const onClickSaveTelegramAsyncSuccess = () => {
  store.setUserTelegram(editedTelegram.value);
  loading.value = false;
  modeEditTelegram.value = false;
};
const onClickSaveTelegramAsyncError = () => {
  loading.value = false;
  modeEditTelegram.value = false;
};

const init = () => {
  editedTelegram.value = props.user.tgNickname;
};
init();
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.field {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $size_767) {
    padding: 16px 0;
  }
}

.field-error {
  padding: 0 20px;
  margin-bottom: 10px;

  @media (max-width: $size_767) {
    padding: 0;
  }
}

.on-hover {
  &:hover {
    border-radius: 14px;
    background: $background;
    cursor: pointer;

    .on-hover-only {
      display: block;
    }
  }
}

.item-name {
  color: $grey;
}

.field-container .settings-field-divider {
  width: calc(100% - 40px);
  margin: 0 auto;

  @media (max-width: $size_767) {
    width: calc(100% - 32px);
  }
}

.on-hover-only {
  display: none;
}

.item-edit {
  display: flex;

  @media (max-width: $size_767) {
    flex-direction: column;
  }
}

.item-info {
  width: 100%;
}

.field-input {
  padding: 10px;
  border-radius: 8px;
  border: none;
  background: $background;

  @media (max-width: $size_767) {
    margin-bottom: 16px;
    margin-right: 0;
  }
}

.tiffany {
  color: $tiffany;
}

.save-btn {
  @media (max-width: $size_767) {
    margin-bottom: 16px;
    margin-right: 0;
  }
}
</style>
