<template>
  <div class="analytics-loading-container">
    <div v-if="props.loading" class="analytics-loading">
      <Loader />
      <p
        v-if="props.loadingText"
        class="analytics-loading-text potok-text-body-1-bold mt-4"
      >
        {{ props.loadingText }}
      </p>
    </div>

    <div v-if="!hasData" class="analytics-loading-container-no-data">
      нет данных
    </div>

    <slot />
    <div v-if="slots.popper || slots.beforepopper" class="popper-wrapper">
      <div class="mr-2"><slot name="beforepopper" /></div>
      <VDropdown
        v-if="slots.popper"
        :placement="'top'"
        :triggers="['click']"
        class="help-dropdown no_tablet"
      >
        <i class="no_tablet icon-attention" />
        <template #popper>
          <slot name="popper" />
        </template>
      </VDropdown>
    </div>

    <ModalFull
      v-if="isShowHelpModal"
      :title="props.helpMobileTitle"
      @close="closeHelpModal"
    >
      <template #content>
        <slot name="popper" />
      </template>

      <template #actions>
        <div class="button-center">
          <button
            type="button"
            class="analytics-help-modal-button button button-secondary button_ok"
            @click.stop="closeHelpModal"
          >
            {{ 'понятно' }}
          </button>
        </div>
      </template>
    </ModalFull>
    <i
      v-if="slots.popper"
      class="only_tablet icon-attention analytics-loading-icon-mobile"
      @click="openHelpModal"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import { useSlots } from 'vue';
import { Loader } from 'potok-uikit';

const props = defineProps({
  loading: Boolean,
  loadingText: {
    type: String,
    default: '',
  },
  hasData: {
    type: Boolean,
    default: true,
  },
  helpMobileTitle: {
    type: String,
    default: '',
  },
});

const slots = useSlots();

const isShowHelpModal = ref(false);

const openHelpModal = () => {
  isShowHelpModal.value = true;
};

const closeHelpModal = () => {
  isShowHelpModal.value = false;
};
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.analytics-loading-container {
  position: relative;
}

.analytics-loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: rgb(255 255 255 / 60%);
  position: absolute;
  backdrop-filter: blur(7px);
}

.analytics-loading-text {
  width: 250px;
  text-align: center;
}

.analytics-loading-container-no-data {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popper-wrapper {
  display: flex;
  justify-content: flex-end;
}

.analytics-loading-icon-mobile {
  position: absolute;
  top: -84px;
  right: 0;
}

.analytics-help-modal-button {
  height: 60px;
}
</style>
