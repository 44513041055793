<template>
  <div class="modal-overlay" @click="close">
    <div class="modal-alert">
      <div class="modal-container" @click="$event.stopPropagation()">
        <button type="button" class="modal-close" @click="close">
          <span>&times;</span>
        </button>
        <div class="modal-title">График по месяцам</div>
        <Bar
          v-if="graphData.labels.length"
          :data="graphData"
          :options="options"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';

import { Bar } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';
import { formatCurrency } from '@/utils/commonUtils/utils';

Chart.register(...registerables);
const months = {
  '01': 'Январь',
  '02': 'Февраль',
  '03': 'Март',
  '04': 'Апрель',
  '05': 'Май',
  '06': 'Июнь',
  '07': 'Июль',
  '08': 'Август',
  '09': 'Сентябрь',
  10: 'Октярбь',
  11: 'Ноябрь',
  12: 'Декабрь',
};
const props = defineProps(['turnoverByMonth']);
const emit = defineEmits(['close']);

const graphData = ref({
  labels: [],
  datasets: [
    {
      backgroundColor: '#58CFC9',
      borderRadius: 4,
    },
  ],
});
const options = ref({
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          return formatCurrency(value, 0) + ' ₽';
        },
      },
    },
    x: {
      ticks: {
        maxRotation: 0,
        minRotation: 0,
      },
    },
  },
});

const graphValues = computed(() => {
  return props.turnoverByMonth.map((i) => i.amount);
});
const graphLabels = computed(() => {
  return props.turnoverByMonth.map((i) => convertNumberToMonth(i.date));
});

onMounted(() => {
  document.querySelector('body').classList.add('modal-open');

  graphData.value.labels = graphLabels.value;
  graphData.value.datasets[0].data = graphValues.value;
});
onUnmounted(() => {
  document.querySelector('body').classList.remove('modal-open');
});
const close = () => {
  emit('close');
};
const convertNumberToMonth = (date) => {
  if (!date) {
    return;
  }
  const monthDate = date.slice(5, 7);
  const yearDate = date.slice(0, 4);
  if (months[monthDate]) {
    return [months[monthDate], yearDate];
  }
};
</script>
<style lang="scss" scoped>
.modal-alert {
  transition: transform 0.3s ease-out;
  margin: 1.75rem auto;
}

.modal-container {
  background: #fff;
  border-radius: 6px;
  max-width: none;
  width: 90%;
}

.modal-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #313132;
  margin-bottom: 20px;
}
</style>
