<template>
  <div class="loans-borrower-type-icon">
    <div class="type-icon-circle">
      <PotokImg
        :src="image"
        height="15"
        width="15"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import PotokImg from '../_generic/PotokImg.vue';

import imageConstants from '@/constants/imageConstants';

const props = defineProps(['type']);

const image = computed(() => {
  if (props.type === 'ENTREPRENEUR') return imageConstants.entrepreneur;
  return imageConstants.company;
})

</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.loans-borrower-type-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  background-color: $background;
}

.type-icon-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: $white;
}
</style>