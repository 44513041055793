<template>
  <div class="investments-menu">
    <ul class="investments-menu-list">
      <li class="investments-menu-list-item">
        <router-link
          :to="{
            name: 'newInvestmentsMyPortfolio',
          }"
          class="investments-menu-item-link potok-text-h1"
          :class="{
            'investments-menu-item-active':
              $route.name === 'newInvestmentsMyPortfolio',
          }"
        >
          {{ myPortfolioText }}
        </router-link>
      </li>
      <li class="investments-menu-list-item">
        <router-link
          v-if="true"
          :to="{
            name: 'newInvestmentsSellList',
          }"
          class="investments-menu-item-link potok-text-h1"
          active-class="investments-menu-item-active"
        >
          заявки на продажу
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import detectDevice from '@/composables/detectDeviceComposable';

const { isMobile } = detectDevice();

const myPortfolioText = computed(() => {
  if (isMobile.value) {
    return 'портфель';
  }
  return 'мой портфель';
});
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;
@use '../../../scss/typography.scss' as *;

.investments-menu-list {
  text-transform: none;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.investments-menu-list-item {
  margin-right: 20px;

  @media (max-width: $size_991) {
    margin-right: 8px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.investments-menu-item-link {
  text-decoration: none;
  color: $grey;

  &:hover {
    color: $deep_dive;
  }

  @media (max-width: $size_991) {
    @include potok-text-h2;
  }
}

.investments-menu-item-active {
  position: relative;
  color: $deep_dive;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -8px;
    left: 0;
    height: 2px;
    background-color: $deep_dive;
  }
}
</style>
