<template>
  <div
    v-on-clickaway="onClickaway"
    class="multiselect"
    @click.prevent="onClickCSelect"
  >
    <div
      class="form-control multiselect__select"
      :class="{
        multiselect__unactive: !isShowSelectList,
        multiselect__active: isShowSelectList,
      }"
    >
      <div
        :class="{
          multiselect__arrow_down: !isShowSelectList,
          multiselect__arrow_up: isShowSelectList,
        }"
      ></div>
      <div v-if="isShowPlaceholder" class="multiselect__select_placeholder">
        {{ props.placeholder }}
      </div>
      <div v-if="!isShowPlaceholder" class="multiselect__select_tags">
        <div v-if="getFirstSelectedTag()" class="multiselect__select_tag">
          {{ getFirstSelectedTag() }}
        </div>
        <div
          v-if="getAdditionalSelectedTagNumber()"
          class="multiselect__select_tag"
        >
          {{ getAdditionalSelectedTagNumber() }}
        </div>
      </div>
    </div>
    <div v-if="isShowSelectList" class="multiselect__list">
      <div
        v-for="(item, index) in props.items"
        :key="index"
        class="multiselect__list__item"
        @click.stop.prevent="onClickSelectedValue(item)"
      >
        <div v-if="item.title">{{ item.title }}</div>
        <i v-if="item.value" class="icon_menu_galka"></i>
      </div>
    </div>
  </div>
</template>
<script setup>
import { directive as vOnClickaway } from 'vue3-click-away';
import { ref, computed } from 'vue';

const props = defineProps(['items', 'modelValue', 'placeholder']);
const emit = defineEmits(['change']);
const isShowSelectList = ref(false);

const isShowPlaceholder = computed(() => {
  let result = true;
  props.items.forEach((i) => {
    if (i.value) {
      result = false;
    }
  });
  return result;
});
const onClickaway = () => {
  isShowSelectList.value = false;
};
const onClickCSelect = () => {
  isShowSelectList.value = !isShowSelectList.value;
};
const onClickSelectedValue = (item) => {
  item.value = !item.value;
  emit('change', props.items);
};
const getFirstSelectedTag = () => {
  const elements = props.items.filter((i) => i.value);
  if (elements && elements.length > 0) {
    return elements[0].title;
  }
  return null;
};
const getAdditionalSelectedTagNumber = () => {
  const elements = props.items.filter((i) => i.value);
  if (elements && elements.length > 1) {
    return `+${elements.length - 1}`;
  }
  return null;
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.multiselect {
  font-size: 14px;
  line-height: 24px;
  position: relative;
  width: 100%;

  .multiselect__select {
    display: flex;
    align-items: center;
    border: 1px solid #dedee1;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    height: 44px;
  }

  .multiselect__unactive {
    color: #999;
    background: transparent;
  }

  .multiselect__active {
    border: 1px solid #173b57;
  }

  .multiselect__select_placeholder {
    margin-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #999;
  }

  .multiselect__arrow_down {
    position: absolute;
    right: 15px;
    top: 40%;
    width: 11px;
    height: 8px;
    background: url('@img/angle-down.svg') no-repeat;
    background-size: contain;
    transform: rotate(180deg);
  }

  .multiselect__arrow_up {
    position: absolute;
    right: 15px;
    top: 40%;
    width: 11px;
    height: 8px;
    background: url('@img/angle-down.svg') no-repeat;
    background-size: contain;
  }

  .multiselect__list {
    position: absolute;
    top: 50px;
    left: 0;
    min-height: 30px;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 22px rgb(13 39 65 / 12%);
    border-radius: 12px;
    z-index: 99;
  }

  .multiselect__list__item {
    margin: 10px;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 44px;
    font-weight: 400;
    font-size: 16px;
    color: $deep_dive;

    &:hover {
      background-color: $background;
    }
  }

  .multiselect__select_tag {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    align-items: center;
    background: $background;
    border-radius: 8px;
    color: $deep_dive;
    padding: 4px 8px;
    margin-right: 6px;
  }
}
</style>
