<template>
  <PotokModal title="бонусная программа для инвесторов" @close="emits('close')">
    <template #content>
      <div class="bonus-modal-container">
        <Loader v-if="isLoading" />
        <div v-else class="bonus-modal-content">
          <div class="bonus-modal-description potok-text-body-1 mb-7">
            {{ bonusProgramText.conditions.descriptionPart1 }}
          </div>
          <div class="bonus-modal-current">
            <div class="bonus-modal-current-left">
              <div
                class="bonus-modal-current-left-subtitle potok-text-uppercase mb-1"
              >
                ваш уровень
              </div>
              <div
                :class="{
                  'color-basic': props.status === BONUS_TYPE.BASIC,
                  'color-silver': props.status === BONUS_TYPE.SILVER,
                  'color-gold': props.status === BONUS_TYPE.GOLD,
                  'color-platinum': props.status === BONUS_TYPE.PLATINUM,
                  'color-diamond': props.status === BONUS_TYPE.DIAMOND,
                }"
                class="bonus-modal-current-left-title potok-text-h1"
              >
                {{ currentLevelTransform }}
              </div>
            </div>
            <div class="bonus-modal-current-right potok-text-body-1-bold">
              {{ currentLevelPercent }}
            </div>
          </div>
          <div class="bonus-modal-list-container mt-7">
            <div class="bonus-modal-list-header potok-text-uppercase">
              <div class="bonus-modal-list-status">статус</div>
              <div class="bonus-modal-list-size">размер активов</div>
              <div class="bonus-modal-list-profit">доходность</div>
            </div>
            <div class="bonus-modal-list potok-text-body-1">
              <div
                v-for="item in bonusProgramList"
                :key="item.type"
                class="bonus-modal-list-item py-4"
              >
                <div class="bonus-modal-list-status">
                  <DashboardStatusesBonusBtn
                    :status="item.type"
                    :is-active="false"
                  />
                </div>
                <div class="bonus-modal-list-size">
                  {{ itemSizeText(item) }}
                </div>
                <div class="bonus-modal-list-profit">
                  {{
                    `+ ${item.annualPercent}% ${item.annualPercent ? 'годовых' : ''}`
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="bonus-modal-description-bottom potok-text-body-1 mt-5">
            {{ bonusProgramText.conditions.descriptionPart2 }}
          </div>
          <div class="bonus-modal-more-info potok-text-body-1">
            <div
              class="bonus-modal-more-info-btn mt-7"
              @click="isShowDetail = !isShowDetail"
            >
              подробные условия
              <i
                class="fa-angle-up fa"
                :class="{
                  'fa-angle-up': isShowDetail,
                  'fa-angle-down': !isShowDetail,
                }"
              />
            </div>
            <div
              v-if="isShowDetail"
              class="bonus-modal-more-info-content mt-5"
              v-html="bonusProgramText.extendedConditions.description"
            />
          </div>
        </div>
      </div>
    </template>
  </PotokModal>
</template>
<script setup>
import { PotokModal, Loader } from 'potok-uikit';
import server from '@/server';
import { computed, ref } from 'vue';
import DashboardStatusesBonusBtn from '@/components/Dashboard/DashboardTitle/DashboardStatuses/DashboardStatusesBonusBtn.vue';
import { BONUS_TYPE } from '@/constants/Dashboard/BonusProgramm/bonusProgrammConstants';

import detectDevice from '@/composables/detectDeviceComposable';
import { formatCurrency } from '@/utils/commonUtils/libUtils';

const emits = defineEmits(['close']);
const props = defineProps({
  status: {
    type: String,
    required: true,
  },
});

const { isMobile } = detectDevice();

const bonusProgramList = ref([]);
const bonusProgramText = ref({
  conditions: {
    descriptionPart1: '',
    descriptionPart2: '',
  },
  extendedConditions: {
    description: '',
  },
});
const isLoading = ref(true);
const isShowDetail = ref(false);

const currentLevelTransform = computed(() => {
  const text = props.status;
  const textArray = text.split('');
  textArray[0] = textArray[0].toUpperCase();
  return textArray.join('');
});

const currentLevelPercent = computed(() => {
  const currentItem = bonusProgramList.value.find(
    (el) => el.type === props.status,
  );
  const currentPercent = currentItem.annualPercent;

  return `+ ${currentPercent}% годовых`;
});

const itemSizeText = (item) => {
  if (!item.min) {
    return '0 ₽';
  }
  if (!isMobile.value) {
    return `от ${formatCurrency(item.min, 0)} ₽ и более`;
  }
  if (String(item.min).length === 6) {
    // Преобразуем число в строку и обрезаем последние 3 цифры для мобилки
    const number = String(item.min).split('').splice(0, 3).join('');
    return `${number} + тыс ₽`;
  }
  if (String(item.min).length === 7) {
    // Преобразуем число в строку и обрезаем последние 6 цифр для мобилки
    const number = String(item.min).split('').splice(0, 1).join('');
    return `${number}+ млн ₽`;
  }

  // в случае не привдиденной ситуации, по дефолту возвращаем декстопный формат
  return `от ${formatCurrency(item.min, 0)} ₽ и более`;
};

const getBonusProgramAsync = () => {
  isLoading.value = true;
  return server.getFeaturesStatusBonus
    .send()
    .pipe(onGetBonusProgramAsyncSuccess, () => {
      isLoading.value = false;
    })
    .exec();
};

const onGetBonusProgramAsyncSuccess = ({ data }) => {
  bonusProgramList.value = data.data;
  bonusProgramText.value = data.text;
  isLoading.value = false;
};

getBonusProgramAsync();
</script>
<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;
@use '../../../../scss/typography.scss' as *;

:deep(.modal_block_container) {
  max-width: 736px;
}

.bonus-modal-container {
  width: 100%;
  display: flex;
  place-items: center center;
}

.bonus-modal-content {
  width: 100%;
}

.bonus-modal-description {
  @media (max-width: $size_767) {
    @include potok-text-body-2;
  }
}

.bonus-modal-current {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 94px;
  background-color: $dividers;
  padding: 20px;
  border-radius: 8px;
}

.bonus-modal-list-status {
  min-width: 82px;
}

.bonus-modal-list-size {
  min-width: 180px;
  margin-left: 65px;

  @media (max-width: $size_767) {
    margin-left: 16px;
    min-width: 82px;
  }
}

.bonus-modal-list-profit {
  min-width: 100px;
  margin-left: 110px;

  @media (max-width: $size_767) {
    margin-left: 25px;
  }
}

.bonus-modal-current-left-title {
  color: $tiffany;

  @media (max-width: $size_767) {
    @include potok-text-h2;
  }
}

.bonus-modal-list-header {
  display: flex;
  color: $grey;
}

.bonus-modal-list-item {
  display: flex;
  border-bottom: 1px solid $dividers;

  &:last-child {
    border-bottom: 0;
  }

  @media (max-width: $size_767) {
    @include potok-text-body-2;
  }
}

.bonus-modal-description-bottom {
  color: $grey;

  @media (max-width: $size_767) {
    @include potok-text-body-2;
  }
}

.bonus-modal-more-info {
  color: $grey;

  @media (max-width: $size_767) {
    @include potok-text-body-2;
  }
}

:deep(.bonus-modal-more-info-content) {
  ol {
    padding-left: 20px;
    margin: 0;
  }

  ul {
    padding-left: 30px;
    list-style: disc;
  }
}

.bonus-modal-more-info-btn {
  color: $tiffany;
  cursor: pointer;
}

.color-basic {
  color: $grey;
}

.color-silver {
  color: $breakwater;
}

.color-gold {
  color: $yellow;
}

.color-platinum {
  color: $tiffany;
}

.color-diamond {
  color: $red;
}
</style>
