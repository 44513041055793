<template>
  <div class="dashboard_block_content" style="height: 100%">
    <div class="potok-text-h2" style="margin-top: 10px">займы на сборе</div>
    <div class="row_funds potok-text-body-1 first_block_content">
      <span style="color: #818e99">сумма займов</span>
      <span style="color: #10273d">
        {{ formatCurrency(props.projectStats.fundsRaisingAmount, 0) }}&nbsp;₽
      </span>
    </div>

    <PotokDivider margin-y="13" />

    <div class="row_funds potok-text-body-1">
      <span style="color: #818e99">осталось собрать</span>
      <span style="color: #10273d">
        {{
          formatCurrency(
            props.projectStats.fundsRaisingAmount -
              props.projectStats.fundsRaisedAmount,
            0,
          )
        }}&nbsp;₽
      </span>
    </div>
  </div>
</template>
<script setup>
import { formatCurrency } from '@/utils/commonUtils/utils';

const props = defineProps(['projectStats']);
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.first_block_content {
  margin-top: 72px;

  @media (max-width: $size_767) {
    margin-top: 36px;
  }
}

.dashboard_block_content {
  background-color: white;
}

.row_funds {
  display: flex;
  justify-content: space-between;

  @media (max-width: $size_767) {
    flex-direction: column;

    span {
      margin: 3px 0;
    }
  }
}
</style>
