export default {
  getCompanies: {
    url: `/api/companies`,
    method: 'GET',
  },
  getCompaniesAccountStatement: {
    url: `/api/companies/account-statement`,
    method: 'GET',
  },
  getCompaniesAccountStatementTotal: {
    url: `/api/companies/account-statement/total`,
    method: 'GET',
  },
  getCompaniesAccountStatementExcel: {
    url: `/api/companies/account-statement/excel`,
    method: 'POST',
  },
  getCompaniesAccountStatementOperationTypes: {
    url: `/api/companies/account-statement/operation-types`,
    method: 'GET',
  },
  getCompanies$IdBalance: {
    url: `/api/companies/:id/balance`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'max-age=86500',
      Pragma: 'max-age=86500',
    },
  },
  getCompaniesBankAccounts: {
    url: `/api/companies/bank-accounts`,
    method: 'GET',
  },
  postCompaniesConfirmEulaSendSms: {
    url: `/api/companies/confirm-eula/send-sms`,
    method: 'POST',
  },
  postCompaniesQualificationUpdateSendSms: {
    url: `/api/companies/qualification-update/send-sms`,
    method: 'POST',
    body: {
      id: null,
    },
  },
  postCompaniesConfirmEula: {
    url: `/api/companies/confirm-eula`,
    method: 'POST',
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data',
    },
    body: {
      id: null,
      smsCode: null,
    },
  },
  postCompaniesQualificationUpdate: {
    url: `/api/companies/qualification-update`,
    method: 'POST',
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data',
    },
    body: {
      id: null,
      smsCode: null,
    },
  },
  postCompaniesConfirmEulaForCompany: {
    url: `/api/companies/confirm-eula/company`,
    method: 'POST',
    body: {
      smsId: null,
      smsCode: null,
    },
  },
  postCompanies: {
    url: `/api/companies`,
    method: 'POST',
  },
  postCompaniesId: {
    url: `/api/companies/:id`,
    method: 'POST',
  },
  postCompaniesFl: {
    url: `/api/companies/fl`,
    method: 'POST',
  },
  getCompanyPromoIncome: {
    url: `/api/companies/income/personal-promo`,
    method: 'GET',
  },
  postCompaniesCashboxLimitUpdate: {
    url: `/api/companies/cashbox/update-limit`,
    method: 'POST',
    body: {
      id: null,
      amount: 0,
    },
  },
  getPlatformProfitability: {
    url: `/api/companies/profitability/all`,
    method: 'GET',
  },
  getActiveBalance: {
    url: `/api/companies/:id/active-balance`,
    method: 'GET',
  },
  getCompanyStatTotal: {
    url: `/api/companies/:id/investments/stats/total`,
    cache: 'no-cache',
    method: 'GET',
  },
  postCompaniesMoneyoutInvestor: {
    url: `/api/companies/moneyout/investor`,
    method: 'POST',
    body: {
      id: null,
      amount: 0,
    },
  },
  postCompaniesMoneyoutInvestorConfirm: {
    url: `/api/companies/moneyout/investor/confirm`,
    method: 'POST',
    body: {
      id: null,
      code: '',
    },
  },
  getMainDebt: {
    url: `/api/companies/:id/debt/main`,
    method: 'GET',
  },
  getProfitabilities: {
    url: `/api/companies/:id/profitability`,
    method: 'GET',
  },
  getProfitabilitiesV2: {
    url: `/api/companies/:id/profitability/new`,
    method: 'GET',
  },
  getCompanyInvestments: {
    url: `/api/companies/:id/investments`,
    method: 'GET',
  },
  getCompanyInvestmentsStats: {
    url: `/api/companies/:id/investments/stats`,
    method: 'GET',
  },
  getCompanyAutoInvestSettings: {
    url: `/api/companies/:id/auto-invest-settings`,
    method: 'GET',
  },
  postCompanyAutoInvestSettings: {
    url: `/api/companies/:id/auto-invest-settings`,
    method: 'POST',
  },
  putCompanyAutoInvestSettings: {
    url: `/api/companies/:id/auto-invest-settings/:settingsId`,
    method: 'PUT',
  },
  postCompanyAutoInvestSettingsReconnect: {
    url: `/api/companies/:id/auto-invest-settings/reconnect`,
    method: 'POST',
  },
  putCompanyAutoInvestSettingsReconnect: {
    url: `/api/companies/:id/auto-invest-settings/reconnect`,
    method: 'PUT',
  },
  postCompanySchedulePayment: {
    url: `/api/companies/:companyId/schedule-payment`,
    method: 'POST',
  },
};
