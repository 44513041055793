<template>
  <section style="margin-top: 30px">
    <DashboardBalancesDesktop
      v-if="!isMobile"
      :loading-balance="loadingBalance"
      :loading-result="loadingResult"
      @deposit-money="onClickDepositMoney"
      @show-cashbox-setting="onClickCashboxSetting"
    />
    <DashboardBalancesMobile
      v-if="isMobile"
      :loading-balance="loadingBalance"
      :loading-result="loadingResult"
      @deposit-money="onClickDepositMoney"
      @show-cashbox-setting="onClickCashboxSetting"
    />

    <ModalCashboxSetting
      v-if="isShowModalCashboxSetting"
      @close="isShowModalCashboxSetting = false"
    />
  </section>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import constants from '@/constants';
import tracker from '@/tracker';

import { useStateStore } from '@/store/stateStore';
import DashboardBalancesDesktop from './DashboardBalances/DashboardBalancesContainers/DashboardBalancesDesktop.vue';
import DashboardBalancesMobile from './DashboardBalances/DashboardBalancesContainers/DashboardBalancesMobile.vue';
import ModalCashboxSetting from '@/components/_generic/modals/ModalCashboxSetting.vue';

import detectDevice from '@/composables/detectDeviceComposable';

const store = useStateStore();
const router = useRouter();
const route = useRoute();

const loadingBalance = ref(false);
const loadingActiveBalance = ref(false);
const loadingResult = ref(false);
const isShowModalCashboxSetting = ref(false);

const { isMobile } = detectDevice();

const getData = () => {
  fetchBalancesAsync();
  fetchActiveBalanceAsync();
  fetchResultAsync();
};

const fetchBalancesAsync = () => {
  const companyId = store.selectedCompanyId;
  if (!store.state.balances || store.state.balances[companyId] === undefined) {
    loadingBalance.value = true;
    store.fetchBalance({ companyId });
    loadingBalance.value = false;
  }
};

const fetchActiveBalanceAsync = () => {
  loadingActiveBalance.value = true;
  store.fetchActiveBalance({
    companyId: store.selectedCompanyId,
  });
  loadingActiveBalance.value = false;
};
const fetchResultAsync = async () => {
  const companyId = store.selectedCompanyId;
  loadingResult.value = true;
  const isNewResultEnabled = route.query?.isNewResultEnabled;
  await store.fetchResult({ companyId, isNewResultEnabled });
  loadingResult.value = false;
};

const onClickDepositMoney = () => {
  tracker.queue(tracker.commands.SEND, 'deposit_money');
  tracker.queue(tracker.commands.SEND, 'purchase');

  store.merge({
    buffer: {
      depositOperationId: constants.depositOperations.refill,
      project: {
        id: null,
      },
    },
  });

  const route = {
    name: 'refill',
  };
  router.push(route);
};

const onClickCashboxSetting = () => {
  isShowModalCashboxSetting.value = true;
};

watch(
  () => {
    return store.selectedCompanyId;
  },
  () => {
    if (!store.isAuthenticated) {
      return;
    }
    getData();
  },
);

onMounted(() => {
  getData();
});
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.investor_balances {
  margin-top: 30px;
  display: flex;
  align-items: center;

  @media (max-width: $size_767) {
    margin-top: 25px;
  }
}

:deep(.dashboard_block_content) {
  position: relative;
  min-height: 246px;
}

.block_active_title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999;

  @media (max-width: $size_767) {
    font-size: 10px;
    line-height: 12px;
  }
}

.block_title_value {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #313132;

  @media (max-width: $size_767) {
    font-size: 20px;
    line-height: 18px;
    margin-bottom: 24px;
  }
}

.content-block {
  @media (max-width: $size_767) {
    margin: 0 !important;
  }
}

.block-title-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: $size_767) {
    padding: 0;
    min-width: 160px;
  }

  .account-history-link {
    margin-right: 10px;
  }
}

:deep(.content_main_value) {
  margin-top: 8px;

  @media (max-width: $size_767) {
    margin: 8px 0;
  }
}
</style>
