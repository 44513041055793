<template>
  <div class="project_progress">
    <div v-if="status === 'risingFundsFull'" class="progress-block">
      <div class="item_name">собрано полностью</div>
      <div class="item_value">
        <div class="sum">
          <span class="target">
            <span>{{ formatCurrency(props.project.collected, 0) }} </span>
            ₽
          </span>
        </div>

        <div class="project-progress project-progress-alt">
          <div
            :style="{
              width:
                calculateProjectProgress(
                  props.project.collected,
                  props.project.amount,
                ) + '%',
            }"
            class="bar bar-green"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="status === 'risingFunds'" class="progress-block">
      <div class="item_name">собрано</div>
      <div class="item_value">
        <div class="sum">
          <span class="source">
            <span>{{
              formatCurrency(decimal(props.project.collected || 0), 0)
            }}</span>
          </span>

          <span class="target" style="float: right">
            <span>{{ formatCurrency(props.project.amount, 0) }}</span>
            ₽
          </span>
        </div>

        <div class="project-progress project-progress-alt">
          <div
            :style="{
              width:
                calculateProjectProgress(
                  props.project.collected,
                  props.project.amount,
                ) + '%',
            }"
            class="bar"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="status === 'fundedFull'" class="progress-block">
      <div class="item_name">возвращено полностью</div>
      <div class="item_value">
        <div class="sum">
          <span class="target">
            <span>{{ formatCurrency(props.project.refunded, 0) }} </span>
            ₽
          </span>
        </div>

        <div class="project-progress project-progress-alt">
          <div
            :style="{
              width:
                calculateProjectProgress(
                  props.project.refunded,
                  props.project.amount,
                ) + '%',
            }"
            class="bar bar-green"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="status === 'funded'" class="progress-block">
      <div class="item_name">возвращено</div>
      <div class="item_value">
        <div class="sum">
          <span class="source">
            <span>{{
              formatCurrency(decimal(props.project.refunded || 0), 0)
            }}</span>
          </span>

          <span class="target" style="float: right">
            <span>{{ formatCurrency(props.project.amount, 0) }}</span>
            ₽
          </span>
        </div>

        <div class="project-progress project-progress-alt">
          <div
            :style="{
              width:
                calculateProjectProgress(
                  props.project.refunded,
                  props.project.amount,
                ) + '%',
            }"
            class="bar"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { decimal, formatCurrency } from '@/utils/commonUtils/utils';

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
});

const calculateProjectProgress = (source, target) => {
  let value = decimal(source || 0).mul(100);
  const loanAmount = decimal(target);
  if (loanAmount > 0) {
    value = value.div(loanAmount);
  }
  return value;
};
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.project_progress {
  margin-top: 15px;

  @media (max-width: $size_767) {
    margin-top: 0;
  }
}

.progress-block {
  display: flex;
  flex-direction: row;

  @media (max-width: $size_767) {
    flex-direction: column;
  }
}

.item_name {
  width: 50%;

  @media (max-width: $size_767) {
    width: 100%;
  }
}

.item_value {
  width: 100%;
  padding-right: 0;

  @media (max-width: $size_767) {
    width: 100%;
    padding-right: 15px;
  }
}

.project-progress {
  height: 6px;
}

.sum {
  margin-bottom: 10px;
}

.source {
  color: $deep_dive;
}

.target {
  color: $grey;
}
</style>
