<template>
  <div class="block-menu-mobile">
    <div class="menu_mobile">
      <div class="block_left">
        <div class="block-menu-mobile-item">
          <CSelect
            v-model="localActiveStatus"
            class="block-menu-select"
            :items="itemsMenu"
            style="margin-top: 0"
            @update:model-value="onChangeSelectStatus()"
          >
          </CSelect>
        </div>
      </div>
      <div class="block_right">
        <button
          type="button"
          class="button-transparent"
          @click="isShowModalFilters = true"
        >
          <i class="icon_filters"></i>
        </button>
      </div>
    </div>
    <InvestmentsMenuMobileFiltersModal
      v-if="isShowModalFilters"
      :title="'Фильтры'"
      :status="props.activeStatus"
      :filters="props.filters"
      :mobile-sort-field="props.mobileSortField"
      @close="onCloseModalFilters"
    />
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import InvestmentsMenuMobileFiltersModal from './InvestmentsMenuMobile/InvestmentsMenuMobileFiltersModal.vue';
import { CSelect } from 'potok-uikit';
import { useStateStore } from '@/store/stateStore';
import { STATUSES } from '../../../../constants/Investments/investmentsConstants.js';

const store = useStateStore();
const emit = defineEmits(['upateActiveStatus', 'updateMobileFilters']);
const props = defineProps([
  'projectsCount',
  'activeStatus',
  'filters',
  'mobileSortField',
]);

const localActiveStatus = ref();
const isShowModalFilters = ref(false);
const itemsMenu = ref([]);

const route = useRoute();
const router = useRouter();

const isPotokHoldingCompany = computed(() => {
  return store.isPotokHoldingCompany;
});
const isPotokFinanceBorrower = computed(() => {
  return store.isPotokFinanceBorrower;
});

const generateMenu = () => {
  itemsMenu.value = [];
  itemsMenu.value.push({
    value: STATUSES.funded,
    title: `текущие — ${props.projectsCount.funded} шт`,
  });
  if (props.projectsCount.restructured > 0) {
    itemsMenu.value.push({
      value: STATUSES.restructured,
      title: `реструктурированные — ${props.projectsCount.restructured} шт`,
    });
  }
  if (props.projectsCount.refunded > 0) {
    itemsMenu.value.push({
      value: STATUSES.refunded,
      title: `возвращенные — ${props.projectsCount.refunded} шт`,
    });
  }
  if (props.projectsCount.softOverdue > 0) {
    itemsMenu.value.push({
      value: STATUSES.softOverdue,
      title: `в просрочке — ${props.projectsCount.softOverdue} шт`,
    });
  }
  if (props.projectsCount.default > 0) {
    itemsMenu.value.push({
      value: STATUSES.default,
      title: `в дефолте — ${props.projectsCount.default} шт`,
    });
  }
  if (props.projectsCount.sold > 0) {
    itemsMenu.value.push({
      value: STATUSES.sold,
      title: `выкупленные — ${props.projectsCount.sold} шт`,
    });
  }
  if (isPotokHoldingCompany.value || isPotokFinanceBorrower.value) {
    itemsMenu.value.push({
      value: STATUSES.buyback_current,
      title: `buyback — текущие`,
    });
  }
};

watch(
  () => props.projectsCount,
  () => {
    generateMenu();
  },
  { deep: true },
);

onMounted(() => {
  generateMenu();
  if (
    route.query &&
    route.query.status &&
    STATUSES[route.query.status] != undefined
  ) {
    localActiveStatus.value = route.query.status;
  } else {
    localActiveStatus.value = STATUSES.funded;
  }
});

const onChangeSelectStatus = () => {
  emit('upateActiveStatus', localActiveStatus.value);
  router.push({
    name: 'investments',
    query: { status: localActiveStatus.value },
  });
};
const onCloseModalFilters = (obj) => {
  isShowModalFilters.value = false;
  emit('updateMobileFilters', obj);
};
</script>
<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.menu_mobile {
  display: flex;
  margin-bottom: 6px;
}

.block_left {
  flex: 2;
}

.block_right {
  margin-left: 16px;
}

.block-menu-mobile {
  width: 100%;
  display: none;

  @media (width <= 767px) {
    display: block;
    margin-top: 20px;
  }

  .block-menu-input {
    margin: 14px 0;
    border-radius: 6px;
    color: #999;
    background-color: $grey-primary;
    border: 1px solid $grey-border;
    padding: 6px 6px 6px 38px;
    height: 36px;
    font-size: 14px;
    line-height: 24px;
    background-image: url('@img/icons/loupe.svg') !important;
    background-position: left 10px center;
    background-repeat: no-repeat;
    box-shadow: none;
  }

  .block-menu-mobile-item {
    width: 100%;
  }
}
</style>
