<template>
  <div class="col-md-12 wrapper-step2 block_content">
    <button type="button" class="button-back" @click="onClickBack">
      <i class="icon_arrow_back_grey"></i>изменить сумму
    </button>
    <div class="qr-wrapper-amount">
      к оплате: {{ formatCurrency(props.amountValue, 0) }}&nbsp;₽
    </div>
    <div>
      <button
        class="button button-primary-white button-payment"
        type="button"
        @click="onClickMakePayment"
      >
        оплатить
      </button>
    </div>
    <div class="qr-wrapper mt-20 qr-code-panel">
      <img :src="'data:image/png;base64,' + props.qrCode" />
    </div>
    <div class="text-wrapper">
      <div class="text-wrapper-steps mt-20">
        <div class="text-wrapper-step">
          <div class="text-wrapper-step-number">1.</div>
          <div class="text-wrapper-step-text">
            наведите камеру телефона на QR-код
          </div>
        </div>
        <div class="text-wrapper-step">
          <div class="text-wrapper-step-number">2.</div>
          <div class="text-wrapper-step-text">
            выберите банковское приложение из списка
          </div>
        </div>
        <div class="text-wrapper-step">
          <div class="text-wrapper-step-number">3.</div>
          <div class="text-wrapper-step-text">
            подтвердите платеж в мобильном приложении банка
          </div>
        </div>
      </div>
    </div>
    <div>
      <button
        type="button"
        class="button-help"
        @click="isShowMessageHelp = true"
      >
        как отсканировать QR-код ?
      </button>
    </div>
    <div v-if="!store.isAlfaCustomer">
      <button
        type="button"
        class="button-download"
        @click="onClickDownloadQRCode"
      >
        <i class="icon-download" />
        скачать QR-код
      </button>
    </div>

    <ModalFull
      v-if="isShowMessageHelp"
      title="как отсканировать QR-код из галереи?"
      class="balance-step-sbp-modal"
      @close="isShowMessageHelp = false"
    >
      <template #content>
        <div
          v-html="!store.isAlfaCustomer ? messageHelp : messageHelpIsAlfa"
        ></div>
      </template>
    </ModalFull>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { formatCurrency } from '@/utils/commonUtils/utils';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import messageHelp from '@/components/Refill/messageHelp.js';
import messageHelpIsAlfa from '@/components/Refill/messageHelpIsAlfa.js';
import { useStateStore } from '@/store/stateStore';

const emit = defineEmits(['prevStep']);
const props = defineProps({
  amountValue: {
    type: Number,
  },
  qrCode: {
    type: String,
    required: true,
  },
  linkPayment: {
    type: String,
    required: true,
  },
});

const store = useStateStore();

const isShowMessageHelp = ref(false);

const onClickBack = () => {
  emit('prevStep');
};

const onClickDownloadQRCode = () => {
  const someb64data = 'data:image/png;base64,' + props.qrCode;
  const uri = someb64data;
  const link = document.createElement('a');
  link.href = uri;
  link.download = 'potok_qrcode_payment';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const onClickMakePayment = () => {
  window.open(props.linkPayment, '_blank');
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.button-back {
  background: $background;
  color: $deep_dive;
  border-radius: 22px;
  height: 30px;
  width: 147px;
  border: 0;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-top: 5px;

  i {
    margin-right: 5px;
  }

  &:hover {
    opacity: 0.5;
  }
}

.button-help {
  color: $tiffany;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  border: 0;
  background-color: transparent;
  margin-top: 6px;
}

.button-download {
  height: 44px;
  width: 177px;
  text-transform: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $deep_dive;
  background: #fff;
  border: 1px solid $input;
  margin-top: 20px;
}

.qr-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.text-wrapper {
  margin-top: 40px;
}

.text-wrapper-step {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $deep_dive;
  margin-bottom: 14px;
  display: flex;
}

.text-wrapper-step-number {
  margin-right: 5px;
}

.button-payment {
  margin-top: 20px;
}

.qr-wrapper-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: $deep_dive;
  margin-top: 24px;
}

.qr-code-panel {
  width: 100%;
  height: 100%;
  background: white;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
