<template>
  <PotokPage title="Аналитика">
    <template v-if="isUserLoaded && isUserRegistrated">
      <template v-if="selectedCompany">
        <div>
          <MenuDesktop v-if="!isMobile"></MenuDesktop>
          <MenuMobile v-if="isMobile"></MenuMobile>
        </div>
      </template>
      <template v-if="!selectedCompany">
        <Loader />
      </template>
    </template>
    <div>
      <router-view></router-view>
    </div>
  </PotokPage>
</template>
<script setup>
import { computed } from 'vue';

import { Loader, PotokPage } from 'potok-uikit';
import MenuDesktop from '../components/Analytics/MenuDesktop.vue';
import MenuMobile from '../components/Analytics/MenuMobile.vue';
import detectDevice from '@/composables/detectDeviceComposable';
import { useStateStore } from '@/store/stateStore';

const { isMobile } = detectDevice();
const store = useStateStore();

const isUserRegistrated = computed(() => {
  return store.isUserRegistrated;
});
const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});
</script>
<style lang="scss" scoped>
@use '../scss/variables.scss' as *;

.page_title {
  @media (max-width: $size_767) {
    margin-bottom: 20px;
  }
}

:deep(.block_subtitle) {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #313132;

  @media (max-width: $size_767) {
    font-size: 14px;
    line-height: 20px;
  }
}

:deep(.block_description) {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5c5c5c;
}
</style>
