<template>
  <div
    v-on-clickaway="onClickaway"
    :class="{
      selected_item: !props.design,
      selected_item_primary: props.design == 'primary',
      selected_item_active: !props.design && isShowSelectList,
    }"
    @click="onClickCSelect"
  >
    <div class="selected_item_title">
      <div class="selected_item_title_value">{{ selectedValueTitle }}</div>
      <div
        :class="{
          menuselect__arrow_down_grey: !isShowSelectList && !props.design,
          menuselect__arrow_up_grey: isShowSelectList && !props.design,
          menuselect__arrow_down_green:
            !isShowSelectList && props.design == 'primary',
          menuselect__arrow_up_green:
            isShowSelectList && props.design == 'primary',
        }"
      ></div>
    </div>
    <ul
      v-if="isShowSelectList"
      class="items"
      :class="{
        align_left: props.align_left,
        align_right: props.align_right,
      }"
    >
      <li
        v-for="(item, index) in props.items"
        :key="index"
        class="item"
        @click="onClickSelectedValue(item.value)"
      >
        <span>{{ item.title }}</span>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { directive as vOnClickaway } from 'vue3-click-away';

const props = defineProps([
  'items',
  'modelValue',
  'align_right',
  'align_left',
  'design',
]);
const emit = defineEmits(['update:modelValue']);

const isShowSelectList = ref(false);
const selectedValue = ref(null);
const selectedValues = computed({
  get() {
    return props.modelValue;
  },
  set(localValue) {
    emit('update:modelValue', localValue);
  },
});
const selectedValueTitle = computed(() => {
  const localValue = props.items.find((i) => i.value === selectedValues.value);
  if (!localValue) {
    return;
  }
  return localValue.title;
});
onMounted(() => {
  selectedValue.value = selectedValues.value;
});

const onClickaway = () => {
  isShowSelectList.value = false;
};
const onClickCSelect = () => {
  isShowSelectList.value = !isShowSelectList.value;
};
const onClickSelectedValue = (localValue) => {
  selectedValue.value = localValue;
  emit('update:modelValue', localValue);
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.selected_item_primary {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #04ccd9;
}

.selected_item {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: $deep_dive;
  cursor: pointer;
  position: relative;
  text-transform: initial;
  letter-spacing: initial;
  border: 1px solid $input;
  padding: 10px 14px;
  border-radius: 8px;

  @media (max-width: $size_767) {
    width: 100%;
  }
}

.items {
  list-style-type: none;
  position: absolute;
  padding: 0;
  top: 44px;
  left: 0;
  background: #fff;
  box-shadow: 0 2px 22px rgb(13 39 65 / 12%);
  border-radius: 12px;
  z-index: 99;
  white-space: nowrap;
  width: 100%;

  .item {
    padding: 10px;
    display: flex;
    align-items: center;
    color: $deep_dive;
    margin: 10px;
    border-radius: 8px;

    span {
      margin-right: 10px;
    }

    cursor: pointer;

    &:hover {
      background-color: $background;
    }

    i {
      margin-right: 5px;
    }
  }
}

.align_right {
  right: 0;
}

.selected_item_title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .selected_item_title_value {
    margin-right: 5px;
  }
}

.selected_item_active {
  border: 1px solid #173b57;
}

.menuselect__arrow_down_grey {
  width: 10px;
  height: 7px;
  background: url('@img/icons/arrow-down-grey.svg') no-repeat;
  background-size: contain;
}

.menuselect__arrow_up_grey {
  width: 10px;
  height: 7px;
  transform: rotate(180deg);
  background: url('@img/icons/arrow-down-grey.svg') no-repeat;
  background-size: contain;
}

.menuselect__arrow_down_green {
  width: 10px;
  height: 7px;
  background: url('@img/icons/arrow-down-green.svg') no-repeat;
  background-size: contain;
}

.menuselect__arrow_up_green {
  width: 10px;
  height: 7px;
  transform: rotate(180deg);
  background: url('@img/icons/arrow-down-green.svg') no-repeat;
  background-size: contain;
}
</style>
