<template>
  <div>
    <div
      v-if="isShowAlfaBanner"
      class="onboard-alfa-banner"
      @click="onClickAlfaModal(true)"
    >
      <div class="onboard-alfa-banner-text potok-text-body-2">
        Все о Потоке<br />
        простыми словами
      </div>

      <button class="onboard-alfa-banner-btn" @click.stop="closeAlfaBanner()" />
    </div>

    <DashboardOnboardAlfaModal
      v-if="isShowAlfaModal"
      @close="onClickAlfaModal(false)"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import DashboardOnboardAlfaModal from '@/components/Dashboard/DashboardOnboard/modals/DashboardOnboardAlfaModal.vue';

const isShowAlfaModal = ref(false);
const isShowAlfaBanner = ref(true);

const onClickAlfaModal = (value) => {
  isShowAlfaModal.value = value;
};

const closeAlfaBanner = () => {
  isShowAlfaBanner.value = false;
};
</script>

<style scoped lang="scss">
@use '../../../../scss/variables.scss' as *;
@use '../../../../scss/typography.scss' as *;

.onboard-alfa-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 18px 20px;
  background-color: $dividers;
  border-radius: 14px;
  background-image: url('@img/banners/alfa-onboard-banner.png');
  background-repeat: no-repeat;
}

.onboard-alfa-banner-text {
  text-transform: initial;
  margin-left: 40px;
  color: $breakwater;
}

.onboard-alfa-banner-btn {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  background-image: url('@img/icons/close-rounded.svg');
  background-repeat: no-repeat;
}
</style>
