<template>
  <ModalFull :title="'настройка копилки'" @close="close">
    <template #content>
      <div style="">
        <p
          style="
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #10273d;
          "
        >
          все поступления на лицевой счет складываются в копилку, пока средства
          в ней не достигнут целевого значения. Средства в копилке недоступны
          для автоматического инвестирования
        </p>
        <div
          class="potok-text-body-2"
          style="margin-bottom: 15px; color: #818e99"
        >
          целевое значение в копилке
        </div>
        <input
          id="rawSum"
          v-model="rawSum"
          v-maska="localMasks.currency"
          class="form-control"
          type="text"
        />
      </div>
    </template>
    <template #actions>
      <div
        style="
          border-top: 1px solid #e8ecf0;
          width: 100%;
          height: 1px;
          margin-bottom: 20px;
        "
      ></div>
      <div
        class="button-center"
        style="
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
        "
      >
        <button
          :disabled="isPending"
          type="button"
          class="modal-cashbox-button button button-secondary"
          @click="onClickChangeCashboxLimit"
        >
          изменить
        </button>
        <button
          :disabled="isPending"
          type="button"
          class="modal-cashbox-button button button-grey-outline"
          @click="onClickDisableCashboxLimit()"
        >
          отключить
        </button>
      </div>
    </template>
  </ModalFull>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import masks from '@/utils/masks';
import tracker from '@/tracker';
import server from '@/server/index';
import { formatNumber } from '@/utils/commonUtils/utils';
import { useStateStore } from '@/store/stateStore';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

const store = useStateStore();
const emit = defineEmits(['close']);
const rawSum = ref(0);
const loadingPostCompaniesCashboxLimitUpdate = ref(false);

const isPending = computed(() => {
  return loadingPostCompaniesCashboxLimitUpdate.value;
});
const localMasks = computed(() => {
  return {
    currency: masks.currency(),
  };
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});

onMounted(() => {
  rawSum.value = Number(selectedCompany.value.cashboxLimitAmount);
  document.body.style.overflow = 'hidden';
});
onUnmounted(() => {
  document.body.style.overflow = null;
});

const close = () => {
  emit('close');
};
const postCompaniesCashboxLimitUpdateAsync = () => {
  if (rawSum.value === '') {
    tracker.queue(
      tracker.commands.SEND,
      'Investor_LK_My_Account_Moneybox_Cancel_Threshold',
    );
  } else {
    if (Number(selectedCompany.value.cashboxLimitAmount) > 0) {
      tracker.queue(
        tracker.commands.SEND,
        'Investor_LK_My_Account_Moneybox_Change_Threshold',
      );
    } else {
      tracker.queue(
        tracker.commands.SEND,
        'Investor_LK_My_Account_Moneybox_Set_Threshold',
      );
    }
  }

  const query = {
    id: selectedCompany.value.id,
    amount: Number(formatNumber(rawSum.value)),
  };
  loadingPostCompaniesCashboxLimitUpdate.value = true;
  return server.postCompaniesCashboxLimitUpdate
    .send(query)
    .pipe(
      onPostCompaniesCashboxLimitUpdateAsyncSuccess.bind(this),
      onPostCompaniesCashboxLimitUpdateAsyncError.bind(this),
    )
    .exec();
};
const onPostCompaniesCashboxLimitUpdateAsyncError = () => {
  loadingPostCompaniesCashboxLimitUpdate.value = false;
};
const onPostCompaniesCashboxLimitUpdateAsyncSuccess = () => {
  loadingPostCompaniesCashboxLimitUpdate.value = false;
  store.fetchBalance({
    companyId: selectedCompany.value.id,
  });
  store.fetchActiveBalance({
    companyId: selectedCompany.value.id,
  });
  store.fetchCompanies();
  close();
};
const onClickDisableCashboxLimit = () => {
  rawSum.value = '';
  postCompaniesCashboxLimitUpdateAsync();
};
const onClickChangeCashboxLimit = () => {
  postCompaniesCashboxLimitUpdateAsync();
};
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.modal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #313132;
}

.modal-cashbox-button {
  width: 125px;

  @media (max-width: $size_767) {
    width: 48%;
  }
}

:deep(.modal_block_container) {
  max-width: 360px;

  @media (max-width: $size_767) {
    max-width: initial;
  }
}
</style>
