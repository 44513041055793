<template>
  <!-- eslint-disable -->
  <div class="text-center">
    <div class="tip">введите код, отправленный на номер</div>
    <div class="number number-alt">
      <span>{{ formattedPhone }}</span>
    </div>
    <form @submit.prevent="">
      <div class="form-column-narrow">
        <div class="">
          <input
            id="smsCode"
            v-model="props.authentication.smsCode"
            v-maska="localMasks.smsCode"
            class="form-control grey-color-placeholder"
            :class="{
              'input-letter-spacing':
                props.inputLetterSpacing && props.authentication.smsCode,
            }"
            type="tel"
            placeholder="код"
            autocomplete="off"
            :readonly="loading"
            @keyup.enter="onEnter"
            @input="changeInput"
          />
        </div>
        <p v-if="props.errorMessage" class="error_message">
          {{ props.errorMessage }}
        </p>
        <div class="two-factor-auth-buttons-container mt-5">
          <slot></slot>
        </div>
        <template v-if="props.request">
          <div
            v-if="leftTime > 0"
            class="potok-text-body-1 tip code-tip grey-color mt-5"
          >
            отправить новый код можно через
            <span>{{ leftTime }}</span>
            секунд<span>{{ pluralize(leftTime, ['у', 'ы', '']) }}</span>
          </div>
          <button
            v-if="leftTime === 0"
            type="button"
            class="potok-text-body-1 button button-text button-repeat grey-color mt-5"
            :disabled="loading"
            @click="requestAsync"
          >
            отправить еще раз
          </button>
        </template>
        <PotokButton
          text="отмена"
          size="link"
          theme="link"
          class="two-factor-auth-cancel"
          @click="close"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted } from 'vue';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import masks from '@/utils/masks';
import { formatLoginToPhoneNumber, pluralize } from '@/utils/commonUtils/utils';
import { PotokButton } from 'potok-uikit';

const props = defineProps({
  authentication: {
    type: Object,
    required: true,
  },
  request: {
    type: Function,
    required: false,
  },
  inputLetterSpacing: {
    type: Boolean,
    default: false,
  },
  phone: {
    type: String,
    required: true,
  },
  errorMessage: {
    type: String,
    required: false,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(['onEnter', 'change', 'close']);

const leftTime = ref(1);
const localMasks = computed(() => {
  return {
    smsCode: masks.digits(6),
  };
});
const steps = computed(() => {
  return TwoFactorAuthentication.steps;
});
const formattedPhone = computed(() => {
  return formatLoginToPhoneNumber(props.phone);
});
onMounted(() => {
  if (
    props.authentication &&
    props.authentication.countdown &&
    props.authentication.countdown.setOnTick
  ) {
    props.authentication.countdown.setOnTick((val) => {
      leftTime.value = val;
    });
  }
});

const requestAsync = () => {
  if (props.loading.value) {
    return;
  }
  props.authentication.setStep(steps.value.ACTIVE);
  if (props.request) {
    props.request();
  }
};

const onEnter = () => {
  emit('onEnter');
};

const changeInput = () => {
  emit('change');
};
const close = () => {
  emit('close');
};
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

#smsCode {
  background: #f2f4f8;
  height: 60px;
  border: 0;
}

.number-alt {
  margin-bottom: 30px;
}

.code-tip {
  color: #999;
}

.error_message {
  text-align: left;
  color: $error;
}

.form-row {
  @media (max-width: $size_767) {
    width: 100%;
  }

  input {
    @media (max-width: $size_767) {
      width: 100%;
    }
  }
}

.grey-color {
  color: $grey;
}

.grey-color-placeholder {
  &::placeholder {
    color: $grey;
  }
}

.two-factor-auth-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.two-factor-auth-cancel {
  margin: 20px auto 0;
}

.input-letter-spacing {
  letter-spacing: 4px;
}
</style>
