<template>
  <section class="notification_wrapper">
    <div class="notification-block content-block">
      <div>
        <div class="settings_row_title">
          <span class="potok-text-h2-light">СМС</span>
          <SwitcherSlider
            v-model="isSmsEnabled"
            :disabled="loading"
            @update:model-value="putUsersMeProfileAsync"
          />
        </div>
        <div class="settings_row">
          <div class="potok-text-body-2 settings_row_desc">
            Независимо от настройки в данном разделе вам будут приходить
            СМС-коды для подтверждения операций и СМС о выводе средств
          </div>
        </div>
      </div>

      <PotokDivider margin-y="30" mobile-margin-y="20" />

      <div>
        <div class="settings_row_title">
          <span class="potok-text-h2-light">Email</span>
          <SwitcherSlider
            v-model="isEmailEnabled"
            :disabled="loading"
            @update:model-value="putUsersMeProfileAsync"
          />
        </div>
        <div class="settings_row">
          <div class="potok-text-body-2 settings_row_desc">
            В наших почтовых рассылках мы рассказываем о новостях платформы,
            информируем об акциях и отчитываемся о результатах нашей работы
          </div>
        </div>
      </div>

      <PotokDivider margin-y="30" mobile-margin-y="20" />

      <div>
        <div class="settings_row_title">
          <span class="potok-text-h2-light">Telegram</span>
          <SwitcherSlider
            v-model="isTelegramEnabled"
            :disabled="loading"
            @update:model-value="putUsersMeProfileAsync"
          />
        </div>
        <div class="settings_row">
          <div class="potok-text-body-2 settings_row_desc">
            Через бот можно получать уведомления о зачислении/выводе средств, а
            также иную полезную информацию о ваших инвестициях,
            <PotokButton
              size="link"
              theme="link"
              text="как настроить?"
              class="settings-link potok-text-body-2"
              @click="isShowMessageHelp = true"
            />
          </div>
        </div>
      </div>
    </div>

    <SettingsNotificationModal
      :is-show="isShowMessageHelp"
      :auth-token="user?.appAuthToken"
      @close="isShowMessageHelp = false"
    />
  </section>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue';
import server from '@/server';
import SwitcherSlider from '@/components/_generic/cselect/SwitcherSlider.vue';
import { useStateStore } from '@/store/stateStore';
import SettingsNotificationModal from '@/components/Settings/SettingsNotification/SettingsNotificationModal';

const store = useStateStore();

const loading = ref(false);
const isSmsEnabled = ref(false);
const isEmailEnabled = ref(false);
const isTelegramEnabled = ref(false);
const isShowMessageHelp = ref(false);

const user = computed(() => {
  return store.state.user;
});

onMounted(() => {
  isSmsEnabled.value = user.value.isSmsEnabled;
  isEmailEnabled.value = user.value.isEmailEnabled;
  isTelegramEnabled.value = user.value.isTelegramEnabled;
});
const putUsersMeProfileAsync = () => {
  store.setUserIsSmsEnabled(isSmsEnabled.value);
  store.setUserIsEmailEnabled(isEmailEnabled.value);
  store.setUserIsTelegramEnabled(isTelegramEnabled.value);

  loading.value = true;
  const query = {
    isSmsEnabled: user.value.isSmsEnabled,
    isEmailEnabled: user.value.isEmailEnabled,
    isTelegramEnabled: user.value.isTelegramEnabled,
  };
  return server.putUsersMeProfile
    .send(query)
    .pipe(onPutUsersMeProfileAsyncSuccess.bind(this, query), () => {
      loading.value = false;
    })
    .exec();
};
const onPutUsersMeProfileAsyncSuccess = () => {
  loading.value = false;
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.notification-block {
  padding: 30px;

  @media (max-width: $size_767) {
    padding: 20px 14px;
  }
}

.block-title {
  @media (max-width: $size_767) {
    display: none;
  }
}

.settings_row_title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.settings_row_desc {
  color: $grey;
  margin-top: 10px;
  margin-right: 80px;

  @media (max-width: $size_767) {
    margin-right: 0;
  }
}

.settings-link {
  display: inline;
  color: $tiffany;
}
</style>
