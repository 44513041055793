<template>
  <div class="footer-content">
    <div class="footer-contacts-container">
      <span class="footer-title">техническая поддержка</span>
      <div class="footer-contacts">
        <a href="https://t.me/OkPotokDigitalBot" target="_blank"
          >поддержка в telegram</a
        >
        <a href="mailto:ok@potok.team">ok@potok.team</a>
      </div>
    </div>
    <div v-if="supportTelephone" class="footer-phone-container">
      <div class="footer-right-column">
        <span class="footer-phone-title">консультация по инвестированию</span>
        <a :href="`tel:${supportTelephone}`" class="footer-phone">
          {{ modifiedSupportTelephone }}
        </a>
      </div>
    </div>
    <div class="footer-links-container">
      <div class="footer-right-column">
        <a href="https://t.me/potok_digital" target="_blank">
          канал новостей
        </a>
        <a
          href="https://t.me/potok_new_projects"
          target="_blank"
          class="footer-right-item-margin"
        >
          канал займов
        </a>
      </div>
      <div class="footer-right-column">
        <router-link :to="{ name: 'documents' }" target="_blank">
          документы
        </router-link>
        <a
          href="https://www.potok.digital/"
          target="_blank"
          class="footer-right-item-margin"
        >
          сайт Потока
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import server from '@/server';
import { formatPhone } from '@/utils/commonUtils/libUtils';

const supportTelephone = ref(null);

const modifiedSupportTelephone = computed(() =>
  formatPhone(supportTelephone.value),
);

const getSupportTelephone = () =>
  server.getSupport
    .send(null)
    .pipe(onGetSupportTelephoneSuccess, () => null)
    .exec();

const onGetSupportTelephoneSuccess = (obj) => {
  supportTelephone.value = obj.data.supportPhone;
};

getSupportTelephone();
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.footer-content {
  margin-top: 40px;
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: $size_991) {
    height: 100%;
    flex-direction: column;
    justify-content: normal;
  }
}

.footer-contacts-container {
  display: flex;
  flex-direction: column;
}

.footer-links-container {
  display: flex;

  @media (max-width: $size_991) {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $input;
    padding-bottom: 50px;
  }
}

.footer-right-column {
  display: flex;
  flex-direction: column;
  margin-right: 70px;
  text-align: right;

  @media (max-width: $size_991) {
    text-align: left;

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-title {
  font-size: 16px;
  font-weight: 500;
}

.footer-right-item-margin {
  margin-top: 10px;
}

.footer-contacts {
  margin-top: 13px;

  a {
    margin-right: 30px;
  }
}

.footer-phone-container {
  @media (max-width: $size_991) {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $input;
  }
}

.footer-phone-title {
  @media (max-width: $size_991) {
    font-weight: 500;
    font-size: 16px;
  }
}

.footer-phone {
  margin-top: 10px;
}

a {
  color: $grey;

  &:hover {
    text-decoration: none;
    color: $tiffany;
  }
}
</style>
