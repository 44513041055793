<template>
  <div class="assets-returns">
    <div class="assets-returns-head mb-5">
      <h2 class="potok-text-body-1-bold my-0">диверсификация</h2>
    </div>

    <AnalyticsLoadingContainer
      :loading="props.loading"
      loading-text="пожалуйста, подождите, загружаем данные"
      help-mobile-title="диверсификация"
    >
      <div class="chart-wrapper">
        <div class="chart-desc">
          на шкале указан уровень диверсификации портфеля:<br />
          10% и более — слабодиверсифицированный портфель<br />
          2% — хорошая диверсификация портфеля
        </div>
        <div class="chart-item">
          <div class="chart-item-row">
            <div class="chart-item-col">
              <div class="chart-item-value">
                {{ props?.data?.count || 0 }}
              </div>
              <div class="chart-item-name">Компаний в портфеле</div>
            </div>
            <div class="chart-item-col">
              <div class="chart-item-value">{{ props?.data?.rate || 0 }}%</div>
              <div class="chart-item-name">Уровень диверсификации</div>
            </div>
          </div>
          <div>
            <div class="chart-outside">
              <div style="display: flex; width: 100%; height: 100%">
                <div
                  style="
                    border-radius: 8px 0 0 8px;
                    flex: 1;
                    background-color: #ee3d48;
                  "
                >
                  <div class="chart-point-value-start">10%</div>
                </div>
                <div style="flex: 1; background-color: #f0505a" />
                <div style="flex: 1; background-color: #f1646d" />
                <div style="flex: 1; background-color: #f3777f" />
                <div style="flex: 1; background-color: #ffc11e" />
                <div style="flex: 1; background-color: #ffcd4b" />
                <div style="flex: 1; background-color: #ffda78" />
                <div style="flex: 1; background-color: #ffe6a5" />
                <div style="flex: 1; background-color: #9bebf0" />
                <div style="flex: 1; background-color: #68e0e8" />
                <div style="flex: 1; background-color: #36d6e1" />
                <div
                  style="
                    border-radius: 0 8px 8px 0;
                    flex: 1;
                    background-color: #04ccd9;
                  "
                >
                  <div class="chart-point-value-end">0%</div>
                </div>
              </div>
              <div
                class="chart-point"
                :style="{
                  left: `${chartWidth}%`,
                }"
              >
                <div v-if="isShowChartPoint" class="chart-point-value">
                  {{ props?.data?.rate }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnalyticsLoadingContainer>
  </div>
</template>

<script setup>
import { computed } from 'vue';

import AnalyticsLoadingContainer from './AnalyticsLoadingContainer.vue';

const props = defineProps({
  loading: Boolean,
  data: {
    type: Object,
  },
});

const chartWidth = computed(() => {
  if (!props?.data?.rate) {
    return 99;
  }
  if (props.data.rate >= 10) {
    return 0;
  }
  return (100 * (10 - props.data.rate)) / 10 - 0.7;
});

const isShowChartPoint = computed(() => {
  if (!props?.data?.rate) {
    return false;
  }
  if (props.data.rate >= 9.5) {
    return false;
  }
  return true;
});
</script>

<style scoped lang="scss">
@use '../../../scss/variables.scss' as *;

.chart-desc {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: $grey;
}

.chart-item {
  margin-top: 100px;

  @media (max-width: $size_767) {
    margin-top: 20px;
  }
}

.chart-item-row {
  display: flex;
  flex-wrap: wrap;
}

.chart-item-col {
  display: flex;
  flex-flow: column wrap;
  min-width: 180px;
  margin-top: 20px;
}

.chart-item-value {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.3px;
  color: $deep_dive;
}

.chart-item-name {
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: $grey;
}

.chart-outside {
  margin-top: 44px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 40px;
}

.chart-point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #173b57;
  position: absolute;
  bottom: -5px;
}

.chart-point-value {
  margin-top: 10px;
}

.chart-point-value-start {
  margin-top: 50%;
  transform: translateY(-50%);
  color: $white;
  text-align: center;
}

.chart-point-value-end {
  margin-top: 50%;
  transform: translateY(-50%);
  color: $white;
  text-align: center;
}
</style>
