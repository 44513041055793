<template>
  <table class="tableitems__table">
    <thead>
      <tr>
        <th
          v-for="(column, index) in props.columns"
          :key="index"
          :style="column.th_desktop_style"
          :class="{
            cursor_pointer: column.sort,
          }"
          @click="onClickSort(column)"
        >
          <div class="header-item">
            {{ column.title }}
            <div
              v-if="column.sort && sortColumn !== column.sort_slug"
              class="header-item-sort"
            >
              <div class="icon_arrow_up_sort"></div>
              <div class="icon_arrow_down_sort"></div>
            </div>

            <div
              v-if="
                column.sort &&
                sortColumn === column.sort_slug &&
                sortColumnDirection === 'up'
              "
              class="header-item-sort"
            >
              <div class="icon_arrow_up_sort"></div>
            </div>

            <div
              v-if="
                column.sort &&
                sortColumn === column.sort_slug &&
                sortColumnDirection === 'down'
              "
              class="header-item-sort"
            >
              <div class="icon_arrow_down_sort"></div>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="isShowTotalColumns" class="tableitems_rows_item">
        <td
          v-for="(column, column_index) in props.columns"
          :key="column_index"
          class="table_items_rows_item_total"
          style="text-align: left"
        >
          <div
            v-if="totalColumns[column.slug] != undefined"
            v-html="totalColumns[column.slug]"
          ></div>
        </td>
      </tr>
      <tr
        v-for="(item, index) in props.items"
        :key="index"
        class="tableitems_rows_item"
      >
        <td
          v-for="(column, column_index) in props.columns"
          :key="column_index"
          :style="column.td_desktop_style"
        >
          <div v-html="column.content_desktop(item)"></div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps([
  'items',
  'columns',
  'sortColumn',
  'sortColumnDirection',
  'totalColumns',
]);
const emit = defineEmits(['selectSortColumn']);

const isShowTotalColumns = computed(() => {
  if (props.totalColumns) {
    return Object.keys(props.totalColumns).length > 0;
  }
  return false;
});
const onClickSort = (column) => {
  if (!column.sort) {
    return;
  }
  emit('selectSortColumn', column);
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.tableitems__table {
  width: 100%;
  overflow: auto;

  .cursor_pointer {
    cursor: pointer;
  }

  th {
    padding: 13px 10px;
    background: rgb(232 236 240 / 30%);
    color: $grey;
    font-weight: 500;
    font-size: 8px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    &:first-child {
      border-radius: 14px 0 0;
      padding-left: 20px;
    }

    &:last-child {
      border-radius: 0 14px 0 0;
    }

    .header-item {
      display: flex;
      justify-content: inherit;
    }

    .header-item-sort {
      margin-left: 5px;
      position: relative;
      bottom: 2px;

      .icon_arrow_up_sort {
        position: absolute;
        top: 3px;
        left: 0;
      }

      .icon_arrow_down_sort {
        position: absolute;
        top: 8px;
        left: 0;
      }
    }
  }

  td {
    background: white;
    color: black;
    padding: 10px 20px 10px 10px;
    border-top: 1px solid #ebedf4;
    white-space: nowrap;

    &:first-child {
      padding-left: 20px;
    }
  }
}

.table_items_rows_item_total {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $deep_dive;
  background: #e6fafb !important;
}
</style>
