<template>
  <div>
    <Loader v-if="isLoading" />

    <template v-else>
      <NewInvestmentsNewRequestStep1Title
        :sum="totalInvestmentsSum"
        :count="investmentsFullList.length"
        :how-it-works-text="howItWorksText"
        @change-step="changeStep"
        @show-tour="showTour"
      />

      <PotokMessage v-if="isTypeCompanyOrEntrepreneur">
        <div class="investments-new-request-step-1-message">
          для ИП и ООО на упрощенной системе налогообложения сумма, полученная
          от продажи инвестиций, подлежит включению в состав доходов в полном
          объеме согласно пункту 1 статьи 346.15 и пункту 1 статьи 249 НК РФ*

          <br />
          <span class="investments-new-request-step-1-message-grey-text">
            *данная информация не является налоговой консультацией, рекомендуем
            обращаться к помощи налогового консультанта
          </span>
        </div>
      </PotokMessage>

      <NewInvestmentsNewRequestStep1TableDesktop
        v-if="!isTablet"
        :items="investmentsListDesktop"
        :is-loading="isLoading"
        :items-count="investmentsListCount"
        @fetch-list="changeInvestmentsListPageDesktop"
      />
      <NewInvestmentsNewRequestStep1TableMobile
        v-if="isTablet"
        :items="investmentsListMobile"
        :is-loading="isLoading"
        :items-count="investmentsListCount"
        @load-more-list="moreInvestmentsListMobile"
      />
    </template>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import NewInvestmentsNewRequestStep1Title from '@/components/Investments/NewInvestmentsNewRequest/NewInvestmentsNewRequestStep1/NewInvestmentsNewRequestStep1Title.vue';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';
import { Loader } from 'potok-uikit';
import PotokMessage from '@/components/_generic/PotokMessage.vue';
import NewInvestmentsNewRequestStep1TableDesktop from '@/components/Investments/NewInvestmentsNewRequest/NewInvestmentsNewRequestStep1/NewInvestmentsNewRequestStep1TableDesktop.vue';
import NewInvestmentsNewRequestStep1TableMobile from '@/components/Investments/NewInvestmentsNewRequest/NewInvestmentsNewRequestStep1/NewInvestmentsNewRequestStep1TableMobile.vue';
import detectDevice from '@/composables/detectDeviceComposable';
import constants from '@/constants';

const store = useStateStore();
const { isTablet } = detectDevice();
const emits = defineEmits(['change-step', 'show-tour']);

const isLoading = ref(false);

const investmentsFullList = ref([]);
const investmentsListDesktop = ref([]);
const investmentsListMobile = ref([]);

const totalInvestmentsSum = ref(0);
const investmentsListCount = ref(0);

const howItWorksText = ref(null);

const isTypeCompanyOrEntrepreneur = computed(() => {
  return (
    store.selectedCompany.typeId === constants.COMPANY_TYPES_ENTREPRENEUR ||
    store.selectedCompany.typeId === constants.COMPANY_TYPES_COMPANY
  );
});

const selectedCompany = computed(() => {
  return store.selectedCompany;
});

const changeStep = (stepId) => {
  emits('change-step', stepId);
};
const showTour = () => {
  emits('show-tour');
};

const changeInvestmentsListPageDesktop = (obj) => {
  const startSlice =
    obj.currentPage * obj.selectedPageRange - obj.selectedPageRange;
  investmentsListDesktop.value = investmentsFullList.value.slice(
    startSlice,
    startSlice + obj.selectedPageRange,
  );
};

const moreInvestmentsListMobile = (obj) => {
  const additionalListArr = investmentsFullList.value.slice(
    investmentsListMobile.value.length,
    investmentsListMobile.value.length + obj.selectedPageRange,
  );
  additionalListArr.forEach((el) => {
    investmentsListMobile.value.push(el);
  });
};

const getInvestmentsForSale = () => {
  isLoading.value = true;

  const query = {
    companyId: store.selectedCompanyId,
  };

  return server.getInvestmentsForSale
    .send(query)
    .pipe(onFetchInvestmentsForSaleSuccess, () => {
      isLoading.value = false;
    })
    .exec();
};

const onFetchInvestmentsForSaleSuccess = (obj) => {
  isLoading.value = false;
  investmentsFullList.value = obj.data.investments;
  totalInvestmentsSum.value = obj.data.totalMainDebtAmount;
  investmentsListCount.value = obj.data.investments.length;
  howItWorksText.value = obj.data.howItWorks;
  changeInvestmentsListPageDesktop({
    currentPage: 1,
    selectedPageRange: 20,
  });
  moreInvestmentsListMobile({
    selectedPageRange: 5,
  });
};

watch(selectedCompany, () => {
  getInvestmentsForSale();
});

const init = () => {
  getInvestmentsForSale();
};
init();
</script>

<style scoped lang="scss">
@use '../../../scss/variables.scss' as *;

.investments-new-request-step-1-message {
  padding: 0 10px;
}

.investments-new-request-step-1-message-grey-text {
  color: $grey;
}
</style>
