<template>
  <Transition>
    <div
      v-if="isShowMessage"
      class="tips_wrapper"
      :class="{
        alert_info: !tipType || tipType === 'alert',
        success_info: tipType === 'success',
      }"
    >
      <!-- <div class="icon_alert_info_local icon_alert_info"></div> -->
      <div
        class="icon_close_local icon_close_grey_second"
        @click="onClickClose"
      ></div>
      <div class="tips_content">
        {{ message }}
      </div>
    </div>
  </Transition>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { useStateStore } from '@/store/stateStore';

const SHOW_TIP_TIMEOUT = 3000;
const isShow = ref(true);
const store = useStateStore();

const isShowMessage = computed(() => {
  return !!store.state.tip.message;
});

const tipType = computed(() => {
  return store.state.tip.type;
});
const message = computed(() => {
  return store.state.tip.message;
});

const onClickClose = () => {
  isShow.value = false;
  store.removeTip();
};

watch(
  () => {
    return store.state.tip.message;
  },
  (value) => {
    if (value) {
      setTimeout(() => {
        store.removeTip();
      }, SHOW_TIP_TIMEOUT);
    }
  },
);
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.tips_wrapper {
  position: fixed;
  top: 100px;
  right: 20px;
  background: #fff;
  box-shadow: 0 16px 64px rgb(183 203 228 / 50%);
  border-radius: 8px;
  color: #10273d;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 18px 50px;
  z-index: 100;
  max-width: 364px;

  @media (max-width: $size_767) {
    top: 50px;
    right: 10px;
    left: 10px;
  }
}

.alert_info {
  border-left: 3px solid #173b57;
}

.success_info {
  border-left: 3px solid $tiffany;
}

.icon_alert_info_local {
  position: absolute;
  width: 14px !important;
  height: 14px !important;
  left: 20px;
  top: 22px;
}

.icon_close_local {
  position: absolute;
  right: 20px;
  top: 20px;
  color: $grey;
  cursor: pointer;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
