<template>
  <section class="investments-my-portfolio">
    <InvestmentsBalance />

    <div>
      <InvestmentsMenuDesktop
        v-if="!isMobile"
        :projects-count="projectsCount"
        :active-status="activeStatus"
        @upate-active-status="setActiveStatus"
      />
      <InvestmentsMenuMobile
        v-if="isMobile"
        :projects-count="projectsCount"
        :active-status="activeStatus"
        :filters="filters.filters"
        :mobile-sort-field="mobileSortField"
        @upate-active-status="setActiveStatus"
        @update-mobile-filters="onUpdateMobileFilters"
      />
    </div>

    <div>
      <InvestmentsFilterTable
        :status="activeStatus"
        @update-filters="updateFilters"
      />
      <template v-if="isLoading">
        <Loader />
      </template>
      <template v-if="!isLoading && totalItemsCount > 0">
        <GeneralTable
          :items="pagedItems(itemsSortedFiltersByStatus)"
          :columns="columns({ status: activeStatus })"
          :sort-column="sortColumn"
          :sort-column-direction="sortColumnDirection"
          :total-columns="
            totalColumns({
              items: itemsSortedFiltersByStatus,
              status: activeStatus,
            })
          "
          @select-sort-column="onSelectSortColumn"
        />
      </template>
      <p
        v-if="
          !isLoading &&
          pagedItems(itemsSortedFiltersByStatus).length == 0 &&
          Object.keys(filters.filters).length > 0
        "
        style="color: #555"
      >
        Ничего не найдено
      </p>
    </div>

    <template v-if="!isLoading && !areThereAnyProjects">
      <div class="project-item-wrapper">
        <div class="investments-item" style="padding: 0">
          <p>Похоже, у вас нет проинвестированных проектов.</p>
        </div>
      </div>
    </template>

    <PaginationDesktop
      v-if="!isLoading && !isMobile && totalItemsCount > 0"
      v-model:current-page="currentPage"
      v-model:selected-page-range="selectedPageRangeDesktop"
      :total-items-count="totalItemsCount"
      :items-pluralize-array="['инвестиции','инвестиций','инвестиций']"
    ></PaginationDesktop>

    <PaginationMobile
      v-if="!isLoading && isMobile && totalItemsCount > 0"
      v-model:current-page="currentPage"
      v-model:selected-page-range="selectedPageRangeMobile"
      :total-items-count="totalItemsCount"
    ></PaginationMobile>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue';

import { Loader } from 'potok-uikit';
import GeneralTable from '@/components/_generic/table/GeneralTable.vue';

import PaginationDesktop from '@/components/_generic/pagination/paginationDesktop.vue';
import PaginationMobile from '@/components/_generic/pagination/paginationMobile.vue';
import InvestmentsMenuDesktop from '@/components/Investments/InvestmentsContent/InvestmentsMenu/InvestmentsMenuDesktop.vue';
import InvestmentsMenuMobile from '@/components/Investments/InvestmentsContent/InvestmentsMenu/InvestmentsMenuMobile.vue';
import InvestmentsBalance from '@/components/Investments/InvestmentsContent/InvestmentsBalance.vue';
import InvestmentsFilterTable from '@/components/Investments/InvestmentsContent/InvestmentsFilterTable.vue';

import investmentsUtils from '@/utils/investments/investmentsUtils';

import investmentsFiltersComposable from '@/composables/Investments/investmentsFiltersComposable';
import investmentsLoadComposable from '../../composables/Investments/investmentsLoadComposable';
import investmentsSortComposable from '../../composables/Investments/investmentsSortComposable';

import detectDevice from '@/composables/detectDeviceComposable';

const { isMobile } = detectDevice();
const {
  items,
  activeStatus,
  projectsCount,
  isLoading,
  areThereAnyProjects,
  getItemsByStatus,
} = investmentsLoadComposable();

const {
  selectedPageRangeDesktop,
  selectedPageRangeMobile,
  currentPage,
  filters,
  pagedItems,
  filteredItems,
  updateFilters,
  updateMobileFilters,
} = investmentsFiltersComposable();

const {
  sortItems,
  sortColumn,
  sortColumnDirection,
  selectSortColumn,
  selectSortColumnMobile,
} = investmentsSortComposable();

const { emitTrackerEvent, columns, totalColumns } = investmentsUtils();

const mobileSortField = ref('default');

const itemsFiltered = computed(() => {
  return filteredItems(
    getItemsByStatus({
      items,
      status: activeStatus.value,
    }),
  );
});

const totalItemsCount = computed(() => {
  return itemsFiltered.value.length;
});

const itemsSortedFiltersByStatus = computed(() => {
  return sortItems(itemsFiltered.value);
});

const setActiveStatus = (name) => {
  if (activeStatus.value === name) {
    return;
  }

  activeStatus.value = name;
  currentPage.value = 1;
  sortColumn.value = null;
  sortColumnDirection.value = 'up';
  filters.filters = {};
  mobileSortField.value = 'default';

  emitTrackerEvent({ name });
};

const onSelectSortColumn = (_data) => {
  selectSortColumn(_data);
};

const onUpdateMobileFilters = (obj) => {
  updateMobileFilters(obj, selectSortColumnMobile);
};
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.investments-my-portfolio {
  .investments_title_block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 27px;

    @media (max-width: $size_767) {
      margin-top: 5px;
      flex-direction: column;
      margin-bottom: 4px;
    }

    .button-history-operation {
      @media (max-width: $size_767) {
        margin-bottom: 20px;
      }
    }
  }

  .top-extra-links {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $size_767) {
      flex-direction: column;
    }

    &__report-link {
      margin-right: 10px;

      @media (max-width: $size_767) {
        margin-right: 0;
      }
    }
  }

  :deep(.tableitems__investment) {
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    text-transform: none;

    ::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 30px;
      background: linear-gradient(to right, rgb(255 255 255 / 0%), #fff);
    }
  }
}

.history {
  display: flex;

  @media (max-width: $size_767) {
    margin-top: 15px;
  }
}
</style>
