<template>
  <div>
    <div class="menu_mobile">
      <div class="block_left">
        <div class="block-menu-mobile-item">
          <CSelect
            v-model="localActiveStatus"
            class="block-menu-select"
            :items="itemsMenu"
            style="margin-top: 0"
            @update:model-value="onChangeSelectStatus()"
          >
          </CSelect>
        </div>
      </div>
      <div v-if="!props.hideFilters" class="block_right">
        <button
          type="button"
          class="button-transparent"
          @click="isShowModalFilters = true"
        >
          <i class="icon_filters"></i>
        </button>
      </div>
    </div>
    <div v-if="hasSelectedFilters" class="menu_mobile_filter_desc">
      выбрано {{ hasSelectedFilters }} фильтра
    </div>
    <LoansFiltersMobile
      v-if="isShowModalFilters"
      :title="'Фильтры'"
      :filters="props.filters"
      @change-filters="onChangeFilters"
      @change-selected-sorting-options="onChangeSorting"
      @close="onCloseModalFilters"
    />
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';

import { CSelect } from 'potok-uikit';
import LoansFiltersMobile from '../LoansFilters/LoansFiltersMobile.vue';

const MENU_ACTIVE = 'Active';
const MENU_INVESTMENT = 'Investment';
const MENU_COMPLETE = 'Complete';
const MENU_ALL = 'All';

const emit = defineEmits([
  'selectMenu',
  'changeSelectedSortingOptions',
  'changeFilters',
]);
const props = defineProps([
  'selectedMenu',
  'count',
  'selectedSortingOptions',
  'filters',
  'hideFilters',
]);

const itemsMenu = ref([]);

const isShowModalFilters = ref(false);
const localActiveStatus = ref(MENU_ACTIVE);

const hasSelectedFilters = computed(() => {
  let count = 0;
  props.filters.borrowType.forEach((i) => {
    if (i.value) {
      count += 1;
    }
  });
  props.filters.loanType.forEach((i) => {
    if (i.value) {
      count += 1;
    }
  });
  props.filters.riskType.forEach((i) => {
    if (i.value) {
      count += 1;
    }
  });
  return count;
});

const onChangeSelectStatus = () => {
  emit('selectMenu', localActiveStatus.value);
};

const onCloseModalFilters = () => {
  isShowModalFilters.value = false;
};

const onChangeFilters = (data) => {
  emit('changeFilters', data);
};

const onChangeSorting = (data) => {
  emit('changeSelectedSortingOptions', data);
};

const generateMenu = () => {
  itemsMenu.value = [
    {
      value: MENU_ACTIVE,
      title: 'активные ' + props.count.activeProjects,
    },
    {
      value: MENU_INVESTMENT,
      title: 'зарезервированные ' + props.count.investmentProjects,
    },
    {
      value: MENU_COMPLETE,
      title: 'собранные ' + props.count.completeProjects,
    },
    {
      value: MENU_ALL,
      title: 'всего ' + props.count.allProjects,
    },
  ];
};

watch(
  () => props.count,
  () => {
    generateMenu();
  },
  { deep: true },
);

onMounted(() => {
  generateMenu();
});
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.menu_mobile {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 6px;
}

.block_left {
  flex: 2;
}

.block_right {
  margin-left: 16px;
}

.block-menu-select {
  background: transparent;
  height: 44px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #313132;
}

.menu_mobile_filter_desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #999;
  margin: 10px 0 20px;
}
</style>
