<template>
  <div class="potok-tabs-secondary">
    <div
      v-for="(item, index) in props.items"
      :key="item.text + index"
      :class="{
        active: item.isActive,
        'potok-text-body-2': props.small,
        small: props.small,
        'colored-icon': props.isColoredIcon,
      }"
      class="potok-tabs-item potok-text-body-1"
      @click="changeTab(item)"
    >
      <slot :item="item" />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  items: {
    type: [null, Array],
    default: () => [],
  },
  small: {
    type: Boolean,
    default: false,
  },
  isColoredIcon: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['changeTab']);

const changeTab = (item) => {
  emit('changeTab', item);
};
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.potok-tabs-secondary {
  height: 100%;
  display: flex;

  @media (max-width: $size_767) {
    justify-content: space-between;
  }

  .potok-tabs-item {
    padding: 0 10px;
    height: 44px;
    color: $grey;
    cursor: pointer;
    border: 1px solid #dedee1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $size_767) {
      width: 100%;
    }

    &:first-child {
      border-radius: 8px 0 0 8px;
      border-right: none;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
      border-left: none;
    }

    &:first-child.active {
      margin-right: -1px;
      position: relative;
    }

    &:last-child.active {
      margin-left: -1px;
    }

    &:hover {
      color: $deep_dive;
    }
  }

  .small {
    padding: 0 10px;
    height: 30px;

    // height: 26px;
  }

  .active {
    color: $breakwater;
    border: 1px solid $deep_dive;
    border-right: 1px solid $deep_dive !important;
    border-left: 1px solid $deep_dive !important;
  }
}

.colored-icon.active {
  :deep(img) {
    filter: invert(9%) sepia(97%) saturate(726%) hue-rotate(177deg)
      brightness(98%) contrast(92%);
  }
}

.colored-icon {
  :deep(img) {
    filter: invert(60%) sepia(7%) saturate(655%) hue-rotate(166deg)
      brightness(92%) contrast(86%);
  }
}
</style>
