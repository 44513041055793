<template>
  <PotokPage title="Уведомления">
    <template v-if="isPending">
      <div style="padding-bottom: 40px">
        <Loader />
      </div>
    </template>
    <template v-else>
      <div class="notifications-list">
        <div
          v-for="notification in notifications"
          :id="notification.id"
          :key="notification.id"
          :class="{ viewed: !notification.isViewed }"
          class="notifications-item"
        >
          <h4 v-if="notification.subject">{{ notification.subject }}</h4>
          <div class="date">
            {{ formatDate(notification.createdAt, 'dd.MM.yyyy HH:mm') }}
          </div>
          <span v-html="notification.message"></span>
        </div>
      </div>
    </template>
  </PotokPage>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import { formatDate } from '@/utils/commonUtils/utils';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';
import { Loader, PotokPage } from 'potok-uikit';

const store = useStateStore();

const isPending = computed(() => {
  return loadingNotification.value;
});
const loadingNotification = ref(false);
const notifications = ref([]);
const notificationViewed = ref([]);

onMounted(() => {
  getNotificationsAsync();
});
const getNotificationsAsync = () => {
  const query = {
    isGlobal: false,
  };
  loadingNotification.value = true;
  return server.getNotification
    .send(query)
    .pipe(onGetNotificationAsyncSuccess)
    .exec();
};
const onGetNotificationAsyncSuccess = ({ data }) => {
  loadingNotification.value = false;
  notifications.value = data;
  const counter = notifications.value.filter((i) => !i.isViewed).length;
  store.merge({
    notification: {
      counter,
    },
  });

  nextTick(function () {
    handleCheckNotifications();
    document.body.onscroll = () => handleCheckNotifications();
  });
};
const putNotificationsViewedAsync = () => {
  Promise.all(
    notificationViewed.value.map((id) => {
      const pipeline = server.putNotification.send(
        {},
        {
          params: { notificationId: id },
        },
      );
      return pipeline.pipe().exec();
    }),
  );
};

const handleCheckNotifications = () => {
  let arrNotif = document.querySelectorAll('.notifications-item');
  for (let elem of arrNotif) {
    let elemId = elem.getAttribute('id');
    let elemTop = elem.getBoundingClientRect().top;
    if (elemTop < window.innerHeight - 100) {
      if (!notificationViewed.value.includes(elemId)) {
        notificationViewed.value.push(elemId);
        setTimeout(() => {
          elem.classList.remove('viewed');
          putNotificationsViewedAsync();
        }, 500);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@use '../scss/variables.scss' as *;

.notifications-list {
  padding: 10px 40px 40px 0;

  @media (max-width: $size_767) {
    padding: 24px 15px 70px;
  }

  h4 {
    margin-bottom: 20px;
  }
}

.notifications-item {
  background-color: white;
  border-radius: 2px;
  padding: 25px 35px;
  margin-bottom: 20px;
}
</style>
