<template>
  <div class="refill-account">
    <div class="block-left block_content">
      <div class="text-desc potok-text-h2">
        <div v-html="props.data?.text"></div>
      </div>
      <div class="fields">
        <div class="col-md-8 col-sm-12 px-0">
          <div v-for="(item, i) in props.fields" :key="i" class="field-item">
            <FormField
              :name="item.name"
              name-width="5"
              value-width="6"
              class="field-item-container"
            >
              <template #value>
                <div class="field-item-value" @click="onClickCopy(item)">
                  <div class="field-item-text">{{ item.value }}</div>
                  <i class="icon_clipboard field-item-clipboard ml-2" />
                  <div v-if="item.copied" class="copied">👍 скопировано</div>
                </div>
              </template>
            </FormField>
          </div>
        </div>
      </div>
    </div>
    <div class="block-right block_content">
      <div class="qr-wrapper">
        <div class="potok-text-h2 text-qr-desc">или по qr коду</div>
        <vue-qr-code-payment
          class="code_payment"
          :payee-name="props.paymentValues.payeeName"
          :payee-inn="props.paymentValues.payeeInn"
          :payee-kpp="props.paymentValues.payeeKpp"
          :payee-personal-acc="props.paymentValues.payeePersonalAcc"
          :payee-bank-name="props.paymentValues.payeeBankName"
          :payee-bank-bic="props.paymentValues.payeeBankBic"
          :payee-bank-corresp-acc="props.paymentValues.payeeBankCorrespAcc"
          :purpose="props.paymentValues.purpose"
          :width="360"
        >
        </vue-qr-code-payment>
        <div class="potok-text-body-2">
          отсканируйте qr код в мобильном приложении банка
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import FormField from '@/components/_generic/forms/FormField.vue';
import VueQrCodePayment from '@/components/_generic/VueQrCodePayment';

const props = defineProps({
  fields: {
    type: Array,
  },
  paymentValues: {
    type: Object,
  },
  data: {
    type: Object,
    required: false,
  },
});

const onClickCopy = (item) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(item.value).then(function () {
      item.copied = true;
      setTimeout(() => {
        item.copied = false;
      }, 2000);
    });
  }
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.refill-account {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.block-left {
  min-width: 750px;
}

.block-right {
  min-width: 360px;
  padding: 30px 0;

  @media (max-width: $size_1200) {
    margin-top: 20px;
  }
}

.text-desc {
  max-width: 590px;
}

.fields {
  margin-top: 20px;
}

.qr-text-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $grey;
  margin: auto;
  margin-bottom: 20px;
}

.field-item-container {
  width: 100%;
}

.qr-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.potok-text-body-2 {
  max-width: 300px;
}

.text-qr-desc {
  padding: 0 20px;
  width: 100%;
}

.field-item-value {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .field-item-text {
      opacity: 0.9;
    }

    .field-item-clipboard {
      display: block;
    }
  }
}

.field-item-clipboard {
  display: none;
  min-width: 18px;
  min-height: 18px;
}

.copied {
  position: absolute;
  left: 5px;
  bottom: 25px;
}
</style>
