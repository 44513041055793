<template>
  <div v-on-clickaway="onClickaway" class="range_wrapper">
    <range-datepicker-calendar
      :periods="periods"
      :init-date-since="props.selectedRange.range[0]"
      :init-date-to="props.selectedRange.range[1]"
      @change="changeDateRange"
      @close="emits('close')"
    />
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { sub } from 'date-fns';

import { directive as vOnClickaway } from 'vue3-click-away';
import RangeDatepickerCalendar from '@/components/_generic/rangedatapicker/RangeDatepickerCalendar.vue';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();

const emits = defineEmits(['change', 'close']);
const props = defineProps({
  isShow: {
    type: Boolean,
    required: true,
  },
  selectedRange: {
    type: Object,
    default: () => {},
  },
});

const periods = ref([
  {
    title: '3 месяца',
    range: [sub(new Date(), { months: 3 }), sub(new Date(), { days: 1 })],
    periodLength: 3,
  },
  {
    title: '6 месяцев',
    range: [sub(new Date(), { months: 6 }), sub(new Date(), { days: 1 })],
    periodLength: 6,
  },
  {
    title: '12 месяцев',
    range: [sub(new Date(), { months: 12 }), sub(new Date(), { days: 1 })],
    periodLength: 12,
  },
  {
    title: 'все время',
    range: [
      store.state.user.createdAt
        ? new Date(store.state.user.createdAt)
        : sub(new Date(), { years: 3 }),
      sub(new Date(), { days: 1 }),
    ],
    periodLength: 0,
  },
]);

const changeDateRange = (data) => {
  emits('change', data);
};

const onClickaway = () => {
  emits('close');
};

watch(
  () => {
    return props.isShow;
  },
  (value) => {
    if (value && document.documentElement.clientWidth <= 767) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
    }
  },
);
</script>

<style lang="scss" scoped>
.range_wrapper {
  position: relative;
}

.range_content {
  top: 30px;
}
</style>
