const config = (() => {
  return {
    MODE: import.meta.env.MODE,
    VITE_BASE_URL: import.meta.env.VITE_BASE_URL,
    VITE_PASS_REG_URL: import.meta.env.VITE_PASS_REG_URL,
    VITE_AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,
    VITE_YM_API_KEY: import.meta.env.VITE_YM_API_KEY,
    VITE_APP_DADATA_API_KEY: import.meta.env.VITE_APP_DADATA_API_KEY,
    VITE_APP_FEEDBACK_URL: import.meta.env.VITE_APP_FEEDBACK_URL,
  };
})();

export default config;
