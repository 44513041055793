<template>
  <div>
    <div class="step_title">Присоединитесь к правилам</div>
    <p class="step_description">
      Вам будет отправлен СМС-код — его ввод подтвердит согласие с
      <a
        href="https://files.potok.digital/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%20%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D1%8B/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%98%D0%BD%D0%B2%D0%B5%D1%81%D1%82%D0%B8%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%BE%D0%B9%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D1%8B%20%D0%9F%D0%BE%D1%82%D0%BE%D0%BA.pdf"
        target="_blank"
        >Правилами платформы</a
      >
    </p>
    <div>
      <PotokDivider margin-y="20" />
      <button
        class="button button-secondary"
        type="button"
        :disabled="isDisabledContinue"
        @click="onClickContinue"
      >
        Продолжить
      </button>

      <p style="margin-top: 20px">
        Нажимаю кнопку «Продолжить» вы присоединяетесь к
        <a
          href="https://files.potok.digital/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%20%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D1%8B/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%98%D0%BD%D0%B2%D0%B5%D1%81%D1%82%D0%B8%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%BE%D0%B9%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D1%8B%20%D0%9F%D0%BE%D1%82%D0%BE%D0%BA.pdf"
          target="_blank"
          >Правилам платформы</a
        >, подтверждаете, что ознакомились и
        <a
          href="https://v3.potok.digital/legal/deklaraciya_o_riskah.pdf"
          target="_blank"
          >принимаете риски</a
        >, связанные с инвестированием Подтверждаете, что являюетесь налоговым
        резидентом РФ и не являетесь российским или иностранным должностным
        лицом, действуете к собственной выгоде и не имеете бенефициаров.
        Присоединяетесь к договору инкассо-цессии.
      </p>
      <p>
        Подтверждаю, что согласен использовать
        <a
          href="https://v3.potok.digital/legal/reglament_raboty_avtoinvestirovaniya.pdf"
          target="_blank"
        >
          функционал автоинвестирования
        </a>
      </p>
    </div>

    <ModalConfirmSMS
      v-if="isShowModalConfirm"
      :loading="loading"
      :confirm-function="onClickConfirmSMS"
      :confirm-message="confirmMessage"
      @close="isShowModalConfirm = false"
    />
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';
import { PotokDivider } from "potok-uikit";
import server from '@/server';
import ModalConfirmSMS from '@/components/_generic/modals/ModalConfirmSMS.vue';
import constants from '@/constants';

import tracker from '@/tracker';

import { useStateStore } from '@/store/stateStore';

const store = useStateStore();
const router = useRouter();

const loading = ref(false);
const loadingPostCompaniesConfirmEula = ref(false);
const isShowModalConfirm = ref(false);
const settingsId = ref(null);
const confirmMessage = `
        Вводя код из смс, я подтверждаю корректность введенных данных,
        достоверность копий документов, а также подтверждаю свое согласие с
        присоединением к документам платформы
      `;
const selectedCompany = computed(() => {
  return store.selectedCompany;
});
const isDisabledContinue = computed(() => {
  return loading.value;
});
const onClickContinue = () => {
  postCompaniesConfirmEulaSendSmsAsync();
};
const postCompaniesConfirmEulaSendSmsAsync = () => {
  loading.value = true;
  return server.postCompaniesConfirmEulaSendSms
    .send()
    .pipe(
      onPostCompaniesConfirmEulaSendSmsAsyncSuccess.bind(this),
      onPostCompaniesConfirmEulaSendSmsAsyncError.bind(this),
    )
    .exec();
};
const onPostCompaniesConfirmEulaSendSmsAsyncSuccess = ({ data }) => {
  loading.value = false;
  if (data?.smsId) {
    settingsId.value = data.smsId;
    isShowModalConfirm.value = true;
  }
};
const onPostCompaniesConfirmEulaSendSmsAsyncError = () => {
  loading.value = false;
};
const onClickConfirmSMS = ({ code }) => {
  const query = {
    smsCode: code,
    isAgreeRisks: true,
    isAgreeRules: true,
    isAgreeCession: true,
    isAgreeTaxResident: true,
    isAgreeInvestment: true,
    isAgreementWithRules: true,
    smsId: settingsId.value,
  };
  if (selectedCompany.value.typeId === constants.companyTypes.company) {
    tracker.queue(
      tracker.commands.SEND,
      'Investor_LK_My_Account_Deposit_Button_Accept_Rules_UL',
    );
  } else {
    tracker.queue(
      tracker.commands.SEND,
      'Investor_LK_My_Account_Deposit_Button_Accept_Rules_IP',
    );
  }
  loading.value = true;
  loadingPostCompaniesConfirmEula.value = true;
  return server.postCompaniesConfirmEulaForCompany
    .send(query)
    .pipe(
      onPostCompaniesConfirmEulaAsyncSuccess.bind(this, query),
      onPostCompaniesConfirmEulaAsyncError.bind(this),
    )
    .exec();
};
const onPostCompaniesConfirmEulaAsyncSuccess = () => {
  loading.value = false;
  loadingPostCompaniesConfirmEula.value = false;
  isShowModalConfirm.value = true;
  store.fetchCompanies();
  router.push({
    name: 'main',
  });
};
const onPostCompaniesConfirmEulaAsyncError = () => {
  loading.value = false;
  loadingPostCompaniesConfirmEula.value = false;
};
</script>
<style></style>
