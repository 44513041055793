<template>
  <div>
    <VueSuggestions
      id="search"
      v-model="search"
      :placeholder="'Начните вводить'"
      :options="suggestionOptions"
      autocomplete="off"
      class="form-input"
    >
    </VueSuggestions>
    <template v-if="props.isFindCompany">
      <div style="margin: 20px 0">
        <FormField
          :name="'Наименование'"
          :value="props.formCompany.shortName"
        />
        <FormField :name="'Адрес'" :value="props.formCompany.formalAddress" />
        <FormField :name="'ИНН'" :value="props.formCompany.inn" />
        <FormField :name="'ОГРН'" :value="props.formCompany.ogrn" />
        <FormField :name="'КПП'" :value="props.formCompany.kpp" />
        <FormField
          :class="{ 'company-error': props.isFindCompanyFailure }"
          :field-error="props.isFindCompanyFailure"
          :name="'Директор'"
          :value="props.formCompany.directorFio"
        />
        <FormField
          :name="'Дата регистрации'"
          :value="formatDate(props.formCompany.registerDateAt)"
        />
      </div>
      <div v-show="props.isFindCompanyFailure" class="find-error">
        Вы не можете добавить организацию, в которой не являетесь единоличным
        исполнительным органом (генеральным директором), а также ИП
      </div>
      <div v-if="props.isInvalidSelectedCompanyType" class="find-error">
        <p>
          Вы не можете добавить этот тип юридического лица, т.к. на одном
          предыдущих шагов вы уже выбрали другой.
        </p>
      </div>
    </template>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { fromUnixTime } from 'date-fns';
import VueSuggestions from '@/libs/suggestions/vue-suggestions.vue';
import FormField from '@/components/_generic/forms/FormField.vue';
import { formatDate, formatName } from '@/utils/commonUtils/utils';
import constants from '@/constants';

import { useStateStore } from '@/store/stateStore';

const store = useStateStore();

const props = defineProps([
  'formCompany',
  'isFindCompany',
  'isFindCompanyFailure',
  'isInvalidSelectedCompanyType',
  'expectedCompanyType',
]);

const emits = defineEmits([
  'update:isFindCompany',
  'update:isFindCompanyFailure',
  'update:isInvalidSelectedCompanyType',
  'update:formCompany',
]);

const search = ref(null);
const selectedDadataType = ref('unknown');
const userFullName = computed(() => {
  return store.userFullName;
});
const user = computed(() => {
  return store.state.user;
});

const onDadataSelect = (_suggestion) => {
  Object.assign(props.formCompany, translateSuggestionData(_suggestion));
  emits('update:formCompany', props.formCompany);
  const isFindCompanyFailure =
    formatName(props.formCompany.directorFio) !==
    formatName(userFullName.value);
  selectedDadataType.value = _suggestion.data.type;
  emits('update:isFindCompany', true);
  emits('update:isFindCompanyFailure', isFindCompanyFailure);
  const isInvalidSelectedCompanyType =
    props.formCompany.typeId === getCompanyType(props.expectedCompanyType)
      ? false
      : true;
  emits('update:isInvalidSelectedCompanyType', isInvalidSelectedCompanyType);
};

const suggestionOptions = {
  token: import.meta.env.VITE_APP_THIRD_PARTY_SERVICE_DADATA_TOKEN,
  type: 'PARTY',
  scrollOnFocus: false,
  triggerSelectOnBlur: false,
  triggerSelectOnEnter: false,
  addon: 'none',
  onSelect: onDadataSelect,
  params: {
    status: ['ACTIVE'],
    type: null,
  },
  geoLocation: true,
};

onMounted(() => {
  suggestionOptions.params.type = props.expectedCompanyType;
  const dadataEl = document.getElementById('search');
  if (props.expectedCompanyType === 'INDIVIDUAL') {
    search.value = user.value.inn;
  }
  if (props.expectedCompanyType === 'LEGAL') {
    search.value = userFullName.value;
  }
  if (dadataEl) {
    dadataEl.focus();
  }
});

const translateSuggestionData = (_suggestion) => {
  const isLegal = _suggestion.data.type === 'LEGAL';
  return {
    shortName:
      _suggestion.data.name.short_with_opf ||
      _suggestion.data.name.full_with_opf,
    fullName: _suggestion.data.name.full_with_opf,
    formalAddress: _suggestion.data.address
      ? _suggestion.data.address.value
      : '',
    inn: _suggestion.data.inn,
    ogrn: _suggestion.data.ogrn,
    kpp: isLegal ? _suggestion.data.kpp : '',
    directorFio: isLegal
      ? _suggestion.data.management.name
      : _suggestion.data.name.full,
    registerDateAt: _suggestion.data.state.registration_date
      ? fromUnixTime(_suggestion.data.state.registration_date / 1000)
      : null,
    isRealCompany: _suggestion.data.status === 'ACTIVE',
    opf: _suggestion.data.opf.short,
    directorPosition: isLegal ? _suggestion.data.management.post : '',
    typeId: getCompanyType(_suggestion.data.type),
  };
};
const getCompanyType = (type) => {
  if (type === 'LEGAL') {
    return constants.companyTypes.company;
  }
  if (type === 'INDIVIDUAL') {
    return constants.companyTypes.entrepreneur;
  }
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

#search {
  outline: none;
  height: 36px;
  padding: 0 14px;
  border: 1px solid #dedee1;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: none;
  font-weight: 400;
}

.find-error {
  margin-top: 30px;
  color: $error;
  text-align: center;
}
</style>
