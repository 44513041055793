<template>
  <ModalFull
    v-if="props.isShow"
    :title="'Как настроить'"
    @close="closeHelpModal"
  >
    <template #content>
      <div class="potok-text-body-2">
        <div>
          Через бот можно получать уведомления о зачислении/выводе средств, а
          также иную полезную информацию о ваших инвестициях.
        </div>

        <div class="potok-text-uppercase grey mt-5">СПОСОБ 1</div>
        <div class="mt-1">
          перейдите по персональной секретной ссылке для подписки. Не
          пересылайте ее третьим лицам, иначе они смогут подписаться на ваши
          уведомления
        </div>

        <div class="potok-text-uppercase grey mt-5">СПОСОБ 2</div>
        <div class="copy-container mt-1">
          — Скопируйте и отправьте этот ключ
          <span class="key tiffany" @click="onClickCopy">
            {{ props.authToken }}
          </span>
          <div v-if="isCopied" class="copied">👍 скопировано</div>
          нашему боту
          <a href="https://t.me/PotokInfoBot" target="_blank" class="tiffany">
            @PotokInfoBot
          </a>
          в Telegram.
        </div>
        <div>
          — Включите оповещения, нажав на кнопку Start в боте или набрав
          “/start”.
        </div>
        <div>
          — Обязательно завершите подключение бота одним из способов указанных
          выше!
        </div>

        <div class="mt-5">Бот принимает следующие команды:</div>
        <div><span class="tiffany">/help</span> — текущая справка</div>
        <div><span class="tiffany">/stop</span> — отписаться от оповещений</div>
        <div><span class="tiffany">/start</span> — включить оповещения</div>
        <div>
          <span class="tiffany">/start TOKEN</span> — авторизоваться для
          получения сообщений от имени пользователя-владельца токена
        </div>
      </div>
    </template>
  </ModalFull>
</template>

<script setup>
import { ref } from 'vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

const emits = defineEmits('close');
const props = defineProps({
  isShow: Boolean,
  authToken: String,
});

const isCopied = ref(false);

const closeHelpModal = () => {
  emits('close');
};
const onClickCopy = () => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(props.authToken).then(function () {
      isCopied.value = true;
      setTimeout(() => {
        isCopied.value = false;
      }, 2000);
    });
  }
};
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.tiffany {
  color: $tiffany;
}

.grey {
  color: $grey;
}

.key {
  cursor: pointer;
}

.copied {
  left: 200px;
  bottom: 40px;
  position: absolute;
}

.copy-container {
  position: relative;
}
</style>
