<template>
  <PotokDialog width="400" title="укажите Telegram" @close="cancel">
    <template #content>
      <div class="potok-text-body-2 mt-2">
        Мы используем мессенджер для оперативной связи по важным вопросам.
        Пожалуйста оставьте свой ник, чтобы мы могли вас найти.
      </div>

      <div class="telegram-input-container mt-7">
        <input
          id="telegram"
          v-model="inputValue"
          class="form-control telegram-input"
          type="text"
          :readonly="isLoading"
          placeholder="ник в Telegram"
          @keyup.enter="saveTelegram"
        />
        <div v-if="errorMessage" class="potok-color-red mt-2">
          {{ errorMessage }}
        </div>
      </div>
    </template>

    <template #actions>
      <div class="telegram-dialog-actions mt-5">
        <PotokButton
          text="сохранить"
          :loading="isLoading"
          size="large"
          class="telegram-dialog-btn"
          @click="saveTelegram"
        />
        <PotokButton
          class="btn-cancel mt-5"
          text="не сейчас"
          :disabled="isLoading"
          theme="link"
          size="link"
          @click="cancel"
        />
      </div>
    </template>
  </PotokDialog>
</template>

<script setup>
import { ref } from 'vue';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';
import PotokDialog from '@/components/_generic/dialogs/PotokDialog.vue';
import { format } from 'date-fns';

const store = useStateStore();

const inputValue = ref('');
const isLoading = ref(false);
const errorMessage = ref('');

const isTelegramValid = () => {
  if (inputValue.value.length > 32) {
    errorMessage.value = 'ник Telegram должен содержать максимум 32 символа';
    return false;
  }
  if (inputValue.value.length < 4) {
    errorMessage.value = 'ник Telegram должен содержать минимум 4 символа';
    return false;
  }
  if (/[а-яА-ЯёЁ']+/.test(inputValue.value)) {
    errorMessage.value = 'в нике не может быть русских букв';
    return false;
  }

  return true;
};
const saveTelegram = () => {
  if (!isTelegramValid()) {
    return;
  }

  isLoading.value = true;

  const query = {
    tgNickname: inputValue.value,
  };

  return server.putUsersMeProfile
    .send(query)
    .pipe(saveTelegramAsyncSuccess, saveTelegramAsyncError)
    .exec();
};

const saveTelegramAsyncSuccess = () => {
  store.setUserTelegram(inputValue.value);
  isLoading.value = false;
  store.setNotification({
    message: `спасибо Telegram добавлен, изменить его можно в
        <a href="/account/settings" target="_blank" class="telegram-dialog-settings-link">
          настройках
        </a>`,
  });
};
const saveTelegramAsyncError = () => {
  isLoading.value = false;
};
const reset = () => {
  inputValue.value = '';
  errorMessage.value = '';
};
const cancel = () => {
  isLoading.value = true;

  const query = {
    tgBannerRejectedAt: format(new Date(), 'yyyy-MM-dd'),
  };

  return server.putUsersMeProfile
    .send(query)
    .pipe(
      () => {
        isLoading.value = false;
        reset();
        store.state.user.isShowTgBanner = false;
      },
      () => {
        isLoading.value = false;
      },
    )
    .exec();
};
</script>

<style scoped lang="scss">
@use '../../scss/variables.scss' as *;

.telegram-input {
  width: 100%;
  padding-left: 20px;
  color: $deep_dive;
  height: 60px;
  border: 0;
  background: $background;
  border-radius: 8px;

  @media (max-width: $size_767) {
    min-width: 288px;
  }
}

.telegram-dialog-actions {
  display: flex;
  flex-direction: column;
}

.telegram-dialog-settings-link {
  text-decoration: underline;
}
</style>
