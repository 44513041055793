<template>
  <div class="channels-telegram content-block">
    <p class="channels-telegram-title potok-text-body-1-bold">
      Подпишитесь на наши Telegram-каналы
    </p>
    <div class="channels-telegram-links mt-7">
      <PotokButton
        size="link"
        theme="link"
        text="новые займы на сборе"
        no-full-width-tablet
        class="channels-link loans-link potok-text-body-2 mr-5"
        @click="toNewLoans"
      >
        <template #icon-after>
          <div class="channels-link-hover loans-link-hover ml-1">↗</div>
        </template>
      </PotokButton>
      <PotokButton
        size="link"
        theme="link"
        text="новости Потока"
        no-full-width-tablet
        class="channels-link news-link potok-text-body-2"
        @click="toPotokNews"
      >
        <template #icon-after>
          <div class="channels-link-hover news-link-hover ml-1">↗</div>
        </template>
      </PotokButton>
    </div>
  </div>
</template>
<script setup>
const toNewLoans = () => {
  window.open('https://t.me/s/potok_new_projects', '_blank');
};
const toPotokNews = () => {
  window.open('https://t.me/s/potok_digital', '_blank');
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.channels-telegram.content-block {
  width: 100%;
  background: $dividers;

  @media (max-width: $size_767) {
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
  }
}

.channels-telegram-links {
  @media (max-width: $size_767) {
    margin-top: 20px;
  }
}

.loans-link {
  &:hover {
    .loans-link-hover {
      display: block;
    }
  }
}

.news-link {
  text-transform: initial;

  @media (max-width: $size_767) {
    margin-top: 10px;
  }

  &:hover {
    .news-link-hover {
      display: block;
    }
  }
}

.channels-telegram-title {
  margin: 0;
}

.channels-link {
  color: $deep_dive;
}

.channels-link-hover {
  display: none;
}
</style>
