<template>
  <ModalFull :title="'подтверждение измений'" @close="close">
    <template #content>
      <two-factor-auth
        :phone="login"
        :authentication="authentication"
        :error-message="errorMessage"
        :loading="loading"
        @change="onChangeInput"
        @close="close"
        @on-enter="onEnter"
      >
        <button
          :disabled="
            !authentication.smsCode ||
            authentication.smsCode.length != 6 ||
            loading
          "
          type="button"
          class="button button-primary"
          style="width: 100%"
          @click="postCompaniesConfirmAsync()"
        >
          подписать
        </button>
      </two-factor-auth>
      <div
        class="potok-text-body-3"
        style="
          color: #818e99;
          text-align: center;
          margin-top: 30px;
          margin-bottom: -20px;
        "
      >
        Вводя код из смс, я подтверждаю, что хочу быть признанным оператором
        платформы квалифицированным инвестором на основании моего
        <a
          href="https://v3.potok.digital/legal/zayavlenie_o_priznanii_kvalificirovannym_investorom.pdf"
          target="_blank"
          style="color: #173b57"
          >заявления</a
        >, подтверждаю корректность введенных данных, достоверность копий
        документов, а также
        <a
          href="https://v3.potok.digital/legal/zayavlenie_o_prisoedinenii_k_pravilam.pdf"
          target="_blank"
          style="color: #173b57"
          >присоединяюсь</a
        >
        к документам платформы
      </div>
    </template>
  </ModalFull>
</template>

<script setup>
import { ref, computed, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import constants from '@/constants';

import useVuelidate from '@vuelidate/core';
import validators from '@/validators';

import server from '@/server';
import TwoFactorAuth from '@/components/_generic/TwoFactorAuth.vue';
import TwoFactorAuthentication from '@/components/_generic/TwoFactorAuthentication';
import { focusDomElementById } from '@/utils/commonUtils/utils';
import { useStateStore } from '@/store/stateStore';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import tracker from '@/tracker';

const emit = defineEmits(['close']);
const props = defineProps(['form', 'documents']);
const router = useRouter();
const store = useStateStore();

const rulesValidations = {
  authentication: {
    smsCode: {
      required: validators.required,
      exactLength: validators.exactLength(6),
      numeric: validators.numeric,
    },
  },
};

const authentication = reactive(new TwoFactorAuthentication());
const v$ = useVuelidate(rulesValidations, { authentication: authentication });
const loading = ref(false);
const errorMessage = ref(null);

const steps = computed(() => {
  return TwoFactorAuthentication.steps;
});

const login = computed(() => {
  return store.state.session.login;
});

const companyFl = computed(() => {
  return store.state.dictionaries.companies.find(
    (item) => item.typeId === constants.companyTypes.person,
  );
});

onMounted(() => {
  authentication.setStep(steps.value.ACTIVE);
  focusDomElementById('smsCode');
});

const close = () => {
  emit('close');
};

const postCompaniesConfirmAsync = () => {
  const query = {
    id: companyFl.value.id,
    smsCode: authentication.smsCode,
    hasQiSecurities: props.form.hasQiSecurities,
    hasQiExperience: props.form.hasQiExperience,
    hasQiSecuritiesDeals: props.form.hasQiSecuritiesDeals,
    hasQiProperty: props.form.hasQiProperty,
    hasQiEducation: props.form.hasQiEducation,
    hasQiBroker: props.form.hasQiBroker,
  };
  let indexFile = 1;
  props.documents.forEach((item) => {
    if (item.file) {
      const name = `filenameQiDocument${indexFile}`;
      query[name] = item.file;
      indexFile += 1;
    }
  });
  loading.value = true;
  return server.postCompaniesQualificationUpdate
    .send(query)
    .pipe(
      onPostCompaniesConfirmAsyncSuccess.bind(this, query),
      onPostCompaniesConfirmAsyncError.bind(this),
    )
    .exec();
};

const onPostCompaniesConfirmAsyncSuccess = () => {
  loading.value = false;
  authentication.setStep(steps.value.DONE);

  tracker.queue(
    tracker.commands.SEND,
    'Adg_Investory_Podtverzhdenie_kvalifikacii',
  );

  store.fetchCompanies({
    callback: () => {
      store.pushTip({
        message: 'документы успешно загружены',
      });
      router.push({
        name: 'loans',
      });
    },
  });
};

const onPostCompaniesConfirmAsyncError = (error) => {
  loading.value = false;
  const { data } = error;
  if (data.message) {
    errorMessage.value = data.message;
  }
};

const onEnter = () => {
  if (v$.value.authentication.smsCode.$invalid || loading.value) {
    return;
  }

  postCompaniesConfirmAsync();
};
const onChangeInput = () => {
  errorMessage.value = null;
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

:deep(.modal_block_container) {
  max-width: 440px;
}

:deep(.modal_block_title) {
  justify-content: center;
}

:deep(.modal_mobile_block_title) {
  text-align: center;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #7d7d7e;
  margin-bottom: 0;
  text-align: center;
}

.button {
  width: auto;
  height: auto;
  line-height: initial;
}
</style>
