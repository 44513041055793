<template>
  <div class="borrower-rating">
    <FormFieldTitle title="рейтинг" />

    <div
      class="borrower-rating-final borrower-rating-container potok-text-body-1-bold"
    >
      <span class="borrower-rating-label">{{ borrower.modelBucket.label }}</span>
      <BorrowerRatingTiles :rating="props.borrower.modelBucket.rating" />
    </div>

    <div
      v-if="borrower.components && isShowComponents"
      class="borrower-rating-components"
    >
      <div
        v-for="(item, index) in borrower.components"
        :key="index"
        class="borrower-rating-component borrower-rating-container mb-5"
      >
        <div class="borrower-rating-component-left">
          <span class="potok-text-body-2">{{ item.label }}</span>
          <VDropdown
            :placement="'top'"
            class="rating-component-dropdown"
            :triggers="['hover']"
          >
            <PotokImg
              :src="imageConstants.attention"
              height="14"
              width="14"
              class="ml-2"
            />
            <template #popper>
              <div class="potok-text-body-2">
                {{ item.description }}
              </div>
            </template>
          </VDropdown>
        </div>

        <BorrowerRatingTiles
          :rating="item.rating"
          is-component
        />
      </div>

      <div class="mt-5 potok-color-grey">
        Рейтинг – это оценка риска, который несет Инвестор, предоставляя денежные средства тому или иному Заемщику.
      </div>
    </div>

    <PotokDivider v-if="borrower.components" margin-y="20" />

    <PotokButton
      v-if="borrower.components"
      theme="linkIconUpload"
      size="link"
      :text="isShowComponents ? 'скрыть' : 'показать подробнее'"
      @click="(isShowComponents = !isShowComponents)"
    >
      <template #icon-after>
        <PotokImg
          :src="
            isShowComponents
              ? imageConstants.arrowUpTiffany
              : imageConstants.arrowDownTiffany
          "
          height="8"
          width="8"
          class="ml-2"
        />
      </template>
    </PotokButton>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { PotokDivider } from 'potok-uikit';

import FormFieldTitle from '@/components/_generic/forms/FormFieldTitle.vue';
import BorrowerRatingTiles from '@/components/Loan/LoanAboutBorrower/LoanAboutBorrowerRating/BorrowerRatingTiles.vue';

import imageConstants from '@/constants/imageConstants';

const props = defineProps({
  borrower: {
    type: [Object, null],
    default: () => {},
  },
});

const isShowComponents = ref(true);
</script>

<style lang="scss">
@use '../../../scss/variables.scss' as *;
@use '../../../scss/typography.scss' as *;

.borrower-rating {
  width: 100%;
  max-width: 800px;
}

.borrower-rating-container {
  padding-right: 80px;
  display: flex;
  justify-content: space-between;

  @media (max-width: $size_767) {
    display: flex;
    flex-direction: column;
    padding-right: 0;
  }
}

.borrower-rating-final {
  display: flex;
}

.borrower-rating-label {
  @media (max-width: $size_767) {
    @include potok-text-body-2;

    margin-bottom: 10px;
  }
}

.borrower-rating-components {
  margin-top: 28px;
}

.borrower-rating-component {
  background-color: $background;
  border-radius: 6px;
  padding: 16px 80px 16px 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $size_767) {
    padding: 10px;
    width: 100%;
  }
}

.borrower-rating-component-left {
  display: flex;
  align-items: center;

  @media (max-width: $size_767) {
    margin-bottom: 10px;
  }
}

.rating-component-dropdown {
  display: flex;
  align-items: center;
}
</style>
