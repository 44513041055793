<template>
  <div class="block-right block_content">
    <div>
      <div class="potok-text-h2">как пополнить счет через <br />QR-код?</div>
      <ul>
        <li>1. наведите камеру телефона на QR-код</li>
        <li>2. выберите банковское приложение из списка</li>
        <li>3. подтвердите платеж в мобильном приложении банка</li>
      </ul>
    </div>
    <div class="potok-text-body-2">
      при переводе по СБП обычно деньги зачисляются мгновенно, но иногда время
      ожидания составляет до 15 минут
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.block-right {
  width: 360px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: $size_767) {
    display: none;
  }

  @media (max-width: $size_1200) {
    margin-top: 20px;
  }
}

.potok-text-body-2 {
  color: $grey;
  max-width: 330px;
}

ul {
  margin-top: 30px;
  list-style-type: none;
  padding-left: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

li {
  margin-top: 14px;
}

.potok-text-body-2 {
  color: $grey;
  max-width: 330px;
}
</style>
