<template>
  <div class="row filters_wrapper">
    <div class="col-md-12 col-xs-12">
      <div class="projects-filters">
        <div class="col-md-3 col-xs-12 filters_row">
          <MultiSelect
            :items="riskTypes"
            :placeholder="'Тип риска'"
            @change="onChangeFilters"
          >
          </MultiSelect>
        </div>
        <div class="col-md-3 col-xs-12 filters_row">
          <MultiSelect
            :items="loanTypes"
            :placeholder="'Тип займа'"
            @change="onChangeFilters"
          >
          </MultiSelect>
        </div>
        <div class="col-md-3 col-xs-12 filters_row">
          <MultiSelect
            :items="borrowTypes"
            :placeholder="'Тип заемщиков'"
            @change="onChangeFilters"
          ></MultiSelect>
        </div>
        <div class="col-md-3 col-xs-12 filters_row">
          <CSelect
            v-model="selectedSortingOptions"
            :items="sortingOptions"
            @update:model-value="onChangeSortingOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue';

import MultiSelect from '@/components/_generic/cselect/MultiSelect.vue';
import { CSelect } from 'potok-uikit';
import loansFiltersComposable from '@/composables/loansFiltersComposable';
import loansItemsSortComposable from '@/composables/loansItemsSortComposable';

const emit = defineEmits(['changeSelectedSortingOptions', 'changeFilters']);
const {
  riskTypes,
  loanTypes,
  borrowTypes,
  initFilterData,
  updateFilters,
  filters,
} = loansFiltersComposable();

const { sortingOptions, selectedSortingOptions, initSortingData } =
  loansItemsSortComposable();

const onChangeFilters = () => {
  updateFilters();
  emit('changeFilters', filters.value);
};
const onChangeSortingOptions = () => {
  emit('changeSelectedSortingOptions', selectedSortingOptions.value);
};

onMounted(() => {
  initFilterData();
  initSortingData();
  emit('changeFilters', filters.value);
  emit('changeSelectedSortingOptions', selectedSortingOptions.value);
});
</script>
<style lang="scss" scoped>
.filters_row {
  margin-bottom: 10px;
}

.projects-filters {
  background-color: #fff;
  border-radius: 3px;
  padding: 20px 0 10px;
  width: 100%;
  display: inline-block;
}
</style>
