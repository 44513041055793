import { ref } from 'vue';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';

export function usePromotions() {
  const store = useStateStore();
  const modalData = ref({});
  const isShowBonusForDepositModal = ref(false);

  const loadTypicalPromotion = () => {
    return server.getTypicalPromotion
      .send()
      .pipe(onloadTypicalPromotion, () => {})
      .exec();
  };

  const onloadTypicalPromotion = (obj) => {
    if (!obj) {
      return;
    }
    const data = obj.data || { promotions: [] };
    if (!data.promotions?.length) {
      return;
    }
    const message = data.promotions[0];

    const notificationData = {
      title: message.notification.cardTitle,
      message: message.notification.cardDescription,
      timer: 'infinity',
      isError: false,
      btnCallback: openBonusForDepositModal,
      btnText: message.notification.buttonLabel,
    };
    modalData.value.title = message.modal.title;
    modalData.value.content = message.modal.text;

    setTimeout(() => {
      store.setNotification(notificationData);
    }, 1000);
  };

  const openBonusForDepositModal = () => {
    isShowBonusForDepositModal.value = true;
  };

  return {
    loadTypicalPromotion,
    modalData,
    isShowBonusForDepositModal,
    openBonusForDepositModal,
  };
}
