<template>
  <div class="project__tags">
    <div :class="props.project.statusClassName" class="project__tag">
      <span class="project__tag-icon">{{ props.project.statusTitle }} </span>
    </div>

    <div
      v-if="!isOverdue(props.project) && props.project.isExperiencedBorrower"
      class="project__tag project-experianced-borrower"
    >
      <span class="project__tag-icon">опытный заёмщик</span>
    </div>

    <template
      v-if="
        props.project.statusId === constants.projectStatus.refunded &&
        props.project.isRestructured
      "
    >
      <div :class="props.project.statusClassName" class="project__tag">
        <span class="project__tag-icon">реструктурирован</span>
      </div>
    </template>

    <template v-if="props.project.type">
      <label-loan-type :loan-type="props.project.type"></label-loan-type>
    </template>

    <div
      v-if="props.project?.rating === 'A'"
      class="project__tag low-risk"
      @click.prevent
    >
      <button class="info-icon-hint">
        <span class="project__tag-icon low-risk-text">
          <i class="low-risk-icon" />
          пониженный риск
        </span>
      </button>
    </div>

    <div v-if="props.project.isHighRisk" @click.prevent>
      <VDropdown>
        <button class="info-icon-hint project__tag risk">
          <span class="project__tag-icon">повышенный риск</span>
        </button>
        <template #popper>
          <div class="popup">
            <div v-close-popper class="popup-close"></div>
            <div class="popup-text">
              Более высокая ставка отражает нашу оценку уровня риска данного
              заемщика.
            </div>
          </div>
        </template>
      </VDropdown>
    </div>

    <template
      v-if="props.project.isPifInvested && !props.project.isPifReserved"
    >
      <pif-invested :project="props.project"></pif-invested>
    </template>
  </div>
</template>
<script setup>
import constants from '@/constants';

import LabelLoanType from '@/components/_generic/tags/LabelLoanType.vue';
import PifInvested from '@/components/_generic/account/project/tabs/statuses/PifInvestedNew';

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
});

const isOverdue = (project) => {
  return (
    project.statusId === constants.projectStatus.overdue ||
    project.statusId === constants.projectStatus.cession1st ||
    project.statusId === constants.projectStatus.cession2nd ||
    project.statusId === constants.projectStatus.cessionFixed
  );
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.project__tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.low-risk {
  display: flex;
  margin: 0;
  top: 2px;

  .project__tag-icon {
    background: #e8ebee;
    color: $breakwater;
  }
}

.low-risk .low-risk-text {
  display: flex;
}

.low-risk-icon {
  margin-right: 4px;
  display: block;
  height: 10px;
  width: 10px;
  background: url('@img/shield-tick.svg');
}

.info-icon-hint {
  border: none;
  background: none;
  cursor: default;
  padding: 0 4px;
}
</style>
